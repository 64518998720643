import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import {
  Box, Grid,
} from '@material-ui/core';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@material-ui/core/Slider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SecondTable from './SecondTable'
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import {

  Button,

} from '@material-ui/core';
import PlotsRight from './PlotsRight';


const drawerWidth = 150;



// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));






export default function SideBarSecond(
  {
    title,
    subtitle,
    data,
    data2,
    data3,
    keys,
    keys2,
    keys3,
    keys4,
    keys6,
    setData,
    valueRow,
    setData2,
    resetVal,
    setResetVal,
    valueID,
    colIdx,
    selectedKeyValuePair,
    setselectedKeyValuePair,
    ValueChosen,
    setValueChosen,
    dataset,
    allKeys,
    setdataset,
  }
) {



  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));





  const classes = useStyles();



  const theme = useTheme();


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleChangeData = (event) => {
    setdataset(event.target.value);
  };



  const [menutitle, setMenuTitle] = React.useState("petalLength");

  const [menutitle2, setMenuTitle2] = React.useState("sepalWidth");


  const [menutitle4, setMenuTitle4] = React.useState("species");


  const [menutitle10, setMenuTitle10] = React.useState("");

  useEffect(() => {
    setMenuTitle(keys[0]);
    setMenuTitle2(keys[2]);
    setMenuTitle4(keys4[0]);
    setMenuTitle10(keys[2]);
  }, [dataset, setdataset, keys4])

  const handleChangeMode = (event) => {

    setMenuTitle(event.target.value);
  };

  const handleChangeMode2 = (event) => {

    setMenuTitle2(event.target.value);
  };

  const handleChangeMode10 = (event) => {
    setMenuTitle10(event.target.value);
  };

  const [valueBin, setValueBin] = React.useState(30);

  const handleChangeBin = (event, newValue) => {
    setValueBin(newValue);
  };

  const resetAll = () => (event) => {

    setResetVal(!resetVal);

};

// const [highCell, setCell] = useState([{}])


  return (

    <Box sx={{ display: 'flex', align: 'center' }}>
      <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }} component="div">
            {title} {"|"} {subtitle}
          </Typography>


          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              '& svg': {
                color: "inherit",
                transition: '0.2s',
                transform: 'translateX(0) rotate(0)',
              },
              '&:hover, &:focus': {
                bgcolor: 'unset',
                '& svg:first-of-type': {
                  transform: 'translateX(-4px) rotate(-20deg)',
                },
                '& svg:last-of-type': {
                  right: 0,
                  opacity: 1,
                },
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                height: '80%',
                display: 'block',
                left: 0,
                width: '1px',
                bgcolor: 'divider',
              },
              ...(open && { display: 'none' })
            }}
          >
            <Settings />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />

        <Grid container>


          <Grid item sm={12} md={12}>
            <PlotsRight
              // setCell={setCell}
              data={data}
              data2={data2}
              keys={keys2}
              keys2={keys6}
              keys4={keys4}
              dataset={dataset}
              setdataset={setdataset}
              setData={setData}
              setData2={setData2}
              resetVal={resetVal}
              setResetVal={setResetVal}
              valueBin={valueBin}
              menutitle={menutitle}
              menutitle2={menutitle2}
              menutitle10={menutitle10}

            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>

          <Grid item sm={12} md={7}>
            <SecondTable 
              // setCell={setCell}
              // highCell={highCell}
              data={data}
              data2={data2}
              data3={data3}
              keysNum={keys}
              keyStr={keys4}
              keys={keys2}
              keys2={keys3}
              selectedKeyValuePair={selectedKeyValuePair}
              setselectedKeyValuePair={setselectedKeyValuePair}
              ValueChosen={ValueChosen}
              setValueChosen={setValueChosen}
              setData={setData}
              setData2={setData2}
              valueID={valueRow}
              allKeys={allKeys}
              colIdx={colIdx}
            />

          </Grid>
          <div className={classes.logoContainer}>
            <Button color="orange"  onClick={resetAll()}>
                                <ThreeSixtyIcon fontSize="large" />
                                Reset All
                                </Button>

                    </div> 

        </Grid>





      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <div >

<>
        <Card sx={{ minWidth: drawerWidth-50 }}>
          <CardContent>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions4-header"
              >
                <Box className={classes.summary}>

                  Change Whole Dataset
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div >
                  <>
                    <FormControl >
                      <InputLabel id="demo-simple-select-filled-label">Change Whole Dataset</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={dataset}
                        onChange={e => {
                          handleChangeData(e);
                        }}
                      >
                        {keys3.map((KeyName, index) => (
                          <MenuItem key={index} value={KeyName}> {KeyName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions4-header"
              >
                <Box className={classes.summary}>

                  Scatterplot Settings
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div >

                  <>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-label">X Parameter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={menutitle}
                        label="XParameter"
                        onChange={handleChangeMode}
                      >
                        {keys.map((KeyName, index) => (

                          <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                        ))}
                      </Select>
                    </FormControl>
                    <br></br>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-label2">Y Parameter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={menutitle2}
                        label="YParameter"
                        onChange={handleChangeMode2}
                      >
                        {keys.map((KeyName, index) => (

                          <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                        ))}
                      </Select>
                    </FormControl>
                  </>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions3-content"
                id="additional-actions4-header"
              >
                <Box className={classes.summary}>

                  Histogram Settings
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div >

                  <>
                    <Box >

                      Change BinSize
                      <Card sx={{ minWidth: drawerWidth-80 }}>
          <CardContent>
            <br></br>
                      <Slider
                        min={12}
                        step={1}
                        max={56}
                        valueLabelDisplay="auto"
                        value={valueBin}
                        onChange={handleChangeBin}
                        aria-labelledby="continuous-slider" />
                        </CardContent>
                        </Card>
                        <br></br>
                        <br></br>
                      <> Change Histogramdata </>
                      <br></br>

                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label3">Histogramdata</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={menutitle10}
                          label="HistoParameter"
                          onChange={handleChangeMode10}
                        >
                          {keys.map((KeyName, index) => (

                            <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                          ))}
                        </Select>
                      </FormControl>


                    </Box>



                  </>
                </div>
              </AccordionDetails>
            </Accordion>


          </CardContent>
        </Card>
        </>
                </div>
      </Drawer>
    </Box>






  );
}
