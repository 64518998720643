import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import SideBar3 from './components/SideBar3';
import irisData_original from './../../datasets/irisData_original.json';
import credit from './../../datasets/credit.json';
import pingu from './../../datasets/pingu.json';
import sales from './../../datasets/sales.json';
import { makeStyles } from '@material-ui/core/styles';


import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function ThirdPage() { 
    
  const classes = useStyles();

    // const datasets = ["IRIS","PENGUIN","FINANCE","SALES"];
    // const [dataMain, changeDataSet] = useState(["IRIS"]);

    const [data, setData] = useState([null]);
    const [data2, setData2] = useState([]);
    const [keys, setKeys2] = useState([]);
    const [keys2, setKeys3] = useState([]);
    const [keys3, setKeys4] = useState([]);
    const [keys4, setKeys5] = useState([]);


    const [dataset, setdataset] = React.useState('iris');

    const Datasets =
    {
      'CreditCard': credit,
      'Sales': sales,
      'iris': irisData_original,
      'penguin': pingu,
    };
  
    const Title = styled.h2`
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;


useEffect(() => {

    setData(Datasets[dataset]);
    setData2(Datasets[dataset]);
    setKeys2(Object.keys(Datasets[dataset][0]));
    setKeys3((Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][0][d])) return d  })));
    //string keys
    setKeys4((Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  })));
     // Keys of Global
     setKeys5(Object.keys(Datasets));
    
}, [dataset])

// check if number or string
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }



  return (
    <>
    {console.log(keys2, keys3)}
      <Layout
                title="Dataset Visualization"
               >
                 
                <SideBar3
                   data={data}
                   data2={data2}
                   keys={keys}
                   keys2={keys2}
                   keys3={keys3}
                   keys4={keys4}
                   title={"Visualization Fundamentals"}
                   subtitle={"K"}
                   dataset={dataset}
                   setdataset={setdataset}
                />
              
            </Layout>
    </>
  );
}
