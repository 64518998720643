import React, { useEffect, useState } from 'react'
// import './Fourth.css'
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import PCA from 'pca-js'
import About from './components/About'
import Menu from './components/Menu'
import ComponentN from './components/ComponentN'
import DataTable from './components/DataTable'
import Examples from './components/Examples'
import ScatterPlot from './components/ScatterPlot'
import EigenvaluesChart from './components/EigenvaluesChart'
import mathHelper from '../Second_DataCleaning/utils/mathHelper'
import ActiveExample from './components/ActiveExample'

function App() {
  const [data, setData] = useState([])
  const [highlightedComponent, setHighlightedComponent] = useState(null)
  const [highlightedIndex, setHighlightedIndex] = useState(null)
  const [principalComponents, setPrincipalComponents] = useState([])
  const [mean, setMean] = useState([0, 0])
  const [activeExample, setActiveExample] = useState(null)
  const [exampleStep, setExampleStep] = useState(0)
  const [showExamplesModal, setShowExamplesModal] = useState(false)
  const [showTooltips, setShowTooltips] = useState(false)

  // calculate PCA on data change
  useEffect(() => {
    if (data.length === 0) {
      setPrincipalComponents([])
      return
    }
    const vectors = PCA.getEigenVectors(data)
    setPrincipalComponents(vectors)
    setMean(mathHelper.mean(data))
  }, [data])

  // show tooltips if data is empty
  useEffect(() => {
    if (data.length > 0 || showExamplesModal) setShowTooltips(false)

    setTimeout(() => {
      if (data.length === 0 && !showExamplesModal) setShowTooltips(true)
    }, 2500)
  }, [data, showExamplesModal])

  return (

        <Grid sx={{ flexGrow: 1 }} container spacing={2}>      
        
        {/* </Col>
        <Col sm="12" md="8" lg="8" xl="3"> */}
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={8} md={4}>
        
          <EigenvaluesChart principalComponents={principalComponents} />
          <div id="singleComponents">
            <ComponentN
              data={data}
              principalComponents={principalComponents}
              setHighlightedComponent={setHighlightedComponent}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              n="1"
            />
            <ComponentN
              data={data}
              principalComponents={principalComponents}
              setHighlightedComponent={setHighlightedComponent}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              n="2"
            />
          </div>
          </Grid>

          <Grid item xs={4} md={4}>
       
      {/* <Row>
        <Col sm="12" md="4" lg="4" xl="2"> */}

          {/* <About /> */}
          
          {activeExample && (
            <ActiveExample
              activeExample={activeExample}
              setActiveExample={setActiveExample}
              exampleStep={exampleStep}
              setExampleStep={setExampleStep}
              setData={setData}
            />
          )}
          <Examples
            setActiveExample={setActiveExample}
            setExampleStep={setExampleStep}
            setData={setData}
            showExamplesModal={showExamplesModal}
            setShowExamplesModal={setShowExamplesModal}
          />
        {/* </Col>
        <Col sm="12" md="8" lg="8" xl="5"> */}
         
          <ScatterPlot
            data={data}
            setData={setData}
            principalComponents={principalComponents}
            mean={mean}
            highlightedComponent={highlightedComponent}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            showTooltips={showTooltips}
          />
          <Menu
        data={data}
        setData={setData}
        setHighlightedComponent={setHighlightedComponent}
        setHighlightedIndex={setHighlightedIndex}
        setShowExamplesModal={setShowExamplesModal}
        showTooltips={showTooltips}
      />
          </Grid>
        {/* </Col>
        <Col sm="12" md="4" lg="4" xl="2"> */}
        <Grid item xs={8} md={3}>
          <DataTable
            data={data}
            setData={setData}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            showTooltips={showTooltips}
          />
          </Grid>
        {/* </Col>
      </Row> */}
</Grid>
  )
}

export default App
