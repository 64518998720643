import styled from 'styled-components';
import PropsTypes from 'prop-types';
import React from 'react';

const YAxis = styled.text`
  transform: rotate(-90deg);
  text-anchor: middle;
  font-size: 0.9rem;
`;

const XAxis = styled.text`
  font-size: 0.9rem;

`;

function AxisLabel2({
  innerHeight,
  innerWidth,
  axisPadding,
  yLabel,
  xLabel,
  marginLeft,
}) {
  return (
    <g>
      <YAxis y={-axisPadding} x={-innerHeight / 1.5}>
        {yLabel}{"→"}
      </YAxis>
      <XAxis y={innerHeight + axisPadding} x={innerWidth / 2 - 2.5*marginLeft}>
        {xLabel}{"→"}
      </XAxis>
    </g>
  );
}

AxisLabel2.propTypes = {
  y: PropsTypes.number,
  yLabel: PropsTypes.string,
  xLabel: PropsTypes.string,
  marginLeft: PropsTypes.number,
  innerHeight: PropsTypes.number,
  innerWidth: PropsTypes.number,
};

export default AxisLabel2;
