import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
    scaleLinear,
    scaleLog,
    extent,
    select,
    scaleOrdinal,
    schemeCategory10,
    group,
} from 'd3';
import * as d3 from 'd3';
import PropsTypes from 'prop-types';
//import textures from 'textures';
import {
    Group, MapWrapper, Map,
    //,
    //Dropdown
} from '../../../style/style';
import AxisLabel from '../../../components/AxisLabel/AxisLabel';
import Axes from '../../../components/Axes/Axes';
import ColorLegend from '../../../components/ColorLegend/ColorLegend';
import textures from 'textures';
//import PanelTopRight from '../../components/Boxes/surfaces/PanelTopRight';
//import Accordions from './BoxSettings/Accordions';
// import Tooltip from './Tooltip';
//import SideBar from '../../components/Layout/SideBar';
import styled from 'styled-components';


const GroupedCircle = styled.g`
  circle {
    
    

    // &:hover {
    //   fill: #e46aa7;
    //   stroke-width: 4px;
    //   stroke: #5a1354;
    // }
  }

//   line {
//     fill: #00a9ec;

//   }
`;


function ScatterPlot({ 
    data, 
    buttonValues, 
    xButton, 
    yButton, 
    sizeButton, 
    colorButton, 
    opaButton, 
    markButton, 
    oriButton, 
    height, 
    width, 
    legendValue,
  tooltipValue,
  highlightedIndex,
  setHighlightedIndex,}) {
    //const [value, setValue] = useState('linear');

    const [selectedCircle, setSelectedCircle] = useState(null);
    const [position, setPosition] = useState({ xPosition: 0, yPosition: 0 });
    const circleRef = useRef();

    const margin = { top: 10, right: 80, bottom: 40, left: 10 };
    const innerWidth = width - margin.right - margin.left;
    const innerHeight = height - margin.top - margin.bottom;

    const circleRadius = buttonValues.sizeSwitch ? (d => 3 * (d.petallength)) : (10);

    //const circleRadius = 10;
    //const circleRadius = d => 3*(d.age);

    //const myTexture = d => (d.name);
    const myAngle = d => 3 * (d[oriButton]);
    const mySize = d => 3 * (d[sizeButton]);
    const xValue = d => d[xButton];
    const yValue = d => d[yButton];
  
    const colorValue = d => d[colorButton];
    //const colorValue = d => d.height;
    const colorScale = scaleOrdinal(schemeCategory10); 
    const nested = Array.from(group(data, colorValue), ([key, value]) => ({
        key,
        value,
    }));
    colorScale.domain(nested.map(d => d.key));
    const colorData = buttonValues.colorSwitch ? (d => colorScale(colorValue(d))) : (d => colorScale(colorScale(d[0])));

    //opacity settings
    const opacityValue = d => d[opaButton];
    //const opacityValue = d => d.age;
    const nestedOpacity = Array.from(group(data, opacityValue), ([key, value]) => ({
        key,
        value,
    }));
    const opacityScale =
        scaleLinear()
        .domain(nestedOpacity.map(d => d.key))
        .range([0.5, 1]); 
    const myOpacity = buttonValues.brightnessSwitch ? (d => opacityScale(opacityValue(d))) : (1);

    var myRadialSize = scaleLinear()
        .domain(extent(data, mySize))
        .range([0, 270])


    var myOrientation = scaleLinear()
        .domain(extent(data, myAngle))
        .range([0, 270])


    //const xScale = scaleLinear()
    //    .domain(extent(data, xValue))
    //    .range([0, innerWidth])
    //    .nice();
    const xScale = buttonValues.logSwitchX ? scaleLog() : scaleLinear();
        
    xScale.domain([d3.min(data, d => Number(xValue(d))) - 0.5, d3.max(data, d => Number(xValue(d))) + 1])
        .range([0, innerWidth])
        .nice();
    
    const yScale = buttonValues.logSwitchY ? scaleLog() : scaleLinear();

    yScale.domain([d3.min(data, d => Number(yValue(d))), d3.max(data, d => Number(yValue(d))) + 1])
        .range([innerHeight, 0])
        .nice();


    
    const axesTickFormat = useCallback(number => {
        return number;
    }, []);


   // const Radius = 125;

   const texture = textures
     .circles();



   const symbolGen = d3.symbol().size(125);
    //elegant way
   const shape = d3.scaleOrdinal(d3.symbols.map(s => symbolGen.type(s)()));
  

    useEffect(() => {

        const circleGroup = select(circleRef.current);
      
        circleGroup.call(texture);

        var config = {
            "avatar_size": 48
        }


        circleGroup.append('defs')
            .append("pattern")
            .attr("id", "grump_avatar")
            .attr("width", config.avatar_size)
            .attr("height", config.avatar_size)
            .attr("patternUnits", "userSpaceOnUse")
            .append("svg:image")
            .attr("xlink:href", 'http://placekitten.com/g/48/48')
            .attr("width", config.avatar_size)
            .attr("height", config.avatar_size);

       

        if (buttonValues.markSwitch) {
            circleGroup
                .selectAll('*')
                .remove();
          

         
            circleGroup
                .selectAll('path')
                .data(data)
                .join('path')
                .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                .transition()
                .duration(2000)
                .delay((_, i) => i * 10)
                .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d))})`)
                .attr("fill", colorData)
                .attr("opacity", myOpacity)
                .attr("d", d => symbolGen.size(myRadialSize(mySize(d))))
                .attr("d", d => shape(d[markButton]))
                .on("start", function repeat() {
                    if (buttonValues.motionSwitch) {
                        d3.active(this)
                            .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                            .transition()
                            .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d)) - 10})`)
                            .transition()
                            .on("start", repeat);
                    }
                });




           


        }
        else if (buttonValues.orientationSwitch) {
            circleGroup
                .selectAll('*')
                .remove();
         

            circleGroup
                .selectAll('.myPoint')
                .data(data)
                .enter()
                .append('image')
                .attr("xlink:href", "https://img.icons8.com/color-glass/48/000000/music.png")
                .attr("fill", colorData)
                .attr("x", innerWidth / 2)
                .attr("y", innerHeight / 2)
                .attr("width", 0)
                .attr("height", 0)
                .transition()
                .duration(2000)
                .delay((_, i) => i * 10)
                .attr("x", d => xScale(xValue(d)) - 13)
                .attr("y", d => yScale(yValue(d)) - 13)
                .attr("width", 26)
                .attr("height", 26)
                .attr("transform", d => `rotate(${myOrientation(myAngle(d))},${xScale(xValue(d))},${yScale(yValue(d))})`)
                .on("start", function repeat() {
                    if (buttonValues.motionSwitch) {
                        d3.active(this)
                            .attr("x", innerWidth / 2)
                            .attr("y", innerHeight / 2)
                            .transition()
                            .attr("x", d => xScale(xValue(d)) - 13)
                            .attr("y", d => yScale(yValue(d)) - 13)
                            .transition()
                            .on("start", repeat);
                    }
                })
           
        }
        else {
            circleGroup
                .selectAll('*')
                .remove();

            circleGroup
                .selectAll('.scatter-circle')
                .data(data)
                .join('circle')
                .attr('class', 'scatter-circle')
                .attr('cy', innerHeight / 2)
                .attr('cx', innerWidth / 2)
                .attr('r', 0)
                .on('mouseover', (e, d) => {
                   setSelectedCircle(d);
                   setHighlightedIndex([d]);
                   setPosition({ xPosition: e.pageX, yPosition: e.pageY });
                })
                .on('mouseout', () => { 
                    setSelectedCircle(null); 
                    setHighlightedIndex([{}])})
                .attr('cy', d => yScale(yValue(d)))
                .attr('cx', d => xScale(xValue(d)))
                // .attr('r', (_value, index) => (_value === highlightedIndex[0] ? (2*circleRadius) : circleRadius))
                .attr('r', (_value, index) => ( highlightedIndex.includes(_value) ? (2*circleRadius) : circleRadius))
                .style('fill', colorData)
                .attr('stroke-width', 3)
                .attr('stroke', (_value, index) => highlightedIndex.includes(_value) ? 'orange' : '')
                // .style('fill', (_value, index) => (_value === highlightedIndex ? 'red' : {colorData}))
                .style("opacity", myOpacity)
                //.style("fill", 'url(#grump_avatar)')
                //.style("opacity", 0.6)
                .on("start", function repeat() {
                    if (buttonValues.motionSwitch) {
                        d3.active(this)
                            .attr("cx", d => xScale(xValue(d)))
                            .attr("cy", d => yScale(yValue(d)))
                            .transition()
                            .attr("cx", innerWidth / 2)
                            .attr("cy", innerHeight / 2)
                            .transition()
                            .on("start", repeat);
                        //.style("fill", d => ofTexture(myTexture(d)))
                        //.attr("opacity", 0.5);
                    }
                });

                
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [data, xButton, yButton,sizeButton, 
        colorButton, 
        opaButton, 
        markButton, 
        oriButton,buttonValues, highlightedIndex]);
 


    return (

<>
                        {/* {console.log("xButton", highlightedIndex)} */}

<>
        <MapWrapper>
            <Map>
                <svg viewBox={"0 -10 750 400"}>
                    <Group
                        x={width}
                        y={height} 
                        right={margin.right}
                        top={margin.top}>
                        <Axes
                            xScale={xScale}
                            yScale={yScale}
                            innerHeight={innerHeight}
                            xTickPadding={10}
                            yTickPadding={10}
                          
                        />
                        <AxisLabel
                            innerHeight={innerHeight+60}
                            innerWidth={innerWidth}
                            axisPadding={50}
                            yLabel={yButton}
                            xLabel={xButton}
                            marginLeft={margin.left}
                        />
                        <GroupedCircle ref={circleRef} />
                    </Group>
                    <ColorLegend
                        moveX={innerWidth-legendValue}
                        spacing={30}
                        radius={9}
                        textX={15}
                        colorScale={colorScale}
                        colorData={colorData}
                        width={width+100}
                        align="horizontal"
                    />
                    </svg>
                    
                {/* {selectedCircle && (
                    <Tooltip
                        selectedCircle={selectedCircle}
                        position={position}
                        xLabel={xButton}
                        yLabel={yButton}
                        tooltipValue={tooltipValue}
                        colorButton={colorButton}
                    />
                )}   */}
            </Map>
            </MapWrapper>
            </>
        </>

    );
}



ScatterPlot.propTypes = {
    data: PropsTypes.array,
};

export default ScatterPlot;
