import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';
import { ParallelCoordinate } from './../ParallelCoordinate';
import Divider from '@material-ui/core/Divider';
import {
    Box, Grid,
} from '@material-ui/core';
import Chip from '@mui/material/Chip';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import irisData_original from './../data/irisData_original.json';
import cars from './../cars.json';
import Parcoords from "parcoord-es"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';


const drawerWidth = 190;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));






export default function SideBar({
    data2,
    dataset,
    keys,
    keys2,
    title,
    subtitle,
    changeDataSet,
    handleChangeInDataSet,
    datasets,
    Datasets,
    dataMain,
    setdataset,
    setKeys,
    setKeys2,
    keysMini,

}) {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(3),
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const [BrushMode, setBrushMode] = React.useState('1D-axes');

    const handleChange = (event) => {


        setBrushMode(event.target.value);

    };

    const classes = useStyles();

    const [isHover, setIsHover] = useState(false);

    const [data3, setData2] = useState([]);
    // const [keys, setKeys] = useState([]);
    // const [keys2, setKeys2] = useState([]);

    // const [dataset, setdataset] = useState('iris');

    // const Datasets =
    // {
    //   'iris': irisData_original,
    //   'cars': cars,
    // };


    const [colVar, setcolVar] = useState();



    const handleChangeVar = (event) => {
        setcolVar(event.target.value);
    };





    useEffect(() => {


        setData2(Datasets[dataset]);
        // setKeys2((Object.keys(dataset[0]).filter(function (d) { return d != "species" })));

        const Axes = (Object.keys(Datasets[dataset][0]));
        setKeys(Axes);
        setcolVar(Axes[1]);
        setKeys2(Object.keys(Datasets));

        setFilterData(filtered);

        setbundling(Object.keys(filtered[0])[0]);

    }, [dataset, data2])

    
   

    const handleBundling = (event) => {
        setbundling(event.target.value);
    };


    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();

        fileReader.readAsText(target.files[0]);
        fileReader.onload = (e) => {
            var contents = e.target.result;
            setData2(contents);
        };
    };





    const theme = useTheme();


    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    const [bundlingStrength, setbundlingStrength] = React.useState(0.5);

    const handleSliderChange = (event, newValue) => {
        setbundlingStrength(newValue);
    };

    const [personName, setPersonName] = React.useState([]);

    const [newAxes, setNewAxes] = React.useState([]);

    const handleChangeAxes = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const [BrushReset, setBrushReset] = useState(false);


   


    const [filterAxes, setfilterAxes] = useState([]);


    // var filtered = {};


    // filtered = (filterAxes === undefined || filterAxes == 0) ? Datasets[dataset] : filterAxes
    // .reduce((obj, key) => data2.map(d => ({[key]: d[key] })), {});
    
    // const filtered = 

    const filtered = (filterAxes === undefined || filterAxes == 0) ? Datasets[dataset] : data2.map( d=> Object.fromEntries(
        Object.entries(d).filter(
           ([key, val])=>filterAxes.includes(key)
        )
     ));

     const [filterData, setFilterData] = useState(Datasets[dataset]);

     const [bundling, setbundling] = useState(Object.keys(filtered[0])[0]);

     const handleChangeData = (event) => {
        setdataset(event.target.value);
        setfilterAxes([]);
        setPersonName([]);
        setbundling(Object.keys(filtered[0])[0]);

    };


    // filtered[personName[0]] = data2.map(d=> d[personName[0]]);

    //  const filtered = Object.keys(Datasets[dataset][0]).forEach((key) => personName.includes(key)? delete data2.map(d => d[key]):"");

    return (




        <Box sx={{ display: 'flex', align: 'center' }}>
            {console.log(filterData, filtered, personName)}
            <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h3" noWrap sx={{ flexGrow: 1 }} component="div">
                        {title}
                        <br></br>
                        {subtitle}
                    </Typography>

                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{
                            '& svg': {
                                color: "inherit",
                                transition: '0.2s',
                                transform: 'translateX(0) rotate(0)',
                            },
                            '&:hover, &:focus': {
                                bgcolor: 'unset',
                                '& svg:first-of-type': {
                                    transform: 'translateX(-4px) rotate(-20deg)',
                                },
                                '& svg:last-of-type': {
                                    right: 0,
                                    opacity: 1,
                                },
                            },
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                height: '80%',
                                display: 'block',
                                left: 0,
                                width: '1px',
                                bgcolor: 'divider',
                            },
                            ...(open && { display: 'none' })
                        }}
                    >
                        <Settings />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Main open={open}>
                <DrawerHeader />

                <ParallelCoordinate
                    data2={data3}
                    dataset={dataset}
                    keys={keys}
                    keys2={keys2}
                    Datasets={Datasets}
                    handleChangeInDataSet={handleChangeInDataSet}
                    // bundleDimension ={bundleDimension}
                    // setbundleDimension={setbundleDimension}
                    colVar={colVar}
                    setcolVar={setcolVar}
                    setData2={setData2}
                    bundlingStrength={bundlingStrength}
                    BrushMode={BrushMode}
                    BrushReset={BrushReset}
                    setbundling={setbundling}
                    bundling={bundling}
                    personName={personName}
                    filtered={filtered}
                    filterAxes={filterAxes}
                    filterData={filterData}
                />

            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <Card sx={{ minWidth: drawerWidth }}>
                </Card>

                <>
                    <div>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >Global Settings</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>
                                <div>
                                    <>
                                        <Box >

                                            Change Whole Dataset
                                            <br>
                                            </br>
                                            <br>
                                            </br>


                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Change Whole Dataset</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={dataset}
                                                    onChange={handleChangeData}
                                                >
                                                    {keys2.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName}> {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>


                                    </>


                                </div>



                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >                            Change Axes Variable
                                    </>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>


                                        <FormControl sx={{ m: 1, width: drawerWidth }}>
                                            <InputLabel id="demo-multiple-chip-label">Parallel Coordinate Axes</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                value={personName}
                                                onChange={handleChangeAxes}
                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {keys.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, personName, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <br></br>

                                        <Button variant="contained" color="grey"
                                            onClick={() => {
                                                setfilterAxes(personName);
                                                setFilterData(filtered);

                                            }}
                                        >
                                            Set Axes
                                        </Button>




                                    </>
                                </div>

                            </AccordionDetails>
                        </Accordion>






                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >                            Brush Mode
                                    </>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>


                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Brush Mode</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={BrushMode}
                                                label="BrushMode"
                                                onChange={handleChange}
                                            >
                                                {Parcoords().brushModes().map((KeyName, index) => (
                                                    <MenuItem key={index} value={KeyName} >{KeyName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <br></br>
                                        <br></br>
                                        <br></br>

                                        <Button variant="contained" color="grey"
                                            onClick={() => {
                                                setBrushReset(!BrushReset);
                                            }}
                                        >
                                            Reset Brushes
                                        </Button>




                                    </>
                                </div>

                            </AccordionDetails>
                        </Accordion>






                        <Divider />

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >Bundling Parameter</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>

                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Variable</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={bundling}
                                                label="BrushMode"
                                                onChange={handleBundling}
                                            >
                                                {Object.keys(filtered[0]).map((KeyName, index) => (
                                                    <MenuItem key={index} value={KeyName} >{KeyName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <br></br>
                                        <br></br>

                                        Bundling Strength
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <Box>
                                            <Slider
                                                min={0.1}
                                                step={0.1}
                                                max={1}
                                                valueLabelDisplay="auto"
                                                value={bundlingStrength}
                                                onChange={handleSliderChange}
                                                color='primary'
                                                aria-labelledby="continuous-slider" />
                                        </Box>


                                    </>
                                </div>

                            </AccordionDetails>
                        </Accordion>



                        <Divider />




                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >Color Settings</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>


                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Variable</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={colVar}
                                                label="BrushMode"
                                                onChange={handleChangeVar}
                                            >
                                                {keys.map((KeyName, index) => (
                                                    <MenuItem key={index} value={KeyName} >{KeyName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>




                                    </>
                                </div>

                            </AccordionDetails>
                        </Accordion>



                    </div>
                </>


            </Drawer>
        </Box>






    );
}
