import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import SideBar5 from './components/SideBar5';
import irisData from './../../datasets/irisData_original.json';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function FifthPage() { 
  const classes = useStyles();


    const [data, setData] = useState([null]);
    const [data2, setData2] = useState([]);
    const [keys, setKeys2] = useState([]);
    
  
    const Title = styled.h2`
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;

  const SubTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 568px) {
    text-align: center;
  }
`;



useEffect(() => {
    const dataIni = irisData.map(record => {
        return { ...record, id: uuid() };
    });
    setData(dataIni);
    setData2(irisData);
    setKeys2(Object.keys(irisData[0]));

   
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [irisData])
  return (
    <>
          {console.log("the real", data)}
      <Layout
                title="Dataset Visualization"
              >
                   
                <SideBar5
                   data={data}
                   data2={data2}
                   keys={keys}
                   title={"Clustering"}
                   subtitle={"K-Means Algorithm"}
                />
            </Layout>

    </>
  );
}
