import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import {
  Box, Grid, Switch,
} from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import BarChart from './BarChart';

const drawerWidth = 260;
// new stuff
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));
export default function SideBar1(props) {
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [valueBin, setValueBin] = React.useState(30);

  const handleChangeBin = (event, newValue) => {
    setValueBin(newValue);
  };
  const [menutitle, setMenuTitle] = React.useState();
  const [menutitle2, setMenuTitle2] = React.useState();
  const [menutitle3, setMenuTitle3] = React.useState();
  const [filter, setfilterData] = React.useState();
  useEffect(() => {
    setMenuTitle(props.keys[0]);
    setMenuTitle2(props.keys2[0]);
    setMenuTitle3(props.keys4[0]);
    setfilterData(props.keys4[0]);
  }, [props.dataset, props.setdataset, props.keys3])
  const handleChangeData = (event) => {
    props.setdataset(event.target.value);
  };
  // grouping data
  function groupByAuto(data, key) {
    var groups = {};
    for (var i in data) {
      if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
      groups[data[i][key]].push(data[i]);
    }
    return groups;
  }
  const [state, setState] = React.useState(false);
  const handleChange = (event) => {
    setState(!state);
  };


  return (
    <Box sx={{ display: 'flex', align: 'center' }}>
      {console.log(menutitle2, menutitle3, props.keys2[0], props.keys4)}
      <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h3" noWrap sx={{ flexGrow: 1 }} component="div">
            {props.title}
          </Typography>
          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              '& svg': {
                color: "inherit",
                transition: '0.2s',
                transform: 'translateX(0) rotate(0)',
              },
              '&:hover, &:focus': {
                bgcolor: 'unset',
                '& svg:first-of-type': {
                  transform: 'translateX(-4px) rotate(-20deg)',
                },
                '& svg:last-of-type': {
                  right: 0,
                  opacity: 1,
                },
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                height: '80%',
                display: 'block',
                left: 0,
                width: '1px',
                bgcolor: 'divider',
              },
              ...(open && { display: 'none' })
            }}
          >
            <Settings />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        <BarChart
          width={800}
          height={400}
          data2={props.data2}
          keys={props.keys}
          valueBin={valueBin}
          menutitle={menutitle}
          menutitle2={menutitle2}
          menutitle3={menutitle3}
          filterTrue={state}
          filter={menutitle3}
          filterData={groupByAuto(props.data2, menutitle2)}
        />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Divider />
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="demo-simple-select-filled-label">Change Whole Dataset</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={props.dataset}
            onChange={e => {
              handleChangeData(e);
              setState(false);
            }}
          >
            {props.keys3.map((KeyName, index) => (
              <MenuItem key={index} value={KeyName}> {KeyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Card sx={{ minWidth: drawerWidth }}>
          <CardContent>
            <Typography id="non-linear-slider" gutterBottom>
              Change BinSize
            </Typography>
            <Slider
              min={4}
              step={1}
              max={56}
              valueLabelDisplay="auto"
              value={valueBin}
              onChange={handleChangeBin}
              aria-labelledby="continuous-slider" />
          </CardContent>
        </Card>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="demo-simple-select-filled-label">Change Histogramdata</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={menutitle}
            onChange={e => { setMenuTitle(e.target.value) }}
          >
            {props.keys.map((KeyName, index) => (
              <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box >
          <Switch
            color="secondary"
            checked={state}
            onChange={handleChange}
            name="logHisto"
          />
          < >Filter Histogramdata</>
        </Box>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="demo-simple-select-filled-label">Filter Histogramdata</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={menutitle2}
            onChange={e => {
              setMenuTitle2(e.target.value);
              props.setKeys4(Object.keys(groupByAuto(props.data2, e.target.value)));
              setMenuTitle3(Object.keys(groupByAuto(props.data2, e.target.value))[0]);
            }}
          >
            {props.keys2.map((KeyName, index) => (
              <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="demo-simple-select-filled-label">Subcategory</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={menutitle3}
            onChange={e => {
              setMenuTitle3(e.target.value);
              setfilterData(e.target.value);
            }}
          >
            {props.keys4.map((KeyName, index) => (
              <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
        <Divider />
      </Drawer>
    </Box>
  );
}
