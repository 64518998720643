import styled from 'styled-components';
import PropsTypes from 'prop-types';
import React from 'react';

const YAxis = styled.text`
  transform: rotate(-90deg);
  text-anchor: middle;
  font-size: 1rem;
`;

const XAxis = styled.text`
  font-size: 1rem;

`;

function AxisLabel({
  innerHeight,
  innerWidth,
  axisPadding,
  yLabel,
  xLabel,
  marginLeft,
}) {
  return (
    <g>
      <YAxis y={-axisPadding} x={-innerHeight / 2}>
        {yLabel}{"→"}
      </YAxis>
      <XAxis y={innerHeight-20 } x={innerWidth / 2 + 15*marginLeft}>
        {xLabel}{"→"}
      </XAxis>
    </g>
  );
}

export default AxisLabel;
