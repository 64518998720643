import Cartogram from 'cartogram-chart';
import React, { useEffect, useState } from "react"
import * as d3 from "d3"
import * as styles from "./../ChoroplethMap.module.css"
import Gemeinde from './../AllGem.json';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

import Kantone from './../AllCan.json';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MainStyle = styled.h2`
font-size: 2.3rem;
font-weight: 300;
margin-bottom: 1rem;
margin-top: -19rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;

const Title = styled.h5`
font-size: 2.3rem;
font-weight: 200;
margin-bottom: 1rem;
margin-left: 25rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;



function Distortion({VarIndex,colorscale,distortionValue}) {
  const chart = React.useRef(null);
//   const chart2 = React.useRef(null);
  const chart4 = React.useRef(null);
  const chart5 = React.useRef(null);


  const classes = useStyles();

  // const [VarIndex, setValue] = useState(2);
  // const handleChange = val => setValue(val);

  const Variables = 
  ['Inhabitants in 2019 div by 1000', 
  'Change in Inhabitants in % in 2019',
'Inhabitants per km2 in 2019',
'% Foreigners in 2019',
'Main language is German in % in 2019',
'% SVP in the NR in % in 2019',
'commit crimes ‰ for total Swiss population in 2019 ',
'% finished higher education in 2019'
];

  const colors = [
    '#f2f0f7',
    '#dadaeb',
    '#bcbddc',
    '#9e9ac8',
    '#807dba',
    '#6a51a3',
    '#4a1486'
  ]


  // --------------- //
  // RETRIEVE DATA //
  // --------------- //
  // radon
  //
  //http://www.bag.admin.ch/themen/strahlung/00046/11952/index.html?lang=en 

  // const educationDataURL = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/for_user_education.json';
  // const countyDataURL = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/counties.json';
  const countyDataURL = 'https://unpkg.com/swiss-maps@4/2021/ch-combined.json';
  // const educationDataURL = 'GemeineTOPOJSONheader.csv'
  const width = 960;
  const height = 750;



     
    
// },[]);

  useEffect(() => {
    // setData(Object.keys(Gemeinde));
    const Residents = Gemeinde[VarIndex+1];

         //Total Inhabitants in Switzerland
         const TInh = 8606033;

    const ResidentsVal = Object.values(Gemeinde[VarIndex+1]);
        const ResValPer = ResidentsVal.forEach(e => e / TInh);


        // const Residents = educationData[1];
        const educationRange = d3.extent((ResidentsVal));

     // Create a color scale
     const color = d3.scaleQuantize()
     .domain(educationRange)
     .range(colorscale);

   

         
   
    // Promise.all([d3.json(educationDataURL), d3.json(countyDataURL)])
    Promise.all([Gemeinde, d3.json(countyDataURL)])
      .then(data => {
        const [educationData, topology] = data;

      
        const tooltipOffsetX = 20;
        const tooltipOffsetY = 73;
    
        // SET UP SVG
        const svg = d3.select(chart.current);

        // .append("svg")
        // .attr("viewBox", [0, 0, width, height]);
    

        // SET UP TOOLTIP
        const tooltip = d3.select(chart4.current)
          .append('div')
          .attr('class', 'hidden');

        // --------------- //
        // RETRIEVE DATA //
        // --------------- //
        //Total Inhabitants in Switzerland
        const TInh = 8606033;

        const Keys = Object.keys(educationData[VarIndex+1]);
        const Names = educationData[0];
        const Residents = educationData[VarIndex+1];
        //Value in Percentage
        const ResidentsVal = Object.values(educationData[VarIndex+1]);
        const ResValPer = ResidentsVal.forEach(e => e / TInh);


        // const Residents = educationData[1];
        const educationRange = d3.extent((ResidentsVal));



        // Map and projection
        const projection = d3.geoMercator().rotate([0, 0]).center([8.3, 46.8]).scale(10000).translate([975 / 2, 610 / 2]).precision(0.1);

        const path = d3.geoPath(projection);

       
        //  // Let the zoom take care of modifying the projection:
        // let zoom = d3.zoom()
        // .on("zoom", ({ transform }) => {
        //     let t = d3.event.transform;  
        //     projection.scale(t.k).translate([t.x,t.y]);
        //     features.attr("d", path)
        // })

        

       


        const z = d3.interpolatePlasma;
        // const z = colorscale;
        // const z = 'grey';
        //var myColor = d3.scaleSequential(z).domain(educationRange);
        // var myColor = colorscale;
        // var myColor = d3.scaleSequential(z).domain(educationRange);


        const g2 = svg.append("g");
        const g = svg.append("g");
        const g3 = svg.append("g");
        const g4 = svg.append("g");
        const g5 = svg.append("g");
        const g6 = svg.append("g");





        // function getColor(d) {
        //   // var result = educationData.filter(function (obj) {
        //   //   return obj.fips === d.id;
        //   // });
        //   // if (result[0]) {
        //   //   return myColor(result[0].bachelorsOrHigher);
        //   // }
        //   // could not find a matching fips id in the data
        //   return myColor(d);
        // }

        const KantDat = Kantone[VarIndex];

        const KantVal = Object.values(Kantone[VarIndex]);
        
        const KantRange = d3.extent((KantVal)); 

        // Create a color scale
        const color = d3.scaleQuantize()
        .domain(KantRange)
        .range(colorscale);

        // Add a scale for bubble size
        const valueExtent = d3.extent(ResidentsVal)


        svg.selectAll('*').remove();
        
     

        // // ADD LEGEND
        const legendRectWidth = 360;
        const legendRectHeight = 10;

        const legend = svg.append("g")
                          .attr("transform", "translate(" + 
                              (width - 800) + ", " + (height-700) + ")");

     for (let i = 0; i < colorscale.length; i++) {
          legend.append("rect")
                .attr("x", (legendRectWidth/colorscale.length)*i)
                .attr("width", legendRectWidth/(colorscale.length))
                .attr("height", legendRectHeight)
                .style("fill", color(((KantRange[1]-KantRange[0])/(colorscale.length-1))*i));
     }

        // LEGEND AXIS      
        const legendScale = d3.scaleLinear()
                              .domain(KantRange)
                              .range([0, legendRectWidth]);

        const legendAxis = d3.axisBottom(legendScale)
                            .tickValues(KantRange);

        legend.append("g")
              .attr("transform", "translate(0, " + legendRectHeight + ")")
              .call(legendAxis);
      // Add legend: circles

      const theDraw = d3.select(chart5.current);
      theDraw.selectAll('*').remove();
     
      const myChart2 = Cartogram();

     

      const colorKant = d3.scaleQuantize()
      .domain(KantRange)
      .range(colorscale);

      
      const NamesKant = Object.keys(Kantone[0]);

      
      myChart2
             .topoJson(topology)
             .topoObjectName('cantons')
            .value(d => KantDat[NamesKant[d.id-1]])
             .color( d => colorKant(KantDat[NamesKant[d.id-1]]))
             .label( d  => `${NamesKant[d.id-1]} ` )
             .projection(projection)
             .iterations(distortionValue)
           //   .units(' per capita')
           //   .valFormatter(d3.format('$,.0f'))
             ((chart5.current))
             ; 
     

      }
      );
  }, [VarIndex, colorscale, distortionValue]);

  function tooltipDisplay(name, interest1, interest2, perc) {
    // const info = `${county.area_name}, ${county.state}\n${county.bachelorsOrHigher}%`;
    const info = perc ? (`${name},\n ${interest1}\n\n${interest2}%`) : (`${name},\n ${interest1}\n\n${interest2}`);
    // const info = county;
    d3.select(`.${styles.tooltip}`).text(info);
  }

  
//   let zoom = d3.zoom()
// 	.on('zoom', handleZoom);

// function handleZoom(e) {
// 	d3.select(chart.current)
// 		.attr('transform', e.transform);
// }

// function initZoom() {
// 	d3.select(chart.current)
// 		.call(zoom);
// }

// initZoom();



  return (
    <> 
      <div ref={chart5} >
      </div>
      <MainStyle>
      <svg ref={chart} className={styles.chart} viewBox={"0 -50 1200 200"}>
  
      </svg>
      </MainStyle>
      
      <svg ref={chart4} viewBox={"200 0 1200 1200"}>
      </svg>

      {/* <div ref={chart2} >
      </div> */}


     

      

    </>
  )
}

export default Distortion