import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import { App } from '../../../App';
import { ZeroPage } from '../../../pages/Zero/ZeroPage';
import { FirstPage } from '../../../pages/First_PreProcessing/FirstPage';
import { SecondPage } from '../../../pages/Second_DataCleaning/SecondPage';
import { ThirdPage } from '../../../pages/Three_VisFundamentals/ThirdPage';
import { FourthPage } from '../../../pages/Fourth_DimsReduction/FourthPage';
import { FifthPage } from '../../../pages/Fifth_Clustering/FifthPage';
import { SixthPage } from '../../../pages/Sixth_Multivariate/SixthPage';
import { SeventhPage } from '../../../pages/Seventh_GeoMapping/SeventhPage';

/* import { TexturingPage } from '../../pages/Three/ThirdPage';
import { TransformationsPage } from '../../pages/Four/FourthPage'; */

export function Routes() {

  return (
    <Switch>

      {/* <Route path="/three">
            <SecondPage 
            data2 = {newdata2}
            />
          </Route>  */}

      <Route path="/seven">
        <SeventhPage />
      </Route>

      <Route path="/one">
        <FirstPage />
      </Route>

      <Route path="/two">
        <SecondPage
        />
      </Route>

      <Route path="/three">
        <ThirdPage
        />
      </Route>

      <Route path="/four">
        <FourthPage
        />
      </Route>

      <Route path="/five">
        <FifthPage
        />
      </Route>

      <Route path="/six">
        <SixthPage
        />
      </Route>



      <Route path="/">
        <FirstPage
        />
      </Route>


      {/*<Route path="/three" component={ThirdPage} />
      <Route path="/four" component={FourthPage} /> */}
    </Switch>
  );
};

