import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import irisData_original from './../../datasets/irisData_original.json';
import credit from './../../datasets/credit.json';
import pingu from './../../datasets/pingu.json';
import sales from './../../datasets/sales.json';

import styled from 'styled-components';
import "parcoord-es/dist/parcoords.css"
import { makeStyles } from '@material-ui/core/styles';
import 'parcoord-es/dist/parcoords.css';
import MuiInput from '@mui/material/Input';
import SideBar1 from './components/SideBar1';

const Input = styled(MuiInput)`
  width: 42px;
`;

const Input2 = styled('input')({
  display: 'none',
});
//////
//
//TODO
/////
//> Yes/No brushPredicate
//> select one curve
//> Selected points are colored dark red, and the subspace containing them is shown in grey

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function FirstPage() { 
 
  const classes = useStyles();

    const datasets = ["IRIS","PENGUIN","FINANCE","SALES"];
    const [dataMain, changeDataSet] = useState(["IRIS"]);
    const [data2, setData2] = useState([]);
    const [keys2, setKeys2] = useState([]);
    const [keys3, setKeys3] = useState([]);
    const [keys4, setKeys4] = useState([]);
    const [keys5, setKeys5] = useState([]);



    
    const Title = styled.h2`
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;

  const [dataset, setdataset] = React.useState('iris');

  const Datasets =
  {
    'CreditCard': credit,
    'iris': irisData_original,
    'penguin': pingu,
    'sales': sales,
  };


useEffect(() => {
    
    setData2(Datasets[dataset]);
    // setKeys2(Object.keys(irisData[0]));
    const allKeys = Object.keys(Datasets[dataset][0]);
    const NewArr = Object.values(Datasets[dataset][0]).filter(function(d) { return isNumber(d) });

    //numerical keys
    setKeys2((Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][0][d])) return d  })));
    //string keys
    setKeys3((Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  })));

    // var arrayVal = Object.entries(Datasets[dataset][0]).map(item => Object.keys(Datasets[dataset][0]).filter(function(d) { return d !==  })));
    // setKeys3((Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][d])) return d  })));

    // string keys
    const strKey = (Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  }));

    // Keys of Global
    setKeys4(Object.keys(Datasets));

    setKeys5(Object.keys(groupByAuto(Datasets[dataset], strKey[0])));

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dataset])

// grouping data
function groupByAuto(data, key){
  var groups={};
  for(var i in data){
      if(!groups.hasOwnProperty(data[i][key])) groups[data[i][key]]=[];
      groups[data[i][key]].push(data[i]);
  }
  return groups;
}




// check if number or string
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

  return (
    <>
{console.log(Object.keys(groupByAuto(data2, keys3[0])),keys3, keys5[0])}
      <Layout
                title="Dataset Visualization"
               >
               

                <SideBar1
                 data2={data2}
                 keys={keys2}
                 keys2={keys3}
                 keys3={keys4}
                 keys4={keys5} // subcategories
                //  keys4={Object.keys(groupByAuto(data2, keys3[0]))} // subcategories
                 setKeys4={setKeys5}
                 title={"Data Preprocessing"}
                 subtitle={""}
                 changeDataSet={changeDataSet}
                 setdataset={setdataset}
                 dataset={dataset}
                 dataMain={dataMain}
              />
                
            </Layout>
        
    </>
  );
}
