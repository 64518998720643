import React, { useEffect, useRef } from 'react';
import { axisLeft, axisBottom, select } from 'd3';
import styled from 'styled-components';
import PropsTypes from 'prop-types';

const Tick = styled.g`
  transform: ${props =>
    props.axisType === 'yAxis' && `translate(0, ${props.innerHeight}px)`};

  path,
  line {
    //stroke: #dcdbdb;
  }

  text {
    font-size: 0.8rem;
  }
`;

function Axes({
  xScale,
  yScale,
  innerHeight,
  yAxisTickFormat,
  xAxisTickFormat,
  yTickSize,
  xTickPadding,
  yTickPadding,
}) {
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);

  useEffect(() => {
    const xGroup = select(xAxisRef.current);
    const yGroup = select(yAxisRef.current);
    const xAxis = axisBottom(xScale)
      .tickPadding(xTickPadding);
    const yAxis = axisLeft(yScale)
      .tickPadding(yTickPadding);

    if (yAxisTickFormat) yAxis.tickFormat(yAxisTickFormat); // Line Chart
    if (xAxisTickFormat) xAxis.tickFormat(xAxisTickFormat); // Bar Chart

    xGroup.transition().duration(1000).call(yAxis);
    yGroup.transition().duration(1000).call(xAxis);
  });

  return (
    <g>
      <Tick ref={xAxisRef} axisType="xAxis" />
      <Tick ref={yAxisRef} axisType="yAxis" innerHeight={innerHeight} />
    </g>
  );
}



export default React.memo(Axes);
