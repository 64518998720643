import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  bin,
  scaleLinear,
  max,
  format,
  select,
  extent,
  mean,
  range,
  deviation,
  line,
  curveMonotoneX,
  median
} from 'd3';

import Grid from '@material-ui/core/Grid';
import Axes from '../Axes/Axes';
import { Group, MapWrapper, Map} from '../../style/style';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AxisLabel2 from '../AxisLabel/AxisLabel2';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const XAxisLabel = styled.g`
  rect {
    width: 15px;
    height: 15px;
    fill: #00a9ec;
  }

  text {
    font-size: 1rem;
  }
  
  line {
    fill: #00a9ec;
  }
`;

const MeanLabel = styled.g`
  rect {
    width: 15px;
    height: 15px;
    fill: #e798f7;
  }

  text {
    font-size: 1rem;
  }
  
  line {
    fill: #e798f7;
    stroke-linecap: round;
  }
`;

const MedianLabel = styled.g`
  rect {
    fill: #e798f7;
    stroke-linecap: round;
  }

  text {
    font-size: 1rem;
  }
  
  line {
    fill: #e798f7;
    stroke-linecap: round;
    &:hover {
      fill: #e46aa7;
    }
  }
`;

const GroupedRect = styled.g`
  rect {
    fill: #00a9ec;
    transition: all 0.2s;
    

    &:hover {
      fill: #e46aa7;
      stroke-width: 2px;
      stroke: #5a1354;
    }
  }

  line {
    fill: #00a9ec;

  }
`;



const GroupedLine = styled.g`
  text {
    font-size: 0.9rem;
  }

  line {
    fill: #e798f7;
    stroke-linecap: round;
   
  }
`;



const LinePath = styled.path`
  fill: none;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
  mix-blend-mode: multiply;
  &:hover {
     stroke-color: #e46aa7;
  }
`;

export default function BarChart({ data2, width, height, keys}) {
  const rectRef = useRef();
  const lineRef = useRef();
  const lineRef2 = useRef();

  const pathRef = useRef();

const points = [];

const [valueBin, setValueBin] = React.useState(30);
// const [indexVal, setIndexVal] = React.useState(keys[2]);
const [menutitle, setMenuTitle] = React.useState();


    useEffect(() => {
       
        setMenuTitle(keys[0]);
    
    }, [keys])

  useEffect(() => {
    // Animation rect with select
    const group = select(rectRef.current);
    // const text = select(textRef.current);
    const line = select(lineRef.current);
    const line2 = select(lineRef2.current);

    // const path = select(pathRef.current);
    // handleSelect();
    handleDrawRect(group);
    // handleDrawText(text);
    handleDrawLine(line);
    handleDrawLine2(line2);
    // handleDrawNormal(path);
    // const keyVal = keys[1];
    // const xVal = d => d[keyVal];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2, valueBin, menutitle]);

  

  const margin = { top: 50, right: 100, bottom: 70, left: 110 };
  const innerWidth = width - margin.right - margin.left;
  const innerHeight = height - margin.top - margin.bottom;

  const yValue = d => d.length;
  const xValue = d => d[menutitle];

  // const xScale = scaleBand()
  //   .domain(data2.map(xValue))
  //   .range([0,innerWidth])
  //   .padding(0.3);

const xScale = 
   scaleLinear()
    .domain(extent(data2, xValue))
    .range([0, innerWidth])
    .nice()
    
const barPadding = 1
  
const binsGenerator = 
  bin()
  .domain(xScale.domain())
  .value(xValue)
  .thresholds(valueBin)
  
const bins = binsGenerator(data2)  

const yScale = 
  scaleLinear()
  .domain([0, max(bins, yValue)])
  .range([innerHeight, 0])
  .nice()

const normal_curve = (points) => {
    for (let value of range(...xScale.domain(), 1/(10*valueBin))) {
      points.push([value, dnorm(value, meanV, stdV) * (300/valueBin) *xValue.length])
    }
   return points; 
  }
  
const dnorm = (x, mean, sd) => 
    1 / sd * dnorm_standard((x - mean) / sd);

const dnorm_standard = x => 
  1 / Math.sqrt(2 * Math.PI) * Math.exp(-0.5 * Math.pow(x, 2));
  
const handleDrawRect = group => {
    group
      .selectAll('rect')
      .data(bins)
      .join('rect')
      .attr('width', d => max([0, xScale(d.x1) - xScale(d.x0) - barPadding]))
      .attr('x', d => xScale(d.x0) + barPadding)
      .transition()
      .duration(750)
      .attr('height', d => innerHeight - yScale(yValue(d)))
      .attr('y', d => yScale(yValue(d)));
    
  };

  const meanV = mean(data2, xValue)

  const medianV = median(data2, xValue)

  const stdV = deviation(data2, xValue)

  // const handleDrawText = text => {
  //   text
  //     .selectAll('text')
  //     .data(bins)
  //     .join('text')
  //     .attr('x', xScale(meanV))
  //     .attr('y', 15)
  //     .text('mean')
  //     .attr('fill', 'maroon')
  //     .style('font-size', '13px')
  //     .style('text-anchor', 'middle')
  //       };

  const handleDrawLine = line => {
          line
            .selectAll('line')
            .data(bins)
            .join('line')
            .attr("stroke", "blue")
            .attr("stroke-dasharray", "4")
            .attr('x1', xScale(meanV))
            .attr('x2', xScale(meanV))
            .attr('y1', 30)
            .attr('y2', innerHeight)
              };

  const handleDrawLine2 = line2 => {
    line2
      .selectAll('line')
      .data(bins)
      .join('line')
      .attr("stroke", "blue")
      .attr("stroke-dasharray", "4")
      .attr('x1', xScale(medianV))
      .attr('x2', xScale(medianV))
      .attr('y1', 30)
      .attr('y2', innerHeight)
        };

  // const handleDrawNormal = normal => {
  //         normal
  //           .selectAll('path')
  //           .data(bins)
  //           .join('path')
  //           .datum(normal_curve(points))
  //           .attr("stroke", "#c4238f")
  //           .attr("stroke-width", 2)
  //           .attr("d", lineB)
  //           .attr("fill", "none");
  //       };

  // const handleSelect = (event) => {
  //   setIndexVal();
  //   setAnchorEl(null);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = (KeyName) => (event) => {
      setAnchorEl(null);
      setMenuTitle(KeyName);
    };

  const handleChangeBin = (event, newValue) => {
    setValueBin(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const xAixsTickFormat = number => format('.2s')(number).replace('G', 'B');

 // const hello = normal_curve(points);

 //const hello = normal_curve(points);

 const lineB = line()
  .x(d => xScale(d[0]))
  .y(d => yScale(d[1]))
  .curve(curveMonotoneX)

  return (
    <>
    <MapWrapper>
            <Map>
    {console.log("hello", points)}
      {/* <Dropdown onChange={handleChange} value={sortOpt}>
        <option value="highest">Highest</option>
        <option value="lowest">Lowest</option>
      </Dropdown> */}
        <svg width={width} height={height}>
          <Group
            x={width}
            y={height} 
            right={margin.right}
            top={margin.top}>
            <Axes
              xScale={xScale}
              yScale={yScale}
              innerHeight={innerHeight}
              yTickSize={0}
              xTickPadding={10}
              yTickPadding={10}
              xAixsTickFormat={xAixsTickFormat}
            />
          <XAxisLabel>
            <rect y={15} x={760} />
            <text y={25} x={780}>
              {menutitle}
            </text>
          </XAxisLabel>      
            
            <AxisLabel2
            innerHeight={innerHeight}
            innerWidth={innerWidth}
            axisPadding = {36}
            yLabel = {'frequency'}
            xLabel = {menutitle}
            marginLeft = {margin.left-40}
            />

            <GroupedRect ref={rectRef} />
            {/* <GroupedText ref={textRef} /> */}
            <GroupedLine ref={lineRef} />
            <GroupedLine ref={lineRef2} />
            <LinePath
                  ref={pathRef}
                  d={lineB(normal_curve(points))}
                  stroke={"#00a9ec"}
                  pathLength={xValue.length}
                />
            <MeanLabel>
              <text y={15} x={xScale(meanV)-30}>
            mean
              </text>
            </MeanLabel>
            <MedianLabel>
              <text y={15} x={xScale(medianV)-10}>
            median
              </text>
            </MedianLabel>
          </Group>
        </svg>
        
        </Map>
            </MapWrapper>
      <Grid item xs style={{
                        position: 'absolute', right: '10%', top: '35%',
                        transform: 'translate(-50%, -50%)'
                    }}>
      
      <Typography id="non-linear-slider" gutterBottom>
        Change BinSize
      </Typography>
        <Slider 
            min={12}
            step={1}
            max={56}
            valueLabelDisplay="auto"
            value={valueBin} 
            onChange={handleChangeBin} 
            aria-labelledby="continuous-slider" />
      <Typography id="non-linear-slider" gutterBottom>
        Change Histogramdata
      </Typography>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} variant="contained" color="primary">
                    {menutitle}
            </Button>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              >
                    {keys.map( (KeyName, index) => (
                      <div>
                          <MenuItem key = {index} value={KeyName} onClick={handleClose(KeyName)}>{KeyName}</MenuItem>
                      </div> ))} 

          </Menu>

        </Grid>
        
            
    </>
  );
}



