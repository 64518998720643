import styled from 'styled-components';
import React from 'react';


const Wrapper = styled.footer`
  padding: 1rem 0;
  text-align: center;
`;

function Footer() {
  return (
    <Wrapper>
      <p></p>
    </Wrapper>
  );
}

export default Footer;
