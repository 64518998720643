import React, { useState, useEffect} from 'react';    
import Layout from '../../components/Layout/Layout';
import SideBar7 from './components/SideBar7';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function SeventhPage() {
 
  const classes = useStyles();


  return (
    <>
    <Layout
      title="Geomap"
      >
     <SideBar7
                 
                   title={"Geomapping"}
                   subtitle={""}
                />

    </Layout>
    </>
  );
}

