import React, { useState, useEffect } from 'react';
import corrData from './../../datasets/corrData.json';

import Layout from '../../components/Layout/Layout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import pingu from './../../datasets/pinguCorr.json';

import { v4 as uuid } from 'uuid';

import SideBarSecond from './components/SideBarSecond';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary
    }
}));

export function SecondPage() {


    const classes = useStyles();

    const [dataset, setdataset] = React.useState('penguin');

    const Datasets =
    {
        'iris': corrData,
        'penguin': pingu,
    };


    const [data, setData] = useState([null]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [keys2, setKeys2] = useState([]);
    const [keys3, setKeys3] = useState([]);
    const [keys6, setKeys6] = useState([]);
    const [ValueChosen, setValueChosen] = useState(null);
    const [colIdx, setColIdx] = useState(null);
    const [valueRow, setValue] = useState(null);
    const [resetVal, setResetVal] = useState(false);
    const [keys4, setKeys4] = useState([]);
    const [keys5, setKeys5] = useState([]);

    const [allKeys, setAllKeys] = useState([]);



    const [state, setState] = React.useState({
        sizeSwitch: false,
        colorSwitch: true,
        brightnessSwitch: false,
        orientationSwitch: false,
        markSwitch: false,
        textureSwitch: false,
        motionSwitch: false,
        logSwitchX: false,
        logSwitchY: false,

    });





    const [selectedKeyValuePair, setselectedKeyValuePair] = useState({});

    useEffect(() => {

        setData2(Datasets[dataset]);
        setData3(Datasets[dataset]);
        // setKeys2(Object.keys(irisData[0]));
        const allKeys = Object.keys(Datasets[dataset][0]);
        const NewArr = Object.values(Datasets[dataset][0]).filter(function (d) { return isNumber(d) });

        //numerical keys
        setKeys2((Object.keys(Datasets[dataset][0]).filter(function (d) { if (isNumber(Datasets[dataset][0][d])) return d })));
        //string keys
        setKeys3((Object.keys(Datasets[dataset][0]).filter(function (d) { if (!isNumber(Datasets[dataset][0][d])) return d })));


        // string keys
        const strKey = (Object.keys(Datasets[dataset][0]).filter(function (d) { if (!isNumber(Datasets[dataset][0][d])) return d }));

        // Keys of Global
        setKeys4(Object.keys(Datasets));

        setKeys5(Object.keys(groupByAuto(Datasets[dataset], strKey[0])));

        const dataIni = Datasets[dataset].map(record => {
            return { ...record, id: uuid() };
        });
        setData(dataIni);
        setKeys6((Object.keys(Datasets[dataset][0]).filter(function (d) { return d !== "species" })))

        const Keys = Object.keys(dataIni[0]);
        var errorIDs = [];
        var colIdxErr = [];
        if (dataset === 'iris') {
            errorIDs =
                [dataIni.filter(
                    person => person[Keys[2]] === -4.9),
                dataIni.filter(
                    person => person[Keys[4]] === 'barbosa')
                ];
            colIdxErr = [2, 4];
        } else {
            errorIDs =
                [dataIni.filter(
                    person => person[Keys[2]] === -39.5),
                dataIni.filter(
                    person => person[Keys[4]] === 'Null'),
                dataIni.filter(
                    person => person[Keys[0]] === 'Australia'),
                dataIni.filter(
                    person => person[Keys[3]] === 1800)
                ];
            colIdxErr = [2, 4, 0, 3];

        }

        const filteredData = errorIDs.map(person => person.map(person2 => person2.id));

        setValue(filteredData);
        setColIdx(colIdxErr);

        setAllKeys(allKeys);

    }, [resetVal, dataset])



    useEffect(() => {

        console.log(selectedKeyValuePair);


    }, [selectedKeyValuePair])

    function groupByAuto(data, key) {
        var groups = {};
        for (var i in data) {
            if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
            groups[data[i][key]].push(data[i]);
        }
        return groups;
    }




    // check if number or string
    function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }



    return (


        <div className="App">
            <Layout
                title="Dataset Visualization"
            >




                <SideBarSecond
                    title={"Data Cleaning"}
                    subtitle={`${dataset}Dataset`}
                    data={data}
                    data2={data2}
                    data3={data3}
                    keys={keys2}
                    keys3={keys4}
                    keys4={keys3}
                    keys2={keys6}
                    allKeys={allKeys}
                    setData={setData}
                    setData2={setData2}
                    resetVal={resetVal}
                    setResetVal={setResetVal}
                    valueRow={valueRow}
                    setdataset={setdataset}
                    dataset={dataset}
                    colIdx={colIdx}
                    keys6={keys6}
                    selectedKeyValuePair={selectedKeyValuePair}
                    setselectedKeyValuePair={setselectedKeyValuePair}
                    ValueChosen={ValueChosen}
                    setValueChosen={setValueChosen}
                />



            </Layout>
        </div>
    );
}


