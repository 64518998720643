import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  bin,
  scaleLinear,
  max,
  format,
  select,
  extent,
  mean,
  range,
  deviation,
  line,
  curveMonotoneX,
  median,
} from 'd3';
import { Group, MapWrapper, Map } from './style/style';
import Axes from './Axes/Axes';
import AxisLabel2 from './AxisLabel/AxisLabel2';
import * as d3 from 'd3';
import * as styles from "./BarChart.module.css"
import Tooltip from '@mui/material/Tooltip';


const XAxisLabel = styled.g`
  rect {
    width: 15px;
    height: 15px;
    fill: #FF8A05;
  }

  text {
    font-size: 1rem;
  }
  
  line {
    fill: #00a9ec;
  }
`;

const MeanLabel = styled.g`
  rect {
    width: 15px;
    height: 15px;
    fill: #e798f7;
  }

  text {
    font-size: 1.2rem;
    font-weight: bolder;
  }
  
  line {
    fill: #e46aa7;
    stroke-linecap: round;
  }
`;

const MedianLabel = styled.g`
  rect {
    fill: #e798f7;
    stroke-linecap: round;
  }

  text {
    font-size: 1.2rem;
    font-weight: bolder;
  }
  
  line {
    fill: #e46aa7;
    stroke-linecap: round;
    &:hover {
      fill: #e46aa7;
    }
  }
`;

const StdLabel = styled.g`
  rect {
    fill: #e798f7;
    stroke-linecap: round;
  }

  text {
    font-size: 1.2rem;
    font-weight: bolder;

  }
  
  line {
    fill: #e46aa7;
    stroke-linecap: round;
    &:hover {
      fill: #e46aa7;
    }
  }
`;

const GroupedRect = styled.g`
  rect {
    fill: #FF8A05;
    transition: all 0.2s;
    

    &:hover {
      fill: #b8444a;
      stroke-width: .5px;
      stroke:  #B8B8B8;
    }

    body {
      font: 10px sans-serif;
    }
    
    
     

  }

  line {
    fill: #00a9ec;

  }

  
`;



const GroupedLine = styled.g`
  text {
    font-size: 0.9rem;
  }

  line {
    stroke-width: 3;
    stroke: #B8B8B8;
    stroke-linecap: round;
    stroke-linejoin: round;
    &:hover {
      stroke: #e46aa7;
      stroke-width: 6;
 
   }
  }
`;



const LinePath = styled.path`
  fill: none;
  stroke-width: 3;
  stroke-color: orange;
  stroke-linejoin: round;
  stroke-linecap: round;
  mix-blend-mode: multiply;

  &:hover {
     stroke: #e46aa7;
     stroke-width: 6;

  }
`;

const StdLine = styled.g`
  text {
    font-size: 0.9rem;
  }

  line {
    stroke-width: 3;
    stroke: #B8B8B8;
    stroke-linecap: round;
    stroke-linejoin: round;

    &:hover {
      stroke: #e46aa7;
      stroke-width: 6;
 
   }
  }
`;


export default function BarChart({ data2, width, height, keys, menutitle, valueBin,
  menutitle2,
  menutitle3,
  filterTrue,
  filter,
  filterData }) {
  const rectRef = useRef();
  const lineRef = useRef();
  const lineRef2 = useRef();
  const lineRef3 = useRef();
  const lineRef4 = useRef();



  const pathRef = useRef();
  const stdRef = useRef();

  const chart4 = React.useRef(null);

  const points = [];

  useEffect(() => {
    // Animation rect with select
    const group = select(rectRef.current);
    const line = select(lineRef.current);
    const line2 = select(lineRef2.current);
    const line3 = select(lineRef3.current);
    const line4 = select(lineRef4.current);



    // SET UP TOOLTIP
    const tooltip = d3.select(chart4.current)
      .append('div')
      .attr('class', 'hidden');

    handleDrawRect(group, tooltip);
    handleDrawLine(line);
    handleDrawLine2(line2);
    handleDrawLine3(line3);
    handleDrawLine4(line4);







  }, [data2, valueBin, menutitle, filterTrue, filter]);

  const tooltipOffsetX = 20;
  const tooltipOffsetY = 73;


  const margin = { top: 50, right: 50, bottom: 70, left: 50 };
  const innerWidth = width - margin.right - margin.left;
  const innerHeight = height - margin.top - margin.bottom;

  const yValue = d => d.length;
  const xValue = d => d[menutitle];


  const xScale =
    scaleLinear()
      .domain(extent(data2, xValue))
      .range([0, innerWidth])
      .nice()

  const barPadding = 1

  const binsGenerator =
    bin()
      .domain(xScale.domain())
      .value(xValue)
      .thresholds(valueBin)

  // const bins = binsGenerator(data2);
  const bins = filterTrue ? binsGenerator(filterData[filter]) : binsGenerator(data2);

 
  const meanV = filterTrue ? mean(filterData[filter], xValue) : mean(data2, xValue);

  const medianV = filterTrue ? median(filterData[filter], xValue) : median(data2, xValue);

  const stdV = filterTrue ? deviation(filterData[filter], xValue) : deviation(data2, xValue);




  const dnorm = (x, mean = 0, sd = 1) =>
    1 / sd * dnorm_standard((x - mean) / sd);

  const dnorm_standard = x =>
    1 / Math.sqrt(2 * Math.PI) * Math.exp(-0.5 * Math.pow(x, 2));

  const binsWidth = (xScale.domain()[1]-xScale.domain()[0])/bins.length; 

  const samplelength = filterTrue ? Object.values(filterData[filter].map(d=> d[menutitle])).length:Object.values(data2.map(d=> d[menutitle])).length;

  const normal_curve = (points) => {
    points = [];
    for (let value of range(...xScale.domain(), binsWidth/100)) {
      points.push([value, dnorm(value, meanV, stdV) * binsWidth * (samplelength) ])
    }
    return points;
  }

  const curveheight = dnorm(meanV, meanV, stdV)* binsWidth * (samplelength);


  const yScale =
    scaleLinear()
      .domain([0, max([curveheight, max(bins, yValue)])])
      .range([innerHeight, 0])
      .nice()

  const handleDrawRect = (group, tooltip) => {
    group
      .selectAll('rect')
      .data(bins)
      .join('rect')
      .attr('width', d => max([0, xScale(d.x1) - xScale(d.x0) - barPadding]))
      .attr('x', d => xScale(d.x0) + barPadding)
      .attr('height', d => innerHeight - yScale(yValue(d)))
      .attr('y', d => yScale(yValue(d)))
      .on("mouseover", (event, d) => {
        tooltip.attr("class", styles.tooltip)
          // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
          .style("left", event.pageX + tooltipOffsetX + "px")
          .style("top", event.pageY - tooltipOffsetY + "px");
        // tooltipDisplay('Hello');
        // tooltipDisplay(educationData.find(e => e.fips === d.id));
        tooltipDisplay(menutitle, d.x0, d.x1, "Frequency:", yValue(d));
        // tooltipDisplay(d.id);
      })
      .on("mouseout", (e, d) => {
        // d3.select(e.currentTarget).style("fill", getColor(d));  
        tooltip.attr("class", styles.hidden);
      });

  };


 

  const handleDrawLine = line => {
    line
      .selectAll('line')
      .data(bins)
      .join('line')
      .attr("stroke-dasharray", "2")
      .attr('x1', xScale(meanV))
      .attr('x2', xScale(meanV))
      .attr('y1', 30)
      .attr('y2', innerHeight)
  };

  const handleDrawLine2 = line2 => {
    line2
      .selectAll('line')
      .data(bins)
      .join('line')
      .attr("stroke-dasharray", "4")
      .attr('x1', xScale(medianV))
      .attr('x2', xScale(medianV))
      .attr('y1', 30)
      .attr('y2', innerHeight)
  };

  const handleDrawLine3 = line3 => {
    line3
      .selectAll('line')
      .data(bins)
      .join('line')
      .attr("stroke-dasharray", "4")
      .attr('x1', xScale(meanV - stdV))
      .attr('x2', xScale(meanV - stdV))
      .attr('y1', innerHeight)
      .attr('y2', innerHeight+30)
  };

  const handleDrawLine4 = line4 => {
    line4
      .selectAll('line')
      .data(bins)
      .join('line')
      .attr("stroke-dasharray", "4")
      .attr('x1', xScale(meanV + stdV))
      .attr('x2', xScale(meanV + stdV))
      .attr('y1', innerHeight)
      .attr('y2', innerHeight+25)
  };


  const widthBar = d => xScale(d.x1) - xScale(d.x0) - barPadding;

  const xAixsTickFormat = number => format('.2s')(number).replace('G', 'B');

  const lineB = line()
    .x(d => xScale(d[0]))
    .y(d => yScale(d[1]))
    .curve(curveMonotoneX);


  // SET UP TOOLTIP

  function tooltipDisplay(name, name2, interest1, interest2, interest3) {
    // const info = `${county.area_name}, ${county.state}\n${county.bachelorsOrHigher}%`;
    const info = (`${name},\n From ${name2} to ${interest1} \n\n${interest2}\n${interest3}`);
    // const info = county;
    d3.select(`.${styles.tooltip}`).text(info);
  }



  return (
    <>
      {console.log(extent(data2, xValue))}
      <MapWrapper>
        <Map>
          <svg viewBox="0 0 1200 500" >
            <Group
              x={width}
              y={height}
              right={margin.right}
              top={margin.top}>
              <Axes
                xScale={xScale}
                yScale={yScale}
                innerHeight={innerHeight}
                yTickSize={0}
                xTickPadding={10}
                yTickPadding={10}
                xAixsTickFormat={xAixsTickFormat}
              />
              <XAxisLabel>
                <rect y={15} x={760} />
                <text y={25} x={780}>
                  {menutitle}
                </text>
                <text y={70} x={780}>standard deviation (σ): {stdV ? stdV.toFixed(2) : ""} </text>
                <text y={55} x={780}>mean (µ): {meanV ? meanV.toFixed(2) : ""} </text>
                <text y={40} x={780}>median (m): {medianV ? medianV.toFixed(2) : ""} </text>
              </XAxisLabel>

              <AxisLabel2
                innerHeight={innerHeight}
                innerWidth={innerWidth}
                axisPadding={36}
                yLabel={'frequency'}
                xLabel={menutitle}
                marginLeft={margin.left - 40}
              />

              <GroupedRect ref={rectRef} />

              <Tooltip title="Standard Deviation" arrow placement="top">
                <StdLine ref={lineRef3} />
              </Tooltip>

              <Tooltip title="Standard Deviation" arrow placement="top">
                <StdLabel>
                  <text y={innerHeight+45} x={xScale(meanV-stdV)-3}>
                  -σ 
                  </text>
                </StdLabel>
              </Tooltip>

              <Tooltip title="Standard Deviation" arrow placement="top">
                <StdLine ref={lineRef4} />
              </Tooltip>

              <Tooltip title="Standard Deviation" arrow placement="top">
                <StdLabel>
                  <text y={innerHeight+45} x={xScale(meanV+stdV)-3}>
                  +σ 
                  </text>
                </StdLabel>
              </Tooltip>

              <Tooltip title="Mean" arrow placement="top">
                <GroupedLine ref={lineRef} />
              </Tooltip>

              <Tooltip title="Median" arrow placement="top">

                <MedianLabel>
                  <text y={15} x={xScale(medianV) - 5}>
                    m
                  </text>
                </MedianLabel>
              </Tooltip>
              <Tooltip title="Median" arrow placement="top">
                <GroupedLine
                  ref={lineRef2}
                />
              </Tooltip>

              <Tooltip title="Normal Curve" arrow placement="top">
                <LinePath
                  ref={pathRef}
                  d={lineB(normal_curve(points))}
                  stroke={"#e46aa7"}
                  pathLength={xValue.length}
                />
              </Tooltip>


              <MeanLabel>
                <text y={15} x={xScale(meanV)} color={'#690550'}>
                  µ
                </text>
              </MeanLabel>


            </Group>
          </svg>

        </Map>
      </MapWrapper>

      <div ref={chart4} >
      </div>

    </>
  );
}



