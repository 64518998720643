import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as nn from 'nearest-neighbor';

function SecondTable(props) {

    const useStyles = makeStyles({
        tableContainer: {
            height: '40vh',
            // width: '60vh',
            '&::-webkit-scrollbar': {
                width: '0.8em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#6c7a86',
                outline: '2px solid #6c7a86',
            }
        },
        tableLabel: {
            fontWeight: 'bold',
            background: '#969495',
            color: 'white',
        },
        isComparisonColumn: {
            color: '#ec008b',
        },
        selectedRow: {
            background: '#EEAF52',
            '& td': {
                color: 'grey',
                '&:hover': {
                    background: '#f9ebce',
                    color: 'black',
                },
            },
        },

        tableData: {
            cursor: 'pointer',
            '&:hover': {
                background: '#f9ebce',
                color: 'black',
            },
        },
        icon: {
            pointerEvents: 'none',
        },
    });


    const [selectedColumn, setSelectedColumn] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [value, setValue] = useState(null);
    const [isRow, setIsRow] = useState(false);

    const reduce = r => i => a => a.reduce(r, i);
    const sum = reduce((a, i) => a + i)(0);
    const length = a => a.length;


    const Options =
        [
            "------",
            "Discard Record",
            "Sentinel Value",
            "Average Value",
            "Nearest Neighbor",
            // "Substitute"


        ]


    const isErrorId = () => {
        const errorIDs =
            [props.data.filter(
                person => person[props.keys[2]] === -4.9),
            props.data.filter(
                person => person[props.keys[4]] === 'barbosa')
            ];

        const filteredData = errorIDs.map(person => person.map(person2 => person2.id));

        setIsRow(filteredData);
    }






    const handleClick = (event) => {
        setAnchorEl(event.target);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.target);
    };

    const open = Boolean(anchorEl);

    const setRow = (KeyName) => {
        { console.log("key", KeyName) }
        if (KeyName == "Discard Record") {
            let txt = window.confirm("You will discard this row now.");
            if (txt === true) {
                const filteredData = props.data.filter(person => person.id !== theID.id);

                setValue();
                props.setData(filteredData);
                props.setData2(filteredData);
            }

        }

        // props.keys (only numerical keys)
        const nameOfKey = props.allKeys[theCol];
        // isErrorId(rowId);


        const nameOfCol = theCol;
        if (KeyName == "Sentinel Value") {
            // alert('You will set a Sentinel value now.');


            var retVal = null;
            const enterValue = () => {

                retVal = prompt("Enter a numerical value : ", "");
                while (!isNumber(retVal)) {
                    retVal = prompt("Enter a numerical value : ", "");

                }
                return retVal;
            }

            const setVal = enterValue();
            let txt = window.confirm("You've set following sentinel value:  " + setVal);
            if (txt === true) {
                const newSentinel = props.data.map(p =>
                    (p.id === theID.id)
                        ? ({ ...p, [nameOfKey]: setVal })
                        : p
                );
                props.setData(newSentinel);
                props.setData2(newSentinel);
                setIsRow(true);
            }
        }

        if (KeyName == "Average Value") {
            if (props.keys.includes(nameOfKey)) {
                let txt = window.confirm("You will set an average value now.");
                if (txt === true) {
                    const vOC = props.data3.map(p => isNumber(p[nameOfKey]) ? p[nameOfKey] : null); //values of column
                    const avg3 = arr => (sum(arr) / length(arr)); //values of column
                    const num = avg3(vOC);


                    var averageValue = num.toFixed(1); //only 1 decimal point
                    { console.log("avg", vOC, avg3, num, averageValue) }

                    const newSentinel = props.data.map(p =>
                        (p.id === theID.id)
                            ? ({ ...p, [nameOfKey]: averageValue })
                            : p
                    );
                    props.setData(newSentinel);
                    props.setData2(newSentinel);
                };
            }
            else {
                window.confirm("Average value does not work here.");
            }
            // props.setData(newSentinel);
            // props.setData2(newSentinel);
            // setValue(newSentinel);
            // setIsRow(true);
        }

        if (KeyName == "Nearest Neighbor") {
            let txt = window.confirm("You will set the nearest neighbor value now.");
            if (txt === true) {

                // if (props.dataset==='penguin')
                {
                    var fieldsNum =
                        props.keysNum.map(d => {
                            return { name: d, measure: nn.comparisonMethods.number, max: 2000 }
                        }
                        )
                    var fields2Str = props.keyStr.map(d => {
                        return { name: d, measure: nn.comparisonMethods.word }
                    }
                    )

                        ;
                    var allfield = fieldsNum.concat(fields2Str);

                    console.log("field:", allfield)

                }
                const filteredData = props.data.filter(person => person.id !== theID.id);
                var proba = 0;
                var nearestN = 0;
                nn.findMostSimilar(theID, filteredData, allfield, function (nearestNeighbor, probability) {
                    console.log(theID);
                    console.log(nearestNeighbor);
                    console.log(probability);
                    proba = probability;
                    nearestN = nearestNeighbor;
                });


                let txt2 = window.confirm("The nearest neighbor is: " + Object.values(nearestN).splice(0, props.allKeys.length) + `\n` + "with accuracy (probability): " + proba.toFixed(2));
                if (txt2 === true) {
                    const newVal = props.data.map(p =>
                        (p.id === theID.id)
                            ? ({ ...p, [nameOfKey]: Object.values(nearestN)[theCol] })
                            : p
                    );
                    props.setData(newVal);
                    props.setData2(newVal);

                }



            };

        }


        props.setValueChosen(KeyName);
        // setValue(rowId);
        //setValue(KeyName6);
        setAnchorEl(null);

    }

    const handleClose = (event) => {
        setAnchorEl(null);
        setAnchorEl2(null);


    }


    // check if number or string
    function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }



    const [clicked, setClicked] = useState(false);

    const handleSelectTableCell = (e) => {
        let tableCell = e.target;

        let key = tableCell.getAttribute("datakey");
        let value = tableCell.getAttribute("datavalue");
        // props.setselectedKeyValuePair({ key, value });
        props.setselectedKeyValuePair(tableCell.id);

        let index = tableCell.getAttribute("index");
        setSelectedColumn(index);

        

        setClicked(!clicked);

    }

    const isSelectedRow = (iRow, idRow) => {
       
        let thisRowsData = props.data[iRow];
        let selectedValue = props.selectedKeyValuePair.value;

        var valueIDcopy = props.valueID;

        var isPart = false;
        if (valueIDcopy) {
            for (var i = 0; i < valueIDcopy.length; i++) {
                if (valueIDcopy[i] == thisRowsData["id"]) {
                    isPart = true;
                }
            }
        }
    

        return thisRowsData && thisRowsData.hasOwnProperty("id") && isPart;


    }


    const isSelectedColumn = (iRow, col) => {
    
        let thisRowsData = props.data[iRow];
        let selectedValue = props.selectedKeyValuePair.value;
        let valueIDcopy = props.valueID;
        let colID = props.colIdx;

        var isPart2 = false;
        if (valueIDcopy) {
            for (var i = 0; i < valueIDcopy.length; i++) {
                if (valueIDcopy[i] == thisRowsData["id"] && colID[i] == col) {
                    isPart2 = true;
                }
            }
        }

        return thisRowsData && thisRowsData.hasOwnProperty("id") && isPart2
            ;

     
    }


    const classes = useStyles();

    const [theID, setID] = useState(null);
    const [theCol, setHead] = useState(null);

    const [hoveringOver, setHoveringOver] = useState("");

    // hello = props.highCell.includes(hData) ? "hello":"no"

    // var hello = [];
    // const handleRowHover = (_value, hData) => {props.setCell(hData);  }
    // const handleRowHoverLeave = (event, propsData) => props.setCell("");

    return (
        <TableContainer className={classes.tableContainer} component={Paper} align="left">
            {console.log("thecol:", theCol,)}
            <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {props.allKeys.map((row, index) => (
                            <TableCell className={selectedColumn == index ? classes.tableLabel + " " + classes.isComparisonColumn : classes.tableLabel} align="center">{row}</TableCell>
                        ))}
                     
                    </TableRow>
                </TableHead>
                <TableBody>

                    {/* hover={props.highCell.includes(row) ? true : false} */}
                    {props.data.map((row, i) => (
                        <TableRow
                            key={i}
                            // hover={Object.values(row).includes(Object.values(props.highCell)) ? true : false} 
                            className={isSelectedRow(i, row) ? classes.selectedRow : ""}>
                            {props.allKeys.map((rowName, indexHead) => (
                                <TableCell className={classes.tableData} align="center"
                                    index={indexHead}
                                    datakey={rowName}
                                    datavalue={row && row[rowName] ? row[rowName] : ""}
                                    onClick={handleSelectTableCell}
                                // onMouseEnter={e => handleRowHover(e, row)}
                                // onMouseLeave={e => handleRowHoverLeave(e, props)}
                                >
                                    {
                                        (isSelectedColumn(i, indexHead)) ?
                                            (<div>
                                                <Button
                                                    id="demo-positioned-button"
                                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={event => { handleClick(event); setHead(indexHead); setID(row); }}
                                                    key={row.id}
                                                >
                                                    {row && row[rowName] ? row[rowName] : ""}
                                                </Button>
                                                <Menu
                                                    id="demo-positioned-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    onChange={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    {Options.map((KeyName6, index6) => (
                                                        <div>

                                                            <MenuItem key={index6} value={KeyName6} onClick={event => { handleClose(event); setRow(KeyName6) }}>{KeyName6}</MenuItem>
                                                        </div>))}

                                                </Menu>
                                            </div>
                                            ) :
                                            (row && row[rowName] ? row[rowName] : "")}
                                </TableCell>))
                            }
                         
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </TableContainer>
    );

}

export default SecondTable;