import React, { useState, useCallback, useRef, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import irisData_original from './../../datasets/irisData_original.json';
import credit from './../../datasets/credit.json';
import sales from './../../datasets/sales.json';
import styled from 'styled-components';
import "parcoord-es/dist/parcoords.css"
import { makeStyles } from '@material-ui/core/styles';
import 'parcoord-es/dist/parcoords.css';
import MuiInput from '@mui/material/Input';
import SideBar6 from './components/SideBar6';
import pingu from './../../datasets/pingu.json';



const Input = styled(MuiInput)`
  width: 42px;
`;

const Input2 = styled('input')({
  display: 'none',
});
//////
//
//TODO
/////
//> Yes/No brushPredicate
//> select one curve
//> Selected points are colored dark red, and the subspace containing them is shown in grey

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export function SixthPage() {

  const classes = useStyles();

  const Title = styled.h3`
    font-size: 2.3rem;
    font-weight: 800;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
    style={{ display: "block" }};
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;

  const SubTitle = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  style={{ display: "block" }};

  @media (max-width: 568px) {
    text-align: center;
  }
`;



  const [data2, setData2] = useState();
  const [keys, setKeys] = useState([]);
  const [keys2, setKeys2] = useState([]);
  const [keysMini, setKeysMini] = useState([]);


  const [dataset, setdataset] = React.useState("iris");

  const Datasets =
  {
    'CreditCard': credit,
    'iris': irisData_original,
    'sales': sales,
    'penguin': pingu,
  };


  const [colVar, setcolVar] = React.useState();

  




  useEffect(() => {


    setData2(Datasets[dataset]);
    const Axes = (Object.keys(Datasets[dataset][0]));

//     if (Object.keys(Datasets[dataset][0]).length > 8) {
     
//       setKeysMini(Object.keys(Datasets[dataset][0]).slice(0,8));
//     }
//     else 
// {
//   setKeysMini(Object.keys(Datasets[dataset][0]).slice(0,8));
//     }

    setKeys(Object.keys(Datasets[dataset][0]));
    setcolVar(Axes[1]);
    setKeys2(Object.keys(Datasets));

  }, [dataset, data2]);


  const handleChangeInDataSet = (event) => {
    setdataset(event.target.value);
  };

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    fileReader.readAsText(target.files[0]);
    fileReader.onload = (e) => {
      var contents = e.target.result;
      setData2(contents);
    };
  };

  


  return (
    <>
      {console.log("the real data")}
      <Layout
        title="Dataset Visualization"
      >

        <SideBar6
          data2={data2}
          keys={keys}
          keys2={keys2}
          keysMini={keysMini}
          setKeys={setKeys}
          setKeys2={setKeys2}
          title={"Parallel Coordinate"}
          subtitle={""}
          handleChangeInDataSet={handleChangeInDataSet}
          setdataset={setdataset}
          dataset={dataset}
          Datasets={Datasets}
        
        />

      </Layout>
    </>
  );
}
