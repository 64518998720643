import React, { useEffect } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';

import Slider from '@material-ui/core/Slider';

import Divider from '@material-ui/core/Divider';
import {
    Box, Grid,
} from '@material-ui/core';
import ScatterPlot5 from './ScatterPlot5';
import makeRandomGenerator from '../../../components/Functions/randomSeed'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';

const drawerWidth = 190;




// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
      minWidth: 150,
      align: 'center',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));


export default function SideBar(props) {
    const theme = useTheme();

    const classes = useStyles();


    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [valueCluster, setValueCluster] = React.useState(5);

    const [valueSeed, setValueSeed] = React.useState(1);
    const [valueSpread, setValueSpread] = React.useState(0.06);
    const [valueCentroid, setValueCentroid] = React.useState(3);

    const handleChangeCluster = (event, newValue3) => {
        setValueCluster(newValue3);
    };
    
    const handleChangeSeed = (event, newValue1) => {
        setValueSeed(newValue1);
    };

    const handleChangeSpread = (event, newValue2) => {
        setValueSpread(newValue2);
    };

    const handleChangeCentroid = (event, newValue3) => {
        setValueCentroid(newValue3);
    };

    





    const points = () => {

        const num_clusters = valueCluster;
        const num_points_per_cluster = 100;
        const p = [];

        const random_generator = makeRandomGenerator("" + valueSeed)
        for (var j = 0; j < num_clusters; j++) {
            const c_x = random_generator.random() * 0.8 + 0.1;
            const c_y = random_generator.random() * 0.8 + 0.1;
            for (var i = 0; i < num_points_per_cluster; i++) {
                p.push({ 'x': randn_bm(random_generator) * valueSpread + c_x, 'y': randn_bm(random_generator) * valueSpread + c_y });
            }
        }
        return p;
    }

    const randn_bm = (random_generator) => {
        var u = 0, v = 0;
        while (u === 0) u = random_generator.random(); //Converting [0,1) to (0,1)
        while (v === 0) v = random_generator.random();
        return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    }




    return (




        <Box sx={{ display: 'flex', align: 'center' }}>
            <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h4" noWrap sx={{ flexGrow: 1 }} component="div">
                    {props.title}
                    <br></br>
                    {props.subtitle}
                    </Typography>
                    
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{ '& svg': {
                            color: "inherit",
                            transition: '0.2s',
                            transform: 'translateX(0) rotate(0)',
                          },
                          '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                              transform: 'translateX(-4px) rotate(-20deg)',
                            },
                            '& svg:last-of-type': {
                              right: 0,
                              opacity: 1,
                            },
                          },
                          '&:after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                            bgcolor: 'divider',
                          },
                          ...(open && { display: 'none' }) }}
                    >
                        <Settings />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Main open={open}>
                <DrawerHeader />
                <ScatterPlot5
                    width={600}
                    height={500}
                    legendValue={300}
                    data={points()}
                    // buttonValues={state}
                    tooltipValue={170}
                    // sliderStep={valueStep}
                    sliderSeed={valueSeed}
                    sliderSpread={valueSpread}
                    sliderCentroid={valueCentroid}



                />
            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Card sx={{ minWidth: drawerWidth }}>
                    <CardContent>
                        <Box >

                        </Box>


                        <>

                            <Box >
                                <br></br>
                                Seed
                                <Slider
                                    min={0}
                                    step={1}
                                    max={8}
                                    valueLabelDisplay="auto"
                                    value={valueSeed}
                                    onChange={handleChangeSeed}
                                    aria-labelledby="continuous-slider" />
                            </Box>
                            <Box >
                                Spread
                                <Slider

                                    min={0.01}
                                    step={0.01}
                                    max={0.1}
                                    valueLabelDisplay="auto"
                                    value={valueSpread}
                                    onChange={handleChangeSpread}
                                    aria-labelledby="continuous-slider" />
                            </Box>
                            <Box >

                                Number of Centroids (k)
                                <Slider
                                    min={2}
                                    step={1}
                                    max={10}
                                    valueLabelDisplay="auto"
                                    value={valueCentroid}
                                    onChange={handleChangeCentroid}
                                    aria-labelledby="continuous-slider" />
                            </Box>
                            <Box >
                            Number of Clusters
                                <Slider
                                    min={2}
                                    step={1}
                                    max={10}
                                    valueLabelDisplay="auto"
                                    value={valueCluster}
                                    onChange={handleChangeCluster}
                                    aria-labelledby="continuous-slider" />
                            

                            </Box>



                        </>
                    </CardContent>
                </Card>
            </Drawer>
        </Box>






    );
}
