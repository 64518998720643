import React, { useRef, useEffect} from 'react';
import {
    select,
} from 'd3';
import * as d3 from 'd3';
//import textures from 'textures';
import {
    Group, MapWrapper, Map,
    //,
    //Dropdown
} from '../../../style/style';
import styled from 'styled-components';
import AxisLabel2 from '../../../components/AxisLabel/AxisLabel2';
import Axes from '../../../components/Axes/Axes';
//import PanelTopRight from '../../components/Boxes/surfaces/PanelTopRight';
//import Accordions from './BoxSettings/Accordions';
import {
    Box,
    Button,
} from '@material-ui/core';
import Container from 'react-bootstrap/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import Grid from '@mui/material/Grid';




const GroupedCircle = styled.g`
  circle {
    transition: all 0.2s;
    

    &:hover {
      fill: #e46aa7;
      stroke-width: 2px;
      stroke: #5a1354;
    }
  }

  line {
    fill: #00a9ec;

  }
`;

const MainStyle = styled.h2`
font-size: 2.3rem;
font-weight: 600;
margin-bottom: 1rem;
margin-top: 1rem;
text-align: center;
justifyContent: center;

@media (max-width: 568px) {
  text-align: center;
}
`;





function ScatterPlot5(props) {

    const steps = ['start with dataset that is unlabelled', 'choose some initial centroids (k) by picking random datapoints', 'All the datapoints are allocated to their nearest centroid',
    'move the centroids to the mean of its allocated datapoints','remove the old allocation to make a new one','steps repeated until convergence','centroids no longer move and the algorithm has converged'];

     //////////////////////////////////////////////////////////////

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set()); 

    //const [value, setValue] = useState('linear');


    const circleRef = useRef();
    const circleRef2 = useRef();
    const textRef = useRef();

    const margin = { top: 50, right: 30, bottom: 70, left: 110 };
    const innerWidth = props.width - margin.right - margin.left;
    const innerHeight = props.height - margin.top - margin.bottom;


    const xScale = d3.scaleLinear()
        .domain([0.0, 1.0]).nice()
        .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
        .domain([0.0, 1.0]).nice()
        .range([innerHeight,0]);

    const [num_centroids, setNumCentroid] = React.useState(props.sliderCentroid);



    const [show_colour, setShowColor] = React.useState(true);

    const [show_centroids, setCentroids] = React.useState(true);




    const [text, setText] = React.useState("");


    const [stepslider, setStepslider] = React.useState(1);

    const handleChangeStep = (event, newValue4) => {
        setStepslider(newValue4);
    };

    useEffect(() => {

        setNumCentroid(props.sliderCentroid);
        setStepslider(activeStep+1);

        //  //////////K-MEANS////////////
        var show_colour2 = true;
        var show_centroids2 = true;
        var cluster_index2 = stepslider - 5;
        var centroids_index2 = stepslider - 5;
        var show_text2 = false;
        var text2 = "";
        

        if (stepslider === 1) {
            cluster_index2 = 0;
            centroids_index2 = 0;
            show_colour2 = false;
            show_centroids2 = false;
            show_text2 = true;
            text2 = "To start with, we have some unlabelled data";
        } else if (stepslider === 2) {
            cluster_index2 = 0;
            centroids_index2 = 0;
            show_colour2 = false;
            show_centroids2 = true;
            show_text2 = true;
            text2 = "We choose some initial centroids by picking random datapoints";
        } else if (stepslider === 3) {
            cluster_index2 = 0;
            centroids_index2 = 0;
            show_colour2 = true;
            show_centroids2 = true;
            show_text2 = true;
            text2 = "All the datapoints are allocated to their nearest centroid";
        } else if (stepslider ===4) {
            cluster_index2 = 0;
            centroids_index2 = 1;
            show_colour2 = true;
            show_centroids2 = true;
            show_text2 = true;
            text2 = "We then move the centroids to the mean of its allocated datapoints";
        } else if (stepslider === 5) {
            cluster_index2 = 0;
            centroids_index2 = 1;
            show_colour2 = false;
            show_centroids2 = true;
            show_text2 = true;
            text2 = "We then remove the old allocation to make a new one";
        } else if (stepslider === 6) {
            cluster_index2 = 1;
            centroids_index2 = 1;
            show_colour2 = true;
            show_centroids2 = true;
            show_text2 = true;
            text2 = "The steps of allocating datapoints and moving the centroids are repeated until convergence";
        } else if (stepslider === clusters().centroids.length+4) {
            show_text2 = true;
            text2 = "The centroids no longer move and the algorithm has converged";
        } 
        // else {
        //     cluster_index2 = 0;
        //     centroids_index2 = 0;
        //     show_colour2 = true;
        //     show_centroids2 = true;
        //     show_text2 = false;
        //     text2 = "";

        // }
        setShowColor(show_colour2)
        setCentroids(show_centroids2)
        setText(text2)

         //texture settings
         const circleGroup = select(circleRef.current);
         const circleGroup2 = select(circleRef2.current);
 
         const textGroup = select(textRef.current);
         // setClustering(clusters(points()));
 
 
 
 
         circleGroup
             .attr("stroke-width", 1.5)
             .selectAll('.scatter-circle')
             .data(props.data)
             .join('circle')
                .transition()
                .duration(50)
                .delay((_, i) => i * 5)
             .attr('class', 'scatter-circle')
             .attr('cx', d => xScale(d.x))
             .attr('cy', d => yScale(d.y))
             // .attr("transform", d => `translate(${xScale(d.x)},${yScale(d.y)})`)
             .attr('fill', 'pink')
             .attr("stroke", (d, i) => show_colour ? colours[clusters(d)["clusters"][cluster_index2][i]] : "violet")
             .attr("r", 3);
 
 
         if (show_centroids2) {
             circleGroup2
                 .selectAll('.scatter-circle2')
                 .remove();
 
             circleGroup2
                 .attr("stroke-width", 1.5)
                 .selectAll('.scatter-circle2')
                 .data(clusters(props.data)["centroids"][centroids_index2])
                 .join("circle")
                 .attr('class', 'scatter-circle2')
                 .attr('cx', d => xScale(d.x))
                 .attr('cy', d => yScale(d.y))
                 // .attr("transform", d => `translate(${xValue(d)},${yValue(d)})`)
                 .attr("fill", (d, i) => show_colour ? colours[i] : 'pink')
                 .attr("stroke", "black")
                 .attr("r", 8);
         } else {
            circleGroup2
            .selectAll('.scatter-circle2')
            .remove();
         }
 
         if (show_text2) {
             textGroup
                 .selectAll('*')
                 .remove();
 
             textGroup
                 .append("text")
                 .attr("x", props.width / 3)
                 .attr("y", props.height - 16)
                 .attr("text-anchor", "middle")
                 .text(text);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, stepslider, activeStep, show_colour, show_centroids, handleChangeStep])




   









    const colours = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"]

    const dist = (a, b) => {
        return (a['x'] - b['x']) * (a['x'] - b['x']) + (a['y'] - b['y']) * (a['y'] - b['y']);
    }
    const points = props.data;

    const centroid_means = (l) => {
        var sums = [];
        var counts = [];
        var num_centroids = 0;
        for (var i = 0; i < l.length; i++) {
            if (l[i] > num_centroids - 1) {
                num_centroids = l[i] + 1;
            }
        }

        for (var f = 0; f < num_centroids; f++) {
            sums.push({ 'x': 0.0, 'y': 0.0 });
            counts.push(0);
        }

        for (var g = 0; g < points.length; g++) {
            sums[l[g]]['x'] += points[g]['x'];
            sums[l[g]]['y'] += points[g]['y'];
            counts[l[g]]++;
        }

        var c = [];
        for (var z = 0; z < num_centroids; z++) {
            c.push({ 'x': sums[z]['x'] / counts[z], 'y': sums[z]['y'] / counts[z] });
        }

        return c;
    }


    const closest = (p, l) => {
        var best = 0;
        var best_dist = dist(p, l[0]);

        for (var i = 1; i < l.length; i++) {
            if (best_dist > dist(p, l[i])) {
                best = i;
                best_dist = dist(p, l[i]);
            }
        }

        return best;
    }






    //const handleChange = val => setValue(val);
    

    const clusters = () => {
        var clusters_object = {};
        clusters_object['centroids'] = [];
        clusters_object['clusters'] = [];

        var initial_centroids = [];

        for (var w = 0; w < num_centroids; w++) {
            initial_centroids.push({ 'x': points[w]['x'], 'y': points[w]['y'] });
        }
        clusters_object['centroids'].push(initial_centroids);

        var initial_clusters = [];
        for (var k = 0; k < points.length; k++) {
            initial_clusters.push(closest(points[k], clusters_object['centroids'][0]));
        }
        clusters_object['clusters'].push(initial_clusters);

        for (var i = 1; i < 22; i++) {
            let new_centroids = centroid_means(clusters_object['clusters'][i - 1]);
            let converged = true;
            for (var j = 0; j < num_centroids; j++) {
                if (isNaN(new_centroids[j].x)) {
                    new_centroids[j] = clusters_object['centroids'][i - 1][j];
                }
                if (new_centroids[j].x !== clusters_object['centroids'][i - 1][j].x
                    || new_centroids[j].y !== clusters_object['centroids'][i - 1][j].y) {
                    converged = false;
                }
            }

            if (converged) {
                break;
            }
            clusters_object['centroids'].push(new_centroids);
            clusters_object['clusters'].push(points.map( x => closest(x, clusters_object['centroids'][i])));
        }
        return clusters_object;
    }

 //////////////////////////////////////////////////////////////


//   const isStepOptional = (step) => {
//     return step === 1;
//   };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const handleReset = () => {
    setActiveStep(0);
  };

 //////////////////////////////////////////////////////////////

    return (

        <>
        
            <>                
<Container style={{
                        position: 'relative', left: '10%', top: '1%',right: '10%'
                    }}>
                 <Box sx={{ maxWidth: 900, justifyContent: 'center' }}>
                 <MainStyle>
                <Stepper activeStep={(activeStep<= 6 || activeStep=== clusters().centroids.length+3) ? activeStep : 6} alternativeLabel>
                    {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                  
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{(activeStep >= index)  ? label : ''}</StepLabel>
                        </Step>
                    );
                    })}
                    
                </Stepper>
                {activeStep === clusters().centroids.length+3 ? (
                    <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep >= 6 && activeStep <= clusters().centroids.length+4 && (
                       
                <Container style={{
                        position: 'absolute', left: '29%', top:'-10%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                       <MobileStepper
                        variant="dots"
                        steps={clusters().centroids.length-3}
                        position="static"
                        activeStep={activeStep-6}
                        sx={{ maxWidth: 400, flexGrow: 1 }}
                      />
                      </Container>
                        )}

                        <Button onClick={handleNext}>
                        {activeStep === clusters().centroids.length+4 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                    </React.Fragment>
                )}
                 </MainStyle>
                </Box> 
               </Container>
            </>
            <MainStyle>
                        <svg viewBox={"-400 20 1500 550"}>
                            <Group
                                x={props.width/4}
                                y={props.height/4} f 
                                right={margin.right}
                                top={margin.top}>
                                <Axes
                                    xScale={xScale}
                                    yScale={yScale}
                                    innerHeight={innerHeight}
                                    xTickPadding={15}
                                    yTickPadding={10}
                                    //yTickSize={-innerWidth}
                                    yAxisTickFormat={""}
                                    xAxisTickFormat={""}
                                />
                                <AxisLabel2
                                    innerHeight={innerHeight}
                                    innerWidth={innerWidth}
                                    axisPadding={60}
                                    yLabel={""}
                                    xLabel={""}
                                    marginLeft={margin.left}
                                />
                                <g ref={circleRef} />
                                <GroupedCircle ref={circleRef2} />

                            </Group>
                          
                        </svg>
                        </MainStyle>
        </>
        
    );
}



export default ScatterPlot5;





