import React from 'react';

import { Routes } from './components/Layout/routes/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';

function App() {

    
    

    return (

        
        <Router>

            <div>

            <Routes 
            />
            </div>
            </Router>

       
    );
}

export default App;
