import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import {
  Box, Grid,
} from '@material-ui/core';
import { Colorscale } from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';
import Paper from '@mui/material/Paper';
import BubbleMap2 from './BubbleMap2'
import ChoroPleth from './ChoroPleth';
import Distortion from './DistortionMap/Distortion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';


const drawerWidth = 380;



// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));






export default function SideBar7(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();



  const viridisColorscale = ['#fafa6e', '#9cdf7c', '#4abd8c', '#00968e', '#106e7c', '#2a4858'];



  const [colorscale, setColor] = useState(viridisColorscale);

  const onChange = colorscale => {

    setColor(colorscale);

  }



  const theme = useTheme();


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  useEffect(() => {




  }, [])


  const [BrushReset, setBrushReset] = useState(false);

  const [value, setValue] = useState('choro');
  const handleChange = val => setValue(val);
  const handleChangeChoro = val => setValueChoro(val);
  const [VarIndex, setValueChoro] = useState(2);
  const [CurrentTitle, setCurrentTitle] = useState(2);

  const VariablesMun =
    ['Residents in 2019',
      'Change in Inhabitants in %, 2010-2019',
      'Inhabitants per km2,2019',
      '% Foreigners, 2019',
      'Age between 0-19 years in %, 2019',
      'Age between 20-64 years in %, 2019',
      'Age >65 years in %, 2019',
      'Marriage Rate (change in %), 2019',
      'Divorce Rate (change in %),2019',
      'Birth Rate (change in %),2019',

    ];

  const VariablesCan =
    ['Inhabitants in 2019 div by 1000',
      'Change in Inhabitants in % in 2019',
      'Inhabitants per km2 in 2019',
      '% Foreigners in 2019',
      'Main language is German in % in 2019',
      '% SVP in the NR in % in 2019',
      'commit crimes ‰ for total Swiss population in 2019 ',
      '% finished higher education in 2019'
    ];

  const [showColorscalePicker, setColPicker] = useState(false);
  const toggleColorscalePicker = () => {
    setColPicker(!showColorscalePicker);
  }

  const [distortionValue, setDistortionValue] = React.useState(2);

  const handleSliderChange = (event, newValue) => {
    setDistortionValue(newValue);
  };


  return (




    <Box sx={{ display: 'flex', align: 'center' }}>
      <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h3" noWrap sx={{ flexGrow: 1 }} component="div">
            {props.title}
            <br></br>
            {/* { <Title>
             {CurrentTitle}
            </Title>} */}
          </Typography>


          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              '& svg': {
                color: "inherit",
                transition: '0.2s',
                transform: 'translateX(0) rotate(0)',
              },
              '&:hover, &:focus': {
                bgcolor: 'unset',
                '& svg:first-of-type': {
                  transform: 'translateX(-4px) rotate(-20deg)',
                },
                '& svg:last-of-type': {
                  right: 0,
                  opacity: 1,
                },
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                height: '80%',
                display: 'block',
                left: 0,
                width: '1px',
                bgcolor: 'divider',
              },
              ...(open && { display: 'none' })
            }}
          >
            <Settings />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />


        {value === 'bubble' ? <BubbleMap2
          VarIndex={VarIndex}
          colorscale={colorscale}
        /> : ''}
        {value === 'choro' ?
          <ChoroPleth
            VarIndex={VarIndex}
            colorscale={colorscale}
          /> : ''}
        {value === 'distortion' ?
          <Distortion
            VarIndex={VarIndex}
            colorscale={colorscale}
            distortionValue={distortionValue}
          /> : ''}


      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Card sx={{ minWidth: drawerWidth }}>
          <CardContent>
            <Box >

              {(value === 'bubble') ?

                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label">Data Cantons</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={VarIndex}
                    onChange={e => handleChangeChoro(e.target.value)}
                  >
                    {VariablesCan.map((KeyName, index) => (

                      <MenuItem key={index} value={index}>{KeyName}</MenuItem>

                    ))}
                  </Select>
                </FormControl> : ''}

              {(value === 'choro') ?

                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label">Data Municipalities</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={VarIndex}
                    onChange={e => { handleChangeChoro(e.target.value); console.log(VarIndex) }}
                  >
                    {VariablesMun.map((KeyName, index) => (

                      <MenuItem key={index} value={index}>{KeyName}</MenuItem>

                    ))}
                  </Select>
                </FormControl> : ''}

              {(value === 'distortion') ?
                <>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-filled-label">Data Cantons</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={VarIndex}
                      onChange={e => handleChangeChoro(e.target.value)}
                    >
                      {VariablesCan.map((KeyName, index) => (

                        <MenuItem key={index} value={index}>{KeyName}</MenuItem>

                      ))}
                    </Select>
                  </FormControl>
                  <Divider />
                  <Card sx={{ minWidth: drawerWidth }}>
                    <CardContent>

                      <Box >
                        <br></br>
                        Distortion Strength

                        <Slider
                          min={0.1}
                          step={1}
                          max={10}
                          valueLabelDisplay="auto"
                          value={distortionValue}
                          onChange={handleSliderChange}
                          color='primary'
                          aria-labelledby="continuous-slider" />
                      </Box>

                    </CardContent>
                  </Card>
                </>
                : ''}



            </Box>




          </CardContent>
        </Card>

        <Divider />
        <Card sx={{ minWidth: drawerWidth }}>
          <CardContent>

            <Box >
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">Map Style</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={value}
                  onChange={e => handleChange(e.target.value)}
                >
                  <MenuItem value={"bubble"}>BubbleMap</MenuItem>
                  <MenuItem value={"choro"}>Choropleth</MenuItem>
                  <MenuItem value={"distortion"}>DistortionMap</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </CardContent>
        </Card>

        <Divider />

        <Card sx={{ minWidth: drawerWidth }}>
          <CardContent>
            <Box >

              <div
                onClick={toggleColorscalePicker}
                className='toggleButton'
              >
                <Colorscale
                  colorscale={colorscale}
                  onClick={() => { }}
                  width={120}
                />
                Toggle Colorscale Picker
              </div>

              {/* // Show the colorscale picker */}
              {showColorscalePicker &&
                <ColorscalePicker
                  onChange={onChange}
                  colorscale={colorscale}
                  width={120}
                />
              }








            </Box>

            <Box >




            </Box>



          </CardContent>
        </Card>
      </Drawer>
    </Box>






  );
}
