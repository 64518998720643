import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@material-ui/core/Divider';

import ScatterPlot3 from './ScatterPlot3';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import {
    Box,
    Switch,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core';

const drawerWidth = 240;




// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));





export default function SideBar3({
    data,
    data2,
    keys,
    keys2,
    keys3,
    keys4,
    title,
    dataset,
    subtitle,
    setdataset,
}) {

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(3),
            minWidth: 150,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    const theme = useTheme();


    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [state, setState] = React.useState({
        sizeSwitch: false,
        colorSwitch: true,
        brightnessSwitch: false,
        orientationSwitch: false,
        markSwitch: false,
        textureSwitch: false,
        motionSwitch: false,
        logSwitchX: false,
        logSwitchY: false,
        saturationSwitch: false,

    });





    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleChangeOrient = (event) => {
        state.orientationSwitch = false;
        state.markSwitch = false;
        setState({ ...state, [event.target.name]: event.target.checked });
    };





    const [menutitle, setMenuTitle] = React.useState("");

    const [menutitle2, setMenuTitle2] = React.useState("");

    const [menutitle3, setMenuTitle3] = React.useState("");

    const [menutitle4, setMenuTitle4] = React.useState("species");

    const [menutitle5, setMenuTitle5] = React.useState("species");

    const [menutitle6, setMenuTitle6] = React.useState("species");

    const [menutitle7, setMenuTitle7] = React.useState("species");

    const [menutitle8, setMenuTitle8] = React.useState("species");

    const [menutitle9, setMenuTitle9] = React.useState("species");


    useEffect(() => {

        setMenuTitle(keys2[0]);
        setMenuTitle2(keys2[1]);
        setMenuTitle3(keys[0]);
        setMenuTitle4(keys3[0]);
        setMenuTitle5(keys[4]);
        setMenuTitle6(keys3[0]);
        setMenuTitle7(keys[3]);
        setMenuTitle8(keys3[0]);
        setMenuTitle9(keys[4]);


    }, [keys])



    const handleChangeData = (event) => {
        setdataset(event.target.value);
    };


    return (




        <Box sx={{ display: 'flex', align: 'center' }}>
            <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h3" noWrap sx={{ flexGrow: 1 }} component="div">
                        {title}
                    </Typography>

                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{
                            '& svg': {
                                color: "inherit",
                                transition: '0.2s',
                                transform: 'translateX(0) rotate(0)',
                            },
                            '&:hover, &:focus': {
                                bgcolor: 'unset',
                                '& svg:first-of-type': {
                                    transform: 'translateX(-4px) rotate(-20deg)',
                                },
                                '& svg:last-of-type': {
                                    right: 0,
                                    opacity: 1,
                                },
                            },
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                height: '80%',
                                display: 'block',
                                left: 0,
                                width: '1px',
                                bgcolor: 'divider',
                            },
                            ...(open && { display: 'none' })
                        }}
                    >
                        <Settings />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Main open={open}>
                <DrawerHeader />
                <ScatterPlot3
                    width={700}
                    height={400}
                    legendValue={300}
                    data={data2}
                    dataset={dataset}
                    buttonValues={state}
                    xButton={menutitle}
                    yButton={menutitle2}
                    sizeButton={menutitle3}
                    colorButton={menutitle4}
                    opaButton={menutitle5}
                    markButton={menutitle6}
                    oriButton={menutitle7}
                    tooltipValue={170}
                    hueBut={menutitle4}
                    saturationButton={menutitle8}
                    opacityBut={menutitle5}



                />
            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Card sx={{ minWidth: drawerWidth }}>
                </Card>

                <>
                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >Global Settings</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>


                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-filled-label">Change Whole Dataset</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={dataset}
                                                onChange={handleChangeData}
                                            >
                                                {keys4.map((KeyName, index) => (

                                                    <MenuItem key={index} value={KeyName}> {KeyName}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>




                                    </>
                                </div>

                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >Plot Variables</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>

                                <div >

                                    <>


                                        <Box >
                                            < >X Parameter</>
                                        </Box>
                                        <Box >

                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">X Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle}
                                                    onChange={e => { setMenuTitle(e.target.value); }}
                                                >
                                                    {keys2.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>


                                        </Box>

                                        <Box >
                                            <Switch
                                                color="secondary"
                                                checked={state.logSwitchX}
                                                onChange={handleChangeOrient}
                                                name="logSwitchX"
                                            />
                                            < >Log Scale</>
                                        </Box>



                                        <Box >
                                            < >Y Parameter</>
                                        </Box>

                                        <Box >
                                            {/* <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                        {/*   {data.map(chaining => Object.keys(chaining).map(chainOp =>
                                    <div>
                                        <MenuItem onClick={handleClose}>{chainOp}</MenuItem>
                                    </div>
                                ))} 
                            </Menu> 
                            */}
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Y Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle2}
                                                    onChange={e => { setMenuTitle2(e.target.value); }}
                                                >
                                                    {keys2.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Box>

                                        <Box >
                                            <Switch
                                                color="secondary"
                                                checked={state.logSwitchY}
                                                onChange={handleChangeOrient}
                                                name="logSwitchY"
                                            />
                                            < >Log Scale</>
                                        </Box>
                                    </>
                                </div>

                                {/* <div className={classes.panelRoot}>
                            <GlobalSettingsOptions />
                        </div>*/}
                            </AccordionDetails>
                        </Accordion>



                        {/*  */}



                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    < >HSL Color Settings</>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>
                                <div>
                                    <>
                                        <Box >
                                            <Switch
                                                color="secondary"
                                                checked={state.colorSwitch}
                                                onChange={handleChange}
                                                name="colorSwitch"
                                            />

                                            < >Hue Color</>

                                        </Box>
                                        {((dataset == 'CreditCard')|| (dataset == 'Sales')) ?
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Hue Colormap</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle4}
                                                    onChange={e => { setMenuTitle4(e.target.value) }}
                                                >
                                                    {keys.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>

                                            :

                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Hue Colormap</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle4}
                                                    onChange={e => { setMenuTitle4(e.target.value) }}
                                                >
                                                    {keys3.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>

                                        }

                                        <Box >

                                            <Switch
                                                color="secondary"
                                                checked={state.saturationSwitch}
                                                onChange={handleChange}
                                                name="saturationSwitch"
                                            />
                                            < >Saturation</>
                                        </Box>

                                        <Box>
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Saturation Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle8}
                                                    onChange={e => { setMenuTitle8(e.target.value); }}
                                                >
                                                    {keys.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box >
                                            <Switch
                                                color="secondary"
                                                checked={state.brightnessSwitch}
                                                onChange={handleChange}
                                                name="brightnessSwitch"
                                            />
                                            < >Brightness</>
                                        </Box>
                                        <Box >
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Brightness Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle5}
                                                    onChange={e => { setMenuTitle5(e.target.value) }}
                                                >
                                                    {keys.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </>


                                </div>

                            </AccordionDetails>
                        </Accordion>


                        {/*  */}

                        {/* <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions3-content"
                                    id="additional-actions4-header"
                                >
                                    <Box >
                                        <Switch
                                            color="secondary"
                                            checked={state.colorSwitch}
                                            onChange={handleChange}
                                            name="colorSwitch"
                                        />
                                        < >Hue</>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-filled-label">Hue Colormap</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={menutitle4}
                                            onChange={e => { setMenuTitle4(e.target.value) }}
                                        >
                                            {keys3.map((KeyName, index) => (

                                                <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                            ))}
                                        </Select>
                                    </FormControl>

                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions3-content"
                                    id="additional-actions4-header"
                                >
                                    <Box >
                                        <Switch
                                            color="secondary"
                                            checked={state.saturationSwitch}
                                            onChange={handleChange}
                                            name="saturationSwitch"
                                        />
                                        < >Saturation</>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div >

                                        <>
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Saturation Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle8}
                                                    onChange={e => { setMenuTitle8(e.target.value); }}
                                                >
                                                    {keys.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>

                                            {/* // Brightness Hue Saturation HCl  */}

                        {/* </>
                                    </div>
                                </AccordionDetails>
                            </Accordion>  */}

                        {/* <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions3-content"
                                    id="additional-actions4-header"
                                >
                                    <Box >
                                        <Switch
                                            color="secondary"
                                            checked={state.brightnessSwitch}
                                            onChange={handleChange}
                                            name="brightnessSwitch"
                                        />
                                        < >Brightness</>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-filled-label">Brightness Parameter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={menutitle5}
                                            onChange={e => { setMenuTitle5(e.target.value) }}
                                        >
                                            {keys.map((KeyName, index) => (

                                                <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                            ))}
                                        </Select>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion> */}

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions1-header"
                            >

                                <Box >
                                    <Switch
                                        color="secondary"
                                        checked={state.sizeSwitch}
                                        onChange={handleChange}
                                        name="sizeSwitch"
                                    />
                                    < >Size</>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Box >

                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-filled-label">Size Parameter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={menutitle3}
                                            onChange={e => { setMenuTitle3(e.target.value) }}
                                        >
                                            {keys.map((KeyName, index) => (

                                                <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                            ))}
                                        </Select>
                                    </FormControl>


                                </Box>

                            </AccordionDetails>
                        </Accordion>

                        {((dataset == 'CreditCard')|| (dataset == 'Sales')) ? "" :
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions3-content"
                                    id="additional-actions4-header"
                                >
                                    <Box >
                                        <Switch
                                            color="secondary"
                                            checked={state.markSwitch}
                                            onChange={handleChangeOrient}
                                            name="markSwitch"
                                        />
                                        < >Mark</>
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <div >

                                        <>
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-filled-label">Mark Parameter</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={menutitle6}
                                                    onChange={e => { setMenuTitle6(e.target.value) }}
                                                >
                                                    {keys3.map((KeyName, index) => (

                                                        <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>



                                        </>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions3-content"
                                id="additional-actions4-header"
                            >
                                <Box >
                                    <Switch
                                        color="secondary"
                                        checked={state.orientationSwitch}
                                        onChange={handleChangeOrient}
                                        name="orientationSwitch"
                                    />
                                    < >Orientation</>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>

                                <div >

                                    <>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-filled-label">Orientation Parameter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={menutitle7}
                                                onChange={e => { setMenuTitle7(e.target.value) }}
                                            >
                                                {keys.map((KeyName, index) => (

                                                    <MenuItem key={index} value={KeyName} > {KeyName}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>



                                    </>
                                </div>
                            </AccordionDetails>
                        </Accordion>





                        {/* TEXTURE AND MOTION BELOW */}

                        {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions3-content"
                        id="additional-actions4-header"
                    >
                        <Box >
                            <Switch
                                color="secondary"
                                checked={state.motionSwitch}
                                onChange={handleChange}
                                name="motionSwitch"
                            />
                            < >Motion</>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div >

                    <>
                    <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-filled-label">Motion Parameter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={menutitle9}
                                    onChange={e => {setMenuTitle9(e.target.value); }}
                                >
                                    {keys.map( (KeyName, index) => (

                                    <MenuItem key = {index} value={KeyName} > {KeyName}</MenuItem>

                                    ))}        
                                    </Select>
                                </FormControl> 

                        

                    </>
                    </div>
                    </AccordionDetails>
                </Accordion> */}


                    </div>
                </>

            </Drawer>
        </Box>






    );
}
