import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import PrinCA from './PrinCA';
import irisData from './../../datasets/irisData_original.json';
import SideBar4 from './SideBar4';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import irisData_original from './../../datasets/irisData_original.json';
import credit from './../../datasets/credit.json';
import pingu from './../../datasets/pingu.json';
import * as jz  from 'jeezy'
import * as data2grid from 'data2grid'
import {extent} from 'd3'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
    align: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function FourthPage() { 
 

    
  
    const Title = styled.h2`
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;

  const SubTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 568px) {
    text-align: center;
  }
`;

const classes = useStyles();


const [data2, setData2] = useState([]);
const [keys2, setKeys2] = useState([]);
const [keys3, setKeys3] = useState([]);
const [keys4, setKeys4] = useState([]);
const [keys5, setKeys5] = useState([]);

const [groupData, setGroups] = useState([]);


const [dataset, setdataset] = React.useState('iris');

  const Datasets =
  {
    // 'CreditCard': credit,
    'iris': irisData_original,
    'penguin': pingu,
  };

  const [corr, setCorr] = useState([]);
  const [grid2, setGrid] = useState([]);

var strKey = null;
useEffect(() => {
    
    setData2(Datasets[dataset]);
    // setKeys2(Object.keys(irisData[0]));
    const allKeys = Object.keys(Datasets[dataset][0]);
    const NewArr = Object.values(Datasets[dataset][0]).filter(function(d) { return isNumber(d) });

    //numerical keys
    const numKeys = (Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][0][d])) return d  }));
    setKeys2((Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][0][d])) return d  })));
    //string keys
    setKeys3((Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  })));

    // var arrayVal = Object.entries(Datasets[dataset][0]).map(item => Object.keys(Datasets[dataset][0]).filter(function(d) { return d !==  })));
    // setKeys3((Object.keys(Datasets[dataset][0]).filter(function(d) { if (isNumber(Datasets[dataset][d])) return d  })));

    // string keys
    strKey = (Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  }));

    // Keys of Global
    setKeys4(Object.keys(Datasets));

    setKeys5(Object.keys(groupByAuto(Datasets[dataset], strKey[0])));

    setGroups([groupByAuto(Datasets[dataset], strKey[0])]);

   const correl = makeCorr(Datasets[dataset],numKeys);

   setCorr(correl);

   setGrid(data2grid.grid(correl));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dataset])

// grouping data
function groupByAuto(data, key){
  var groups={};
  for(var i in data){
      if(!groups.hasOwnProperty(data[i][key])) groups[data[i][key]]=[];
      groups[data[i][key]].push(data[i]);
  }
  return groups;
}


// check if number or string
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

function makeCorr(dataSet,keys) {
var data6 = [];
var cols2 = keys;

const samplelength = Object.values(Datasets[dataset].map(d=> d[0])).length;

for (var i = 0; i < samplelength; i++){
  var obj2 = {index: i};
  cols2.forEach(col => {
    obj2[col] = dataSet.map(d=> d[col])[i];
  });
  data6.push(obj2);
}
var corr2 = jz.arr.correlationMatrix(data6,cols2);
var extentVar = extent(corr2.map(function(d){ return d.correlation; }).filter(function(d){ return d !== 1; }));

return corr2;
}


  return (
      <div className='light-theme'> 
      <Layout
                title="Dataset Visualization"
              >
                 
                 <SideBar4
                 title={"Dimensionality Reduction"}
                 subtitle={"Principal Component Analysis"}
                 data2={data2}
                 keys={keys2}
                 keys2={keys3}
                 keys3={keys4}
                 keys4={keys5} // subcategories
                 keysNames={Object.keys(groupByAuto(data2, keys3[0]))} // subcategories
                 groupData={[groupByAuto(Datasets[dataset], (Object.keys(Datasets[dataset][0]).filter(function(d) { if (!isNumber(Datasets[dataset][0][d])) return d  }))[0])]}
                 setKeys4={setKeys5}
                 dataset={dataset}
                 setdataset={setdataset}
                 Datasets={Datasets}
                 corr={corr}
                 grid2={grid2}


                />
            </Layout>
            </div> 
  );
}
