import React, { useEffect, useState } from "react"
import * as d3 from "d3"
import * as topojson from "topojson-client";
import * as styles from "./BubbleMap.module.css"
// import Swiss from './GemeineTOPO.json';
import Kantone from './AllCan.json';
import { Minimize } from "@material-ui/icons";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

// data https://www.bfs.admin.ch/bfs/de/home/statistiken/regionalstatistik/regionale-portraets-kennzahlen/kantone.assetdetail.15864476.html

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MainStyle = styled.h2`
font-size: 2.3rem;
font-weight: 300;
margin-bottom: 1rem;
margin-top: 1rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;

const Title = styled.h5`
font-size: 2.3rem;
font-weight: 200;
margin-bottom: 1rem;
margin-left: 25rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;

function BubbleMap2({VarIndex, colorscale}){

  const chart2 = React.useRef(null);
  const chart3 = React.useRef(null);

  const groupRef = React.useRef(null);


  // const [VarIndex, setValue] = useState(1);
  // const handleChange = val => setValue(val);
  const classes = useStyles();
  const KeysK = Object.keys(Kantone[0]);

  const Variables = 
      ['Inhabitants in 2019 div by 1000', 
      'Change in Inhabitants in % in 2019',
    'Inhabitants per km2 in 2019',
    '% Foreigners in 2019',
    'Main language is German in % in 2019',
    '% SVP in the NR in % in 2019',
    'commit crimes ‰ for total Swiss population in 2019 ',
    '% finished higher education in 2019'
    ];


  const colors = [
    '#f2f0f7',
    '#dadaeb',
    '#bcbddc',
    '#9e9ac8',
    '#807dba',
    '#6a51a3',
    '#4a1486'
  ]




  const countyDataURL = 'https://unpkg.com/swiss-maps@4/2021/ch-combined.json';


  const width = 960;
  const height = 750;

  useEffect(() => {
    Promise.all([
      // d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson"),
      // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_gpsLocSurfer.csv")
      Kantone, d3.json(countyDataURL)
    ]).then(initialize => {

      const [educationData, topology] = initialize;
      // let data = initialize[0]
      // let dataGeo = initialize[1]

      
      const tooltipOffsetX = 20;
      const tooltipOffsetY = 73;

      // SET UP SVG
      const svg = d3.select(chart2.current);
        // .append("svg")
        // .attr("viewBox", [0, 0, width, height]);


        const handleZoom = svg => {
          svg.call(
            d3.zoom().on('zoom', ({ transform }) => {
              svg.attr('transform', transform);
            })
          );
        };
  
        handleZoom(svg);

      

      // SET UP TOOLTIP
      const tooltip = d3.select(chart3.current)
        .append('div')
        .attr('class', 'hidden');

       // --------------- //
      // RETRIEVE DATA //
      // --------------- //
       //Total Inhabitants in Switzerland
      const TInh = 8606033;

      const Keys = Object.keys(educationData[VarIndex]);
      const Names = Object.keys(educationData[0]);
      const Residents = educationData[VarIndex];
      //Value in Percentage
      const ResidentsVal = Object.values(educationData[VarIndex]);
      const ResValPer = ResidentsVal.forEach(e => e / TInh);

      
      // const Residents = educationData[1];
      const educationRange = d3.extent((ResidentsVal)); 



      // Map and projection
      const projection = d3.geoMercator().rotate([0, 0]).center([8.3, 46.8]).scale(10000).translate([975 / 2, 610 / 2]).precision(0.1);

      const path = d3.geoPath(projection);

    
 
      const g2 = svg.append("g");
      const g = svg.append("g");
      const g3 = svg.append("g");
      const g4 = svg.append("g");
      const g5 = svg.append("g");


      
      const color = d3.scaleQuantize()
      .domain(educationRange)
      .range(colorscale);


      // Add a scale for bubble size
      const valueExtent = d3.extent(ResidentsVal)
      const size = d3.scaleSqrt()
        .domain(valueExtent)  // What's in the data
        .range([5, 35])  // Size in pixel


        svg.selectAll('*').remove();

svg
.selectAll('.bubble')
        .data(topojson.feature(topology, topology.objects.cantons).features)
        .join('circle')
        .attr('class', 'bubble')
        .attr("transform", function (d) { return "translate(" + path.centroid(d) + ")"; })
        .transition()
        .duration(1000)
        .attr("r", function (d) { return size(Residents[Keys[d.id - 1]]); })
        .attr('fill', d => color(Residents[Keys[d.id - 1]]))
        .attr("fill-opacity", .6)
        .attr('stroke', d => color(Residents[Keys[d.id - 1]]))
        .attr("stroke-width", 3)
  
      ;


        svg
        .selectAll(".path")
         .data(topojson.feature(topology, topology.objects.cantons).features)
         .join('path')
        .attr('class', 'path')
         .attr("d", path)
         .attr("class", styles.featurecanton) 
          .style('fill', d => color(Residents[d.id]))
           .on("mouseover", (event, d) => {
                 tooltip.attr("class", styles.tooltip)
                       // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
                       .style("left", event.pageX + tooltipOffsetX + "px")
                       .style("top", event.pageY - tooltipOffsetY + "px");
                       // tooltipDisplay('Hello');
                 // tooltipDisplay(educationData.find(e => e.fips === d.id));
                 tooltipDisplay(Names[d.id-1],Variables[VarIndex],Residents[Names[d.id-1]],false);
                 // tooltipDisplay(d.id);
                 d3.select(event.currentTarget).style("fill", "#FE621D");
               })
             .on("mouseout", (e, d) => {
               // d3.select(e.currentTarget).style("fill", getColor(d));  
               d3.select(e.currentTarget).style("fill",  color(Residents[d.id]));
               tooltip.attr("class", styles.hidden);
             });
         ;
        
      
         svg.selectAll(".path")
     .data(topojson.feature(topology, topology.objects.lakes).features)
     .enter().append('path')
     .attr("d", path)
     .attr("class", styles.featurelake)
         ;
   
     
   
   
     svg.append("path")
         .datum(topojson.mesh(topology, topology.objects.cantons, function(a, b) { return a !== b; }))
     .attr("d", path) .attr("class", styles.boundary)
     .style("stroke-width", "1px");




// The scale you use for bubble size
const size5 = d3.scaleSqrt()
  .domain(valueExtent)  // What's in the data, let's say it is percentage
  .range([1, 100])  // Size in pixel

// Add legend: circles
const valuesToShow = [valueExtent[0]+1,(valueExtent[1]-valueExtent[0])/2,valueExtent[1]];
const xCircle = 230
const xLabel = 380
const yCircle = 330
svg
  .selectAll("legend")
  .data(valuesToShow)
  .join("circle")
    .attr("cx", xCircle)
    .attr("cy", d => height-80 - size(d))
    .attr("r", d => size(d))
    .style("fill", "none")
    .attr("stroke", "black")

// Add legend: segments
svg
  .selectAll("legend")
  .data(valuesToShow)
  .join("line")
    .attr('x1', d => xCircle + size(d))
    .attr('x2', xLabel)
    .attr('y1', d => height-80- size(d))
    .attr('y2', d => height-80 - size(d))
    .attr('stroke', 'black')
    .style('stroke-dasharray', ('2,2'))

// Add legend: labels
svg
  .selectAll("legend")
  .data(valuesToShow)
  .join("text")
    .attr('x', xLabel)
    .attr('y', d => height-80 - size(d))
    .text( d => d)
    .style("font-size", 10)
    .attr('alignment-baseline', 'middle')

      }
    );
    },[VarIndex, colorscale]);

    function tooltipDisplay(name, interest1, interest2, perc) {
      // const info = `${county.area_name}, ${county.state}\n${county.bachelorsOrHigher}%`;
      const info = perc ? (`${name}, ${interest1}\n${interest2}%`) : (`${name}, ${interest1}\n${interest2}`);
      // const info = county;
      d3.select(`.${styles.tooltip}`).text(info);
    }

 

  return (
    <>
      <MainStyle>
      <svg ref={chart2} className={styles.chart} viewBox={"0 0 1500 1500"}>
        {/* <h1 className={styles.MainStyle}>United States Educational Attainment</h1>
      <h2 className={styles.subMainStyle}>Percentage of adults age 25 and older with a bachelor's degree or higher (2010-2014)</h2> */}
      </svg>
      </MainStyle>
      <div ref={chart3} >

      </div>
    </>

  )
}

export default BubbleMap2