import styled from 'styled-components';

import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import BarChart2 from './BarChartP2';

import Grid from '@mui/material/Grid';


import ScatterPlot from './ScatterPlot';





export default function PlotsRight(props) {
    {


        const theme = useTheme();


const useStyles = makeStyles({
    list: {

    }
    
});

        const classes = useStyles();
//         const [stateDrawer, setDrawer] = useState(false);
        const [highlightedIndex, setHighlightedIndex] = useState([{}])



//         const toggleDrawer = (anchor, open) => (event) => {
//             if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//                 return;
//             }

//             setDrawer(!stateDrawer);
//         };

      

        const [state, setState] = React.useState({
            sizeSwitch: false,
            colorSwitch: true,
            brightnessSwitch: false,
            orientationSwitch: false,
            markSwitch: false,
            textureSwitch: false,
            motionSwitch: false,
            logSwitchX: false,
            logSwitchY: false,

        });

  
//         const [anchorEl10, setAnchorEl10] = React.useState(null);





//         useEffect(() => {

//             setMenuTitle10(props.keys[0]);

//         }, [props.keys2])


//         const [valueBin, setValueBin] = React.useState(30);

//         const handleChangeBin = (event, newValue) => {
//             setValueBin(newValue);
//         };




//         const handleChange = (event) => {
//             setState({ ...state, [event.target.name]: event.target.checked });
//         };

//         const handleChangeOrient = (event) => {
//             state.orientationSwitch = false;
//             state.markSwitch = false;
//             setState({ ...state, [event.target.name]: event.target.checked });
//         };





        const [menutitle, setMenuTitle] = React.useState("petalLength");

        const [menutitle2, setMenuTitle2] = React.useState("sepalWidth");

        const [menutitle3, setMenuTitle3] = React.useState("sepalLength");

        const [menutitle4, setMenuTitle4] = React.useState("species");

        const [menutitle5, setMenuTitle5] = React.useState("species");

        const [menutitle6, setMenuTitle6] = React.useState(props.keys[0]);

        const [menutitle7, setMenuTitle7] = React.useState(props.keys[0]);

        const [menutitle8, setMenuTitle8] = React.useState(props.keys[0]);

        const [menutitle9, setMenuTitle9] = React.useState(props.keys[0]);

        const [menutitle10, setMenuTitle10] = React.useState();

        useEffect(() => {
            setMenuTitle(props.keys[0]);
            setMenuTitle2(props.keys[1]);
            setMenuTitle4(props.keys4[0]);
          }, [props.dataset, props.setdataset, props.keys4])


        // useEffect(() => {
        //     props.setCell(highlightedIndex);
          
        //   }, [highlightedIndex])


//         const handleChangeMode = (event) => {
    
//             setMenuTitle(event.target.value);
//           };

//           const handleChangeMode2 = (event) => {
          
//             setMenuTitle2(event.target.value);
//           };

//           const handleChangeMode3 = (event) => {
       
//             setMenuTitle3(event.target.value);
//           };

//           const handleChangeMode4 = (event) => {
         
//             setMenuTitle4(event.target.value);
//           };

//           const handleChangeMode5 = (event) => {
    
//             setMenuTitle5(event.target.value);
//           };

//           const handleChangeMode6 = (event) => {
            
//             setMenuTitle6(event.target.value);
//           };

//           const handleChangeMode7 = (event) => {
            
//             setMenuTitle7(event.target.value);
//           };

//           const handleChangeMode8 = (event) => {
          
//             setMenuTitle8(event.target.value);
//           };

//           const handleChangeMode9 = (event) => {
//             setMenuTitle9(event.target.value);
//           };

//           const handleChangeMode10 = (event) => {
//             setMenuTitle10(event.target.value);
//           };
//         const list = (anchor) => (

//             <div
//                 className={clsx(classes.list, {
//                     [classes.fullList]: anchor === 'top' || anchor === 'bottom',
//                 })}
//                 role="presentation"
//             // onClick={toggleDrawer(anchor, false)}
//             onKeyDown={toggleDrawer(anchor, false)}
//             >
            

//                             <>
//                                 <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label">X Parameter</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle}
//                                         label="XParameter"
//                                         onChange={handleChangeMode}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>

                            

//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.logSwitchX}
//                                         onChange={handleChangeOrient}
//                                         name="logSwitchX"
//                                     />
//                                     Log Scale
//                                 </Box>


//                                 <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label2">Y Parameter</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle2}
//                                         label="YParameter"
//                                         onChange={handleChangeMode2}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>
                           
                                    

                               

//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.logSwitchY}
//                                         onChange={handleChangeOrient}
//                                         name="logSwitchY"
//                                     />
//                                     Log Scale                                </Box>




                                
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.sizeSwitch}
//                                         onChange={handleChange}
//                                         name="sizeSwitch"
//                                     />
//                                     Size                              

//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Size Parameter</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle3}
//                                         label="YParameter"
//                                         onChange={handleChangeMode3}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


                               

//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.colorSwitch}
//                                         onChange={handleChange}
//                                         name="colorSwitch"
//                                     />
//                                     Color                                </Box>

//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Color</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle4}
//                                         label="YParameter"
//                                         onChange={handleChangeMode4}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.brightnessSwitch}
//                                         onChange={handleChange}
//                                         name="brightnessSwitch"
//                                     />
//                                     Brightness                                </Box>


//                                 <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Brightness</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle5}
//                                         label="YParameter"
//                                         onChange={handleChangeMode5}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>

//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.markSwitch}
//                                         onChange={handleChangeOrient}
//                                         name="markSwitch"
//                                     />
//                                     Mark                                </Box>

//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Mark</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle6}
//                                         label="YParameter"
//                                         onChange={handleChangeMode6}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.orientationSwitch}
//                                         onChange={handleChangeOrient}
//                                         name="orientationSwitch"
//                                     />
//                                     Orientation                                </Box>


//                                 <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Orientation</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle7}
//                                         label="YParameter"
//                                         onChange={handleChangeMode7}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


//                                 <Box className={classes.summary}>
//                                     <Switch
//                                         color="secondary"
//                                         checked={state.textureSwitch}
//                                         onChange={handleChange}
//                                         name="textureSwitch"
//                                     />
//                                     Texture                                </Box>


//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Texture</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle8}
//                                         label="Texture"
//                                         onChange={handleChangeMode8}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


//                                     <Switch
//                                         color="secondary"
//                                         checked={state.motionSwitch}
//                                         onChange={handleChange}
//                                         name="motionSwitch"
//                                     />
//                                     Motion                                

//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Motion</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle9}
//                                         label="YParameter"
//                                         onChange={handleChangeMode9}
//                                     >
//                                         {props.keys.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>




//                             </>
                       


















//                 <Accordion>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-label="Expand"
//                         aria-controls="additional-actions3-content"
//                         id="additional-actions4-header"
//                     >
//                         <Box className={classes.summary}>

//                             <HeyTitle >Histogram</HeyTitle>
//                         </Box>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <div className={classes.panelRoot}>

//                             <>
//                                 <Box className={classes.summary}>

//                                     Change BinSize
//                                     <Slider
//                                         min={12}
//                                         step={1}
//                                         max={56}
//                                         valueLabelDisplay="auto"
//                                         value={valueBin}
//                                         onChange={handleChangeBin}
//                                         aria-labelledby="continuous-slider" />
//                                     Change Histogramdata

//                                     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
//                                     <InputLabel id="demo-simple-select-label3">Histogramdata</InputLabel>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         value={menutitle10}
//                                         label="HistoParameter"
//                                         onChange={handleChangeMode10}
//                                     >
//                                         {props.keys2.map((KeyName, index) => (
                                           
//                                                 <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>
                                            
//                                         ))}
//                                     </Select>
//                                 </FormControl>


//                                 </Box>



//                             </>
//                         </div>
//                     </AccordionDetails>
//                 </Accordion>



//             </div>
//         );

        return (
                <>

               {/* {console.log("highlight:",highlightedIndex)} */}

            


                    <Grid container>
                <Grid item sm={6} md={5}>

                            <ScatterPlot
                                width={750}
                                height={380}
                                legendValue={30}
                                data={props.data2}
                                buttonValues={state}
                                xButton={props.menutitle}
                                yButton={props.menutitle2}
                                sizeButton={menutitle3}
                                colorButton={menutitle4}
                                opaButton={menutitle5}
                                markButton={menutitle6}
                                oriButton={menutitle7}
                                tooltipValue={250}
                                keys={props.keys}
                                setHighlightedIndex={setHighlightedIndex}
                                highlightedIndex={highlightedIndex}

                            />

                          </Grid>
                          
                          <Grid item sm={6} md={6}>


                            <BarChart2
                                width={680}
                                height={380}
                                data2={props.data2}
                                keys={props.keys}
                                valueBin={props.valueBin}
                                menutitle={props.menutitle10}
                                setHighlightedIndex={setHighlightedIndex}
                                highlightedIndex={highlightedIndex}
                            />
                          </Grid>
                          </Grid>

                </>
                
        );



    };

}


