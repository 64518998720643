import styled from 'styled-components';
import PropsTypes from 'prop-types';
import React from 'react';


const Wrapper = styled.g`
  transform: ${props =>
    props.align === 'row'
      ? `translate(${props.spacing}px, 0)`
      : `translate(${props.spacing}px, 30px)`};
`;

const ColorTick = styled.g`
  transform: ${props =>
    props.align === 'row'
      ? `translate(${props.spacing}px, 230px)`
      : `translate(200px, ${props.spacing}px)`};

  text {
    font-size: 1.4rem;
  }
`;

const ColorCircle = styled.circle`
  fill: ${props => props.color};
`;

function ColorLegend({
  moveX,
  colorData,
  colorScale,
  spacing,
  radius,
  textX,
  width,
  align,
}) {
  return (
    <Wrapper spacing={width - moveX+100} align={align}>
      {colorScale.domain().map((d, i) => (
        <ColorTick spacing={i * spacing} align={align} key={i}>
              <ColorCircle r={radius} color={colorScale(d)} />
          <text dy="0.32em" x={textX}>
            {d}

          </text>
        </ColorTick>
      ))}
      </Wrapper>


  );
}


export default ColorLegend;
