import React, { useState, useCallback, useRef, useEffect } from 'react';

import styled from 'styled-components';
import * as d3 from "d3";
import Parcoords from "parcoord-es"
import "parcoord-es/dist/parcoords.css"

import { makeStyles } from '@material-ui/core/styles';
import 'parcoord-es/dist/parcoords.css';

import MuiInput from '@mui/material/Input';




const Input = styled(MuiInput)`
  width: 42px;
`;

const Input2 = styled('input')({
  display: 'none',
});
//////
//
//TODO
/////
//> Yes/No brushPredicate
//> select one curve
//> Selected points are colored dark red, and the subspace containing them is shown in grey

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export function ParallelCoordinate({
data2,
dataset,
keys,
keys2,
Datasets,
handleChangeInDataSet,
// bundleDimension ,
// setbundleDimension,
colVar,
setcolVar,
setData2,
bundlingStrength,
BrushMode,
BrushReset,
setbundling,
bundling,
personName,
filtered,
filterAxes,
filterData,
}) {
 

  const classes = useStyles();

  const Title = styled.h3`
    font-size: 2.3rem;
    font-weight: 800;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
    style={{ display: "block" }};
  
    @media (max-width: 568px) {
      text-align: center;
    }
  `;

  const SubTitle = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  style={{ display: "block" }};

  @media (max-width: 568px) {
    text-align: center;
  }
`;



  const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

  const myCol = d => d[colVar];

  var blue_to_brown = d3.scaleLinear()
    .domain(d3.extent(data2, myCol))
    .range(["steelblue", "brown"])
    .interpolate(d3.interpolateLab);

  var colorSet = d => {
    return (typeof d[colVar] === 'number' ? (blue_to_brown(d[colVar])) : (colorScale(d[colVar])));
  };


  ///////////////////////
  const chartRef = useRef()
  useEffect(() => {
    if (chartRef !== null) {

      // SET UP SVG
      // const theDraw = d3.select(chartRef.current);
      const Axes = (Object.keys(Datasets[dataset][0])); 


      const theDraw = d3.select(chartRef.current);
      theDraw.selectAll('*').remove();

      var group = Parcoords()(chartRef.current)
        .alpha(0.4)
        ;

        const val = Object.keys(Datasets[dataset][0])[3];
        // setbundling(`"${val}"`);

      DrawMuVar(group);
   
   if (BrushReset == true )  {
    group.brushReset();
   }; 

    }
  }, [BrushMode, BrushReset, bundlingStrength, colVar, data2, bundling, filtered]);


  const ParData = data2;

  const DrawMuVar = (group) => {

    group
      // .data(Datasets[dataset])
      .data(filtered)
      .bundlingStrength(bundlingStrength) // set bundling strength
      // .bundleDimension(bundling)
      .smoothness(0.1)
      .color(colorSet)
      .margin({ top: 24, left: 20, bottom: 12, right: 80 })
      .render()
      .shadows()
      .brushMode(BrushMode)  // enable brushing
      .interactive()
      .reorderable();

  };



 
 

  const [uploadErrorMessage, setUploadErrorMessage] = useState(null)


const handleUpload = (event) => {
  event.preventDefault()
  const file = event.target.files[0]
  if (!file) return
  const reader = new FileReader()
  reader.onload = (event) => {
    try {
      const newData = JSON.parse(event.target.result)
      setData2(newData)
    } catch (exception) {
      setUploadErrorMessage('Unable to parse as JSON file')
      setTimeout(() => setUploadErrorMessage(null), 5000)
    }
  }
  reader.readAsText(file)
}

// const filtered = Object.keys(data2[0])
//   .filter(key => personName.includes(key))
//   .reduce((obj, key) => {
//     obj[key] = data2[key];
//     return obj;
//   }, {});





  return (
    <>
    {console.log("blabla",bundling,Object.keys(filtered[0])[0])}

        <div style={{ height: window.innerHeight / 1.2, width: window.innerWidth/1.2, align: 'left' }} ref={chartRef} className={"parcoords"} d={DrawMuVar}/>



    </>
  );
}
