import styled, { createGlobalStyle } from 'styled-components';
import PropsTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Header from './Header';
//import SideBar from './SideBar';
import Footer from './Footer';
import Header2 from './Header2';


const GlobalStyle = createGlobalStyle`
  html {
    ${'' /* 10px = 1rem */}
    font-size: 82.5%; 

    @media (max-width: 992px) {
      ${'' /* 9px = 1rem */}
      font-size: 56.25%; 
    }

    @media (max-width: 768px) {
      ${'' /* 8px = 1rem */}
      font-size: 50%; 
    }

    @media (max-width: 320px) {
      ${'' /* 7px = 1rem */}
      font-size: 43.75%; 
    }

    scroll-behavior: smooth;
  }

  body {
    margin: 3;
    padding: 3;
    align: center;
    style={{ display: "block" }};

  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  p {
    font-size: 1.6rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align: center;
  style={{ display: "block" }};

  

  main {
    flex: 1;

  }
`;





function Layout({
  children,
  title,
}) {
  return (
    <Wrapper>
     

      <GlobalStyle />

      <Grid container spacing={0} alignItems="center">

     
{/* 
      <Header2
        /> */}
         <Header
        title={title}
      />

          <main>{children}</main>
   
    </Grid>
    </Wrapper>
  );
}

Layout.propTypes = {
  title: PropsTypes.string,
  subTitle: PropsTypes.string,
  chartType: PropsTypes.string,
  dataSource: PropsTypes.string,
  dataSourceUrl: PropsTypes.string,
  headTitle: PropsTypes.string,
};

export default Layout;
