import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
    scaleLinear,
    scaleLog,
    extent,
    select,
    scaleOrdinal,
    schemeCategory10,
    group,
} from 'd3';
import * as d3 from 'd3';
import PropsTypes from 'prop-types';
//import textures from 'textures';
import {
    Group, MapWrapper, Map,
    //,
    //Dropdown
} from '../../../style/style';
import AxisLabel2 from '../../../components/AxisLabel/AxisLabel2';
import Axes from '../../../components/Axes/Axes';
import ColorLegend from '../../../components/ColorLegend/ColorLegend';
import textures from 'textures';
//import PanelTopRight from '../../components/Boxes/surfaces/PanelTopRight';
//import Accordions from './BoxSettings/Accordions';
//import SideBar from '../../components/Layout/SideBar';
import styled from 'styled-components';
import * as styles from "./SP3.module.css"


const GroupedCircle = styled.g`
  circle {
    
    &:hover {
      fill: #e46aa7;
      stroke-width: 2px;
      stroke: #5a1354;
    }
  }

  line {
    fill: #00a9ec;

  }
  path {
    
    &:hover {
      fill: #e46aa7;
      stroke-width: 2px;
      stroke: #5a1354;
    }
  }



`;

const MainStyle = styled.h2`
font-size: 2.3rem;
font-weight: 600;
margin-bottom: 1rem;
margin-top: 1rem;
text-align: center;
justifyContent: center;

@media (max-width: 568px) {
  text-align: center;
}
`;

const Wrapper = styled.g`
  transform: ${props =>
    props.align === 'row'
      ? `translate(${props.spacing}px, 0)`
      : `translate(${props.spacing}px, 30px)`};
`;

const ColorTick = styled.g`
  transform: ${props =>
    props.align === 'row'
      ? `translate(${props.spacing}px, 230px)`
      : `translate(200px, ${props.spacing}px)`};

  text {
    font-size: 1.4rem;
  }
`;

const ColorCircle = styled.circle`
  fill: ${props => props.color};
`;


function ScatterPlot3({ 
    data, 
    buttonValues, 
    xButton, 
    yButton, 
    sizeButton, 
    colorButton, 
    opaButton,
    saturationButton, 
    markButton, 
    oriButton, 
    height, 
    dataset,
    width, 
    personName,
    legendValue,
 tooltipValue }) {
    //const [value, setValue] = useState('linear');

    const [selectedCircle, setSelectedCircle] = useState(null);
    const [position, setPosition] = useState({ xPosition: 0, yPosition: 0 });
    const circleRef = useRef();
    const legendRef = useRef();

    const chart4 = React.useRef(null);

    const margin = { top: 10, right: 80, bottom: 60, left: 10 };
    const innerWidth = width/1.1 - margin.right - margin.left;
    const innerHeight = height - margin.top - margin.bottom;

    //const circleRadius = 10;
    //const circleRadius = d => 3*(d.age);

    //const myTexture = d => (d.name);
    const myAngle = d => (d[oriButton]);
    const mySize = d =>  (d[sizeButton]);
    const xValue = d => d[xButton];
    const yValue = d => d[yButton];
    //const myAngle = d => 3 * (d.age);
    //const mySize = d => 3 * (d.name);
    //const xValue = d => d.age;
    //const yValue = d => d.height;
    //const colorValue = d => d.age;
    const colorValue = d => d[colorButton];
    const saturationValue = d => d[saturationButton];
    //const colorValue = d => d.height;

    const colorScale = scaleOrdinal();

    const nested = Array.from(group(data, colorValue), ([key, value]) => ({
        key,
        value,
    }));
    colorScale.domain(nested.map(d => d.key));

    const colorData = buttonValues.colorSwitch ? (d => colorScale(colorValue(d))) : (d => colorScale(colorScale(d[0])));


    //opacity settings
    const opacityValue = d => d[opaButton];
    //const opacityValue = d => d.age;

    // const nestedOpacity = Array.from(group(data, opacityValue), ([key, value]) => ({
    //     key,
    //     value,
    // }));
    // const opacityScale =
    //     scaleLinear()
    //     .domain(nestedOpacity.map(d => d.key))
    //     .range([0.2, 1]); 

    const myLightness = 1;

 
    
    const myScaleColorCat = d3.scaleOrdinal().domain(d3.range(3)).range([0, 120, 240]);

    const myScaleColorNum = d3.scaleLinear().domain(extent(data, colorValue)).range([0, 340]);

    const colorScaleHSL = (d) => {
        if (isNumber(d))
        { return myScaleColorNum(d)}
        else
        {
          return myScaleColorCat(d)
        }
    }

    const myColorz = d => buttonValues.colorSwitch ? colorScaleHSL(d[colorButton]): 40;
    
    const opacityScaleNum = scaleLinear()
    .domain(extent(data, opacityValue))
    .range([0.2, 1]); 

    const opacityScaleCat = scaleOrdinal()
    .domain(extent(data, opacityValue))
    .range([0.2, 0.5, 1]); 

    const opacityScale = (d) => {
        if (isNumber(d))
        { return opacityScaleNum(d)}
        else
        {
          return opacityScaleCat(d)
        }
    }

    const myOpacity = d => buttonValues.brightnessSwitch ? (opacityScale(d[opaButton])) : (1);


    const mySaturationNum = scaleLinear()
    .domain(extent(data, saturationValue))
    .range([0.2, 1]); 

    const mySaturationCat = scaleOrdinal()
    .domain(extent(data, saturationValue))
    .range([0.2, 0.5, 1]); 

    const saturationScale = (d) => {
        if (isNumber(d))
        { return mySaturationNum(d)}
        else
        {
          return mySaturationCat(d)
        }
    }
       
    const mySaturation =  d => (buttonValues.saturationSwitch ? (saturationScale(d[saturationButton])) : (1));


    // Numerical Values
    var myRadialSizeNum = scaleLinear()
        .domain(extent(data, mySize))
        .range([3, 15]);

    // Categorical Values
    var myRadialSizeCat = scaleOrdinal()
        .domain(extent(data, mySize))
        .range([3,5,15]);

    const myRadialSize = (d) => {
        if (isNumber(d))
        { return myRadialSizeNum(d)}
        else
        {
          return myRadialSizeCat(d)
        }
    }

    const circleRadius = d => (buttonValues.sizeSwitch ? ( myRadialSize(d[sizeButton])) : (10));

    // check if number or string
    function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    const myOrientNum = scaleLinear()
    .domain(extent(data, myAngle))
    .range([0, 270]);

    const myOrientCat = scaleOrdinal()
    .domain(extent(data, myAngle))
    .range([0, 135, 270]);


    var myOrientation = (d) =>{
        if (isNumber(d))
        { return myOrientNum(d)}
        else
        {
          return myOrientCat(d)
        }
    };


    //const xScale = scaleLinear()
    //    .domain(extent(data, xValue))
    //    .range([0, innerWidth])
    //    .nice();
    const xScale = buttonValues.logSwitchX ? scaleLog() : scaleLinear();
        
    xScale.domain([d3.min(data, d => Number(xValue(d))), d3.max(data, d => Number(xValue(d))) + 1])
        .range([0, innerWidth])
        .nice();
    
    const yScale = buttonValues.logSwitchY ? scaleLog() : scaleLinear();

    yScale.domain([d3.min(data, d => Number(yValue(d))), d3.max(data, d => Number(yValue(d))) + 1])
        .range([innerHeight, 0])
        .nice();

    //Custom Semi Circle, accurate center and size
    //var customShapeCircle = {
    //    draw: function (context, size) {
    //        let r = Math.sqrt(2 * size / Math.PI);
    //        let orgin = (4 * r) / (3 * Math.PI); //the orgin of the circle, not of the symbol
    //        context.arc(0, -orgin, r, Math.PI, 2 * Math.PI, true);
    //        context.closePath();
    //    }
    //}

    

    // // BRUSHING FEATURES 
    // var brush = d3.brush().extent([[0, 0], [width, height]]).on("end", brushended),
    // idleTimeout,
    // idleDelay = 350;

    // const brushended = (event) => {

    //     var s = event.selection;
    //     if (!s) {
    //         if (!idleTimeout) return idleTimeout = setTimeout(idled, idleDelay);
    //         xScale.domain(d3.extent(data, function (d) { return xValue(d); })).nice();
    //         yScale.domain(d3.extent(data, function (d) { return yValue(d); })).nice();
    //     } else {
            
    //         xScale.domain([s[0][0], s[1][0]].map(xScale.invert, xScale));
    //         yScale.domain([s[1][1], s[0][1]].map(yScale.invert, yScale));
    //         scatter.select(".brush").call(brush.move, null);
    //     }
    //     zoom();
    // }

    // function idled() {
    //     idleTimeout = null;
    // }

    // function zoom() {

    //     var t = scatter.transition().duration(750);
    //     svg.select("#axis--x").transition(t).call(xAxis);
    //     svg.select("#axis--y").transition(t).call(yAxis);
    //     scatter.selectAll("circle").transition(t)
    //     .attr("cx", function (d) { return xScale(d.x); })
    //     .attr("cy", function (d) { return yScale(d.y); });
    // }
    // /
    
    const axesTickFormat = useCallback(number => {
        return number;
    }, []);


   // const Radius = 125;

   const texture = textures
     .circles();

    var scaleIn = d3.scaleOrdinal()
                        .domain(d3.range(3))
						.range([50, 200, 300]);

//    const symbolGen = d => d3.symbol().type(d3.symbols[5]).size(scale(d[xButton]));
   const symbolGen = d3.symbol().type(d3.symbolSquare);

    //elegant way

//    const shape = d3.scaleOrdinal(d3.symbols.map((s,index) => symbolGen.type(s)()));

const markValue = d => d[markButton];
   
var symbOrdinal = d3.scaleOrdinal()
                        .domain(extent(data, markValue))
						.range([d3.symbols[1], d3.symbols[3], d3.symbols[5]]);


   const shapeGen = 
    // symbolGen.size(circleRadius(d)*10);
    d3.symbol().type(d => symbOrdinal(d[markButton])).size(d=> circleRadius(d)*15);
    // shape(d[markButton]);
   

//    const shape = d3.scaleOrdinal(d3.symbols.map((s) => s));
//    const symbolGen = d => d3.symbol().type(shape(d[markButton])).size(circleRadius(d));



    //solution way
   //var symbolTypes = ['symbolCircle', 'symbolCross', 'symbolDiamond', 'symbolSquare', 'symbolStar', 'symbolTriangle', 'symbolWye'];

   const l = textures.lines().size(4)
       .strokeWidth(1).background("violet");

   const c = textures.circles().size(4)
       .strokeWidth(1).background("violet");

   const v = textures.lines().size(4)
       .orientation("3/8", "7/8")
       .stroke("#000")
  .background("orange");

   const f = textures.lines()
       .orientation("vertical")
       .strokeWidth(1)
       .shapeRendering("crispEdges");

    var triangle = {
        draw: function(context, size) {
            context.moveTo(0,0)
          context.lineTo(size/8, 0);
          context.lineTo(size/8, size/3);
          context.closePath();
        }
      }

   

    

   // //const types = d3.symbols[(d3.symbols.length)];
   // const ofTexture = d3.scaleOrdinal()
   //     .domain(["0", "1", "2", "3"])
   //     .range([l.url(), c.url(), v.url(), f.url()])

   

    useEffect(() => {

        //texture settings
        const circleGroup = select(circleRef.current);
        const svg = select(legendRef.current);

        //circleGroup.call(l);
        //circleGroup.call(c);
        //circleGroup.call(v);
        //circleGroup.call(f);
        circleGroup.call(v);

        var config = {
            "avatar_size": 48
        }


        
       

        if (buttonValues.markSwitch) {
            circleGroup
                .selectAll('*')
                .remove();
            //circleGroup
            //    .selectAll('.scatter-circle')
            //    .data(data)
            //    .join('circle')
            //    .attr('class', 'scatter-circle')
            //    .attr('cy', innerHeight / 2)
            //    .attr('cx', innerWidth / 2)
            //    .attr('r', 0)
            //    .attr('d', customCircle.size(250))



            //circleGroup
            //    .selectAll('.myPoint')
            //    .data(data)
            //    .enter()
            //    .append('image')
            //    .attr("xlink:href", "https://img.icons8.com/color-glass/48/000000/music.png")
            //    .attr("x", innerWidth / 2)
            //    .attr("y", innerHeight / 2)
            //    .attr("width", 0)
            //    .attr("height", 0)
            //    .transition()
            //    .duration(2000)
            //    .delay((_, i) => i * 10)
            //    .attr("x", d => xScale(xValue(d)) - 13)
            //    .attr("y", d => yScale(yValue(d))-13)
            //    .attr("width", 26)
            //    .attr("height", 26);

            //add colors most likely not efficiently
            ////circleGroup
            ////    .selectAll('.myPoint')
            ////    .data(data)
            ////    .enter()
            ////    .append('image')
            ////    .attr("xlink:href", "https://img.icons8.com/color-glass/48/000000/music.png")
            ////    .style("fill", "blue")
            ////    .attr("x", innerWidth / 2)
            ////    .attr("y", innerHeight / 2)
            ////    .attr("width", 0)
            ////    .attr("height", 0)
            ////    .transition()
            ////    .duration(2000)
            ////    .delay((_, i) => i * 10)
            ////    .attr("x", d => xScale(xValue(d)) - 13)
            ////    .attr("y", d => yScale(yValue(d)) - 13)
            ////    .attr("width", 26)
            ////    .attr("height", 26)
            ////    .attr("transform", `rotate(${angle})')


            //.attr("transform", "rotate(90,"+ (d => xScale(xValue(d)) - 13) + ", " + ( d => yScale(yValue(d)) - 13)+")")
            //.attr("transform", 'rotate(${ 360 * (innerWidth / innerHeight) })translate(${ 200}, ${ 200})rotate(${-360 * (innerWidth / innerHeight)})')
            //.attr("transform", 'translate(${d => xScale(xValue(d)) - 13}, ${d => yScale(yValue(d)) - 13})')

            //.attr("transform", 'rotate(${angle / 10}, 0 0)')

            //.attr("transform", 'rotate(${ angle / 10}, ${ d => xScale(xValue(d)) - 13 } ${ d => yScale(yValue(d)) - 13 })`)

            circleGroup
                //.style("background", dark ? "#2e2e2e" : "white")
                .selectAll('path')
                .data(data)
                .join('path')
                // .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                // .transition()
                // .duration(2000)
                // .delay((_, i) => i * 10)
                .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d))})`)
                .attr("fill", d => d3.hsl(myColorz(d),mySaturation(d),0.7,myOpacity(d)))
                // .attr("opacity", myOpacity)

                // .attr("d", d => shape(d[markButton]))
                .attr("d", shapeGen)
                // .attr("d", d => symbolGen.size(myRadialSize(mySize(d))))

                // .on("start", function repeat() {
                //     if (buttonValues.motionSwitch) {
                //         d3.active(this)
                //             .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                //             .transition()
                //             .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d)) - 10})`)
                //             .transition()
                //             .on("start", repeat);
                //     }
                // })
                .on("mouseover", (event, d) => {
                    setSelectedCircle(d);
                    tooltip.attr("class", styles.tooltip)
                      // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
                      .style("left", event.pageX + tooltipOffsetX + "px")
                      .style("top", event.pageY - tooltipOffsetY + "px");
                    // tooltipDisplay('Hello');
                    // tooltipDisplay(educationData.find(e => e.fips === d.id));
                    tooltipDisplay(xButton,xValue(d),yButton,yValue(d),"Category:",colorValue(d));
                    // tooltipDisplay(d.id);
                  })
                  .on("mouseout", (e, d) => {
                    // d3.select(e.currentTarget).style("fill", getColor(d));  
                    tooltip.attr("class", styles.hidden);
                    setSelectedCircle(null);
                  })
                ;



            //d3.symbol().size(Radius).type(s)


            //circleGroup
            //    .selectAll('.myPoint')
            //    .data(data)
            //    .enter()
            //    .append('image')
            //    .exit()
            //    .transition()
            //    .delay(3000)
            //    .remove();
            //.enter()
            //.append('image')
            //.attr("xlink:href", "https://img.icons8.com/color-glass/48/000000/music.png")
            //.attr("fill", colorData)
            //.attr("x", d => xScale(xValue(d)) - 13)
            //.attr("y", d => yScale(yValue(d)) - 13)
            //.attr("width", 26)
            //.attr("height", 26)
            //.attr("transform", d => `rotate(${myOrientation(myAngle(d))},${xScale(xValue(d))},${yScale(yValue(d))})`)
            //.transition()
            //.duration(2000)
            //.delay((_, i) => i * 10)
            //.attr("x", innerWidth / 2)
            //.attr("y", innerHeight / 2)
            //.attr("width", 0)
            //.attr("height", 0)



        }
        else if (buttonValues.orientationSwitch) {
            circleGroup
                .selectAll('*')
                .remove();
            //circleGroup
            //    .selectAll('path')
            //    .data(data)
            //    .join('path')
            //    .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d))})`)
            //    .attr("fill", colorData)
            //    .attr("opacity", 0)
            //    .attr("d", d => symbolGen.size(myRadialSize(mySize(d))))
            //    .attr("d", d => shape(d.name));

            //circleGroup
            //    .selectAll('.scatter-circle')
            //    .data(data)
            //    .join('circle')
            //    .attr('class', 'scatter-circle')
            //    .attr('cy', innerHeight / 2)
            //    .attr('cx', innerWidth / 2)
            //    .attr('r', 0)
            //    .attr('d', customCircle.size(250))

            // circleGroup
            //     .selectAll('.myPoint')
            //     .data(data)
            //     .enter()
            //     .append('image')
            //     .attr("xlink:href", "https://img.icons8.com/color-glass/48/000000/music.png")
            //     // .attr("fill", colorData)
            //     .attr("x", innerWidth / 2)
            //     .attr("y", innerHeight / 2)
            //     // .attr("width", 0)
            //     // .attr("height", 0)
            //     // .transition()
            //     // .duration(2000)
            //     // .delay((_, i) => i * 10)
            //     .attr("x", d => xScale(xValue(d)) - 13)
            //     .attr("y", d => yScale(yValue(d)) - 13)
            //     .attr("width", d => circleRadius(d)*3)
            //     .attr("transform", d => `rotate(${myOrientation(myAngle(d))},${xScale(xValue(d))},${yScale(yValue(d))})`)
            //     .on("start", function repeat() {
            //         if (buttonValues.motionSwitch) {
            //             d3.active(this)
            //                 .attr("x", innerWidth / 2)
            //                 .attr("y", innerHeight / 2)
            //                 .transition()
            //                 .attr("x", d => xScale(xValue(d)) - 13)
            //                 .attr("y", d => yScale(yValue(d)) - 13)
            //                 .transition()
            //                 .on("start", repeat);
            //         }
            //     })

                circleGroup
                //.style("background", dark ? "#2e2e2e" : "white")
                .selectAll('path')
                .data(data)
                .join('path')
                // .transition()
                // .duration(2000)
                // .delay((_, i) => i * 10)
                .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                .attr("fill", d => d3.hsl(myColorz(d),mySaturation(d),0.7,myOpacity(d)))
                // .attr("opacity", myOpacity)

                // .attr("d", d => shape(d[markButton]))
                // .attr("transform", d => `rotate(${myOrientation(myAngle(d))},${xScale(xValue(d))},${yScale(yValue(d))})`)
                .attr("transform", d => `rotate(${myOrientation(myAngle(d))},${xScale(xValue(d))},${yScale(yValue(d))})translate(${xScale(xValue(d))},${yScale(yValue(d))})`)
                .attr("d", d3.symbol().type(triangle).size(d => circleRadius(d)*10))
                // .attr("d", d => symbolGen.size(myRadialSize(mySize(d))))
                .on("mouseover", (event, d) => {
                    setSelectedCircle(d);
                    tooltip.attr("class", styles.tooltip)
                      // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
                      .style("left", event.pageX + tooltipOffsetX + "px")
                      .style("top", event.pageY - tooltipOffsetY + "px");
                    // tooltipDisplay('Hello');
                    // tooltipDisplay(educationData.find(e => e.fips === d.id));
                    tooltipDisplay(xButton,xValue(d),yButton,yValue(d),"Category:",colorValue(d));
                    // tooltipDisplay(d.id);
                  })
                  .on("mouseout", (e, d) => {
                    // d3.select(e.currentTarget).style("fill", getColor(d));  
                    tooltip.attr("class", styles.hidden);
                    setSelectedCircle(null);
                  })
                // .on("start", function repeat() {
                //     if (buttonValues.motionSwitch) {
                //         d3.active(this)
                //             .attr("transform", d => `translate(${innerWidth / 2},${innerHeight / 2})`)
                //             .transition()
                //             .attr("transform", d => `translate(${xScale(xValue(d))},${yScale(yValue(d)) - 10})`)
                //             .transition()
                //             .on("start", repeat);
                //     }
                // })
                ;



            //arrow

            ////const arrowPoints = [[0, 0], [0, 20], [20, 10]];
            ////const lineLength = 200;
            ////const lineStart = [0, 20];
            ////const lineEnd = [lineStart[0] + lineLength, 20];

            ////circleGroup
            ////    .append('defs')
            ////    .append('marker')
            ////    .attr('id', 'arrow')
            ////    .attr('viewBox', [0,-5, 10, 10])
            ////    .attr('refX', 5)
            ////    .attr('refY', 0)
            ////    .attr('markerWidth', 4)
            ////    .attr('markerHeight', 5)
            ////    .attr('orient', 'auto-start-reverse')
            ////    .append("path")
            ////    .attr("d", d3.line()(arrowPoints))
            ////    .attr('stroke', 'black');

            ////circleGroup
            ////    .append('path')
            ////    .attr('d', d3.line()([lineStart, lineEnd]))
            ////    .attr('stroke', 'black')
            ////    .attr('marker-end', 'url(#arrow)')
            ////    .attr('fill', 'none');
        }
        else {
            circleGroup
                .selectAll('*')
                .remove();

            circleGroup
                .selectAll('.scatter-circle')
                .data(data)
                .join('circle')
                .attr('class', 'scatter-circle')
                // .attr('cy', innerHeight / 2)
                // .attr('cx', innerWidth / 2)
                // .attr('r', 0)

                // .on('mouseover', (e, d) => {
                //    setSelectedCircle(d);
                //    setPosition({ xPosition: e.pageX, yPosition: e.pageY });
                // })
                .on("mouseover", (event, d) => {
                    setSelectedCircle(d);
                    tooltip.attr("class", styles.tooltip)
                      // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
                      .style("left", event.pageX + tooltipOffsetX + "px")
                      .style("top", event.pageY - tooltipOffsetY + "px");
                    // tooltipDisplay('Hello');
                    // tooltipDisplay(educationData.find(e => e.fips === d.id));
                    tooltipDisplay(xButton,xValue(d),yButton,yValue(d),"Category:",colorValue(d));
                    // tooltipDisplay(d.id);
                  })
                  .on("mouseout", (e, d) => {
                    // d3.select(e.currentTarget).style("fill", getColor(d));  
                    tooltip.attr("class", styles.hidden);
                    setSelectedCircle(null);
                  })
                // .transition()
                // .duration(2000)
                // .delay((_, i) => i * 10)
                .attr('cy', d => yScale(yValue(d)))
                .attr('cx', d => xScale(xValue(d)))
                .attr('r', d => circleRadius(d))
                // .style('fill', colorData)
                .style('fill', d => d3.hsl(myColorz(d),mySaturation(d),0.7,myOpacity(d)))
                // .style("opacity", myOpacity)
                //.style("fill", 'url(#grump_avatar)')
                //.style("opacity", 0.6)

                // .on("start", function repeat() {
                //     if (buttonValues.motionSwitch) {
                //         d3.active(this)
                //             .attr("cx", d => xScale(xValue(d)))
                //             .attr("cy", d => yScale(yValue(d)))
                //             .transition()
                //             .attr("cx", innerWidth / 2)
                //             .attr("cy", innerHeight / 2)
                //             .transition()
                //             .on("start", repeat);
                //         //.style("fill", d => ofTexture(myTexture(d)))
                //         //.attr("opacity", 0.5);
                //     }
                // })
                ;
        }

        // Add a legend (interactive)

        // circleGroup
        // .selectAll("myLegend")
        // .data(data)
        // .join('g')
        // .append("text")
        //     .attr('x', (d,i) => 30 + i*60)
        //     .attr('y', 30)
        //     .text(d => colorData(d[colorButton]))
        //     .style("fill", d => d3.hsl(myColorz(d),mySaturation(d),0.7,myOpacity(d)))
        //     .style("font-size", 15)
        // .on("click", function(event,d){
        //     // is the element currently visible ?
        //     const currentOpacity = d3.selectAll("." + d.name).style("opacity")
        //     // Change the opacity: from 0 to 1 or from 1 to 0
        //     d3.selectAll("." + d.name).transition().style("opacity", currentOpacity == 1 ? 0:1)

        // })

        // circleGroup.append("g")
        // .attr("class", "brush")
        // .call(brush); 


    //     if (dataset == 'CreditCard') {
    //         // // ADD LEGEND
    //         const legendRectWidth = 280;
    //         const legendRectHeight = 10;
    
    //         const educationRange = d3.extent(data,colorValue);

    //         const legend = circleGroup.append("g")
    //                           .attr("transform", "translate(" + 
    //                               (width - 400) + ", " + (height - 400) + ")");
    
    //     //  for (let i = 0; i < myColorz.length; i++) {
    //         for (let i = 0; i < 8; i++) {
    //           legend.append("rect")
    //                 .attr("x", (legendRectWidth/8)*i)
    //                 .attr("width", legendRectWidth/(8))
    //                 .attr("height", legendRectHeight)
    //                 .style("fill", myScaleColorCat(((educationRange[1]-educationRange[0])/(8-1))*i));
    //      }
    //         // LEGEND AXIS      
    //         const legendScale = d3.scaleLinear()
    //                               .domain(educationRange)
    //                               .range([0, legendRectWidth]);
    
    //         const legendAxis = d3.axisBottom(legendScale)
    //                             .tickValues(educationRange);
    
    //         legend.append("g")
    //               .attr("transform", "translate(0, " + legendRectHeight + ")")
    //               .call(legendAxis);
    //    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, xButton, yButton,sizeButton, 
        colorButton, 
        opaButton, 
        markButton, 
        oriButton,buttonValues, saturationButton]);
    //const handleChange = val => setValue(val);

// SET UP TOOLTIP
const tooltip = d3.select(chart4.current)
.append('div')
.attr('class', 'hidden');

const tooltipOffsetX = 20;
const tooltipOffsetY = 73;

function tooltipDisplay(name, name2, interest1, interest2, interest3, interest4) {
  // const info = `${county.area_name}, ${county.state}\n${county.bachelorsOrHigher}%`;
  const info = (`${name},\n ${name2} ,\n ${interest1}\n${interest2} \n${interest3}\n${interest4}`);
  // const info = county;
  d3.select(`.${styles.tooltip}`).text(info);
}   


    return (

<>
<MainStyle>
{console.log(dataset)}
<svg viewBox={"-50 0 1000 450"}>
                    <Group
                        x={width}
                        y={height}
                        right={margin.right}
                        top={margin.top}>
                        <Axes
                            xScale={xScale}
                            yScale={yScale}
                            innerHeight={innerHeight}
                            xTickPadding={15}
                            yTickPadding={10}
                            //yTickSize={-innerWidth}
                            yAxisTickFormat={axesTickFormat}
                            xAxisTickFormat={axesTickFormat}
                        />
                        <AxisLabel2
                            innerHeight={innerHeight}
                            innerWidth={innerWidth}
                            axisPadding={60}
                            yLabel={yButton}
                            xLabel={xButton}
                            marginLeft={margin.left }
                        />
                        <GroupedCircle ref={circleRef} />
                   
                    {/* <ColorLegend
                        moveX={innerWidth}
                        spacing={30}
                        radius={9}
                        textX={15}
                        colorScale={colorScale}
                        colorData={colorData}
                        width={width}
                        align="vertical"
                    /> */}
                  
                   
                   { (dataset == ('Sales') || dataset == ('CreditCard')) ? "" :
                   <Wrapper spacing={width - innerWidth+100} align={"vertical"}>
                    {colorScale.domain().map((d, i) => (
                        <ColorTick spacing={i * 30} align={"vertical"} key={i}>
                            <ColorCircle r={9} color={d3.hsl(myScaleColorCat(d),1,0.7,1)} />
                        <text dy="0.32em" x={15}>
                            {d}

                        </text>
                        </ColorTick>
                    ))}
                    </Wrapper>
 }
                     </Group>
                    
                {/* {selectedCircle && (
                    <Tooltip
                        selectedCircle={selectedCircle}
                        position={position}
                        xLabel={xButton}
                        yLabel={yButton}
                        tooltipValueX={100}
                        tooltipValue={tooltipValue-10}
                        colorButton={colorButton}
                    />
                )}   */}
                            </svg>
                            </MainStyle>
                            <div ref={chart4} >
            </div>
          
            </>

    );
}

export default ScatterPlot3;
