import React, { useEffect, useState } from "react"
import * as d3 from "d3"
import * as topojson from "topojson-client";
import * as styles from "./ChoroplethMap.module.css"
// import Swiss from './GemeineTOPO.json';
import Gemeinde from './AllGem.json';
import { ContactSupportOutlined, Minimize } from "@material-ui/icons";
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 60,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MainStyle = styled.h2`
font-size: 2.3rem;
font-weight: 300;
margin-bottom: 1rem;
margin-top: 1rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;

const Title = styled.h5`
font-size: 2.3rem;
font-weight: 200;
margin-bottom: 1rem;
margin-left: 25rem;
text-align: left;

@media (max-width: 568px) {
  text-align: center;
}
`;



function ChoroPleth({VarIndex,colorscale}) {
  const chart = React.useRef(null);
  const chart4 = React.useRef(null);

  const classes = useStyles();

  // const [VarIndex, setValue] = useState(2);
  // const handleChange = val => setValue(val);

  const Variables =
    ['Residents in 2019',
      'Change in Inhabitants in %, 2010-2019',
      'Inhabitants per km2,2019',
      '% Foreigners, 2019',
      'Age between 0-19 years in %, 2019',
      'Age between 20-64 years in %, 2019',
      'Age >65 years in %, 2019',
      'Marriage Rate (change in %), 2019',
      'Divorce Rate (change in %),2019',
      'Birth Rate (change in %),2019',

    ];

  const colors = [
    '#f2f0f7',
    '#dadaeb',
    '#bcbddc',
    '#9e9ac8',
    '#807dba',
    '#6a51a3',
    '#4a1486'
  ]


  // --------------- //
  // RETRIEVE DATA //
  // --------------- //
  // radon
  //
  //http://www.bag.admin.ch/themen/strahlung/00046/11952/index.html?lang=en 

  // const educationDataURL = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/for_user_education.json';
  // const countyDataURL = 'https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/counties.json';
  const countyDataURL = 'https://unpkg.com/swiss-maps@4/2021/ch-combined.json';
  // const educationDataURL = 'GemeineTOPOJSONheader.csv'
  const width = 960;
  const height = 750;

  useEffect(() => {
    // setData(Object.keys(Gemeinde));

   
    // Promise.all([d3.json(educationDataURL), d3.json(countyDataURL)])
    Promise.all([Gemeinde, d3.json(countyDataURL)])
      .then(data => {
        const [educationData, topology] = data;

      
        const tooltipOffsetX = 20;
        const tooltipOffsetY = 73;
    
        // SET UP SVG
        const svg = d3.select(chart.current);
        // .append("svg")
        // .attr("viewBox", [0, 0, width, height]);
    

        // SET UP TOOLTIP
        const tooltip = d3.select(chart4.current)
          .append('div')
          .attr('class', 'hidden');

        // --------------- //
        // RETRIEVE DATA //
        // --------------- //
        //Total Inhabitants in Switzerland
        const TInh = 8606033;

        const Keys = Object.keys(educationData[VarIndex+1]);
        const Names = educationData[0];
        const Residents = educationData[VarIndex+1];
        //Value in Percentage
        const ResidentsVal = Object.values(educationData[VarIndex+1]);
        const ResValPer = ResidentsVal.forEach(e => e / TInh);


        // const Residents = educationData[1];
        const educationRange = d3.extent((ResidentsVal));



        // Map and projection
        const projection = d3.geoMercator().rotate([0, 0]).center([8.3, 46.8]).scale(10000).translate([975 / 2, 610 / 2]).precision(0.1);

        const path = d3.geoPath(projection);

        // const handleZoom = svg => {
        //   svg.call(
        //     d3.zoom().on('zoom', ({ transform }) => {
        //       svg.attr('transform', transform);
        //     })
        //   );
        // };
  
        // handleZoom(svg);

       


        const z = d3.interpolatePlasma;
     

        const g2 = svg.append("g");
        const g = svg.append("g");
        const g3 = svg.append("g");
        const g4 = svg.append("g");
        const g5 = svg.append("g");
        const g6 = svg.append("g");


        // Create a color scale
        const color = d3.scaleQuantize()
        .domain(educationRange)
        .range(colorscale);

        // Add a scale for bubble size
        const valueExtent = d3.extent(ResidentsVal)
        const size = d3.scaleSqrt()
          .domain(valueExtent)  // What's in the data
          .range([1, 35])  // Size in pixel


        svg.selectAll('*').remove();
        


        svg
          .selectAll(".path")
          .data(topojson.feature(topology, topology.objects.municipalities).features)
          .join('path')
          .attr('class', 'path')
          .attr("d", path)
          .attr("class", styles.featurecanton)
          .style('fill', d => color(Residents[d.id]))
          .on("mouseover", (event, d) => {
            tooltip.attr("class", styles.tooltip)
              // .attr("data-education", educationData.find(e => e.fips === d.id).bachelorsOrHigher)
              .style("left", event.pageX + tooltipOffsetX + "px")
              .style("top", event.pageY - tooltipOffsetY + "px");
            // tooltipDisplay('Hello');
            // tooltipDisplay(educationData.find(e => e.fips === d.id));
            tooltipDisplay(Names[d.id], Variables[VarIndex], Residents[d.id], false);
            // tooltipDisplay(d.id);
            d3.select(event.currentTarget).style("fill", "#FE621D");
          })
          .on("mouseout", (e, d) => {
            // d3.select(e.currentTarget).style("fill", getColor(d));  
            d3.select(e.currentTarget).style("fill", color(Residents[d.id]));
            tooltip.attr("class", styles.hidden);
          });
        ;


        svg.selectAll(".path")
          .data(topojson.feature(topology, topology.objects.lakes).features)
          .enter().append('path')
          .attr("d", path)
          .attr("class", styles.featurelake)
          ;




        svg.append("path")
          .datum(topojson.mesh(topology, topology.objects.cantons, function (a, b) { return a !== b; }))
          .attr("d", path).attr("class", styles.boundary)
          .style("stroke-width", "1px");


        // // ADD LEGEND
        const legendRectWidth = 360;
        const legendRectHeight = 10;

        const legend = svg.append("g")
                          .attr("transform", "translate(" + 
                              (width - 800) + ", " + (height - 100) + ")");

     for (let i = 0; i < colorscale.length; i++) {
          legend.append("rect")
                .attr("x", (legendRectWidth/colorscale.length)*i)
                .attr("width", legendRectWidth/(colorscale.length))
                .attr("height", legendRectHeight)
                .style("fill", color(((educationRange[1]-educationRange[0])/(colorscale.length-1))*i));
     }

        // LEGEND AXIS      
        const legendScale = d3.scaleLinear()
                              .domain(educationRange)
                              .range([0, legendRectWidth]);

        const legendAxis = d3.axisBottom(legendScale)
                            .tickValues(educationRange);

        legend.append("g")
              .attr("transform", "translate(0, " + legendRectHeight + ")")
              .call(legendAxis);
      // Add legend: circles


     

      }
      );
  }, [VarIndex, colorscale]);

  function tooltipDisplay(name, interest1, interest2, perc) {
    // const info = `${county.area_name}, ${county.state}\n${county.bachelorsOrHigher}%`;
    const info = perc ? (`${name},\n ${interest1}\n\n${interest2}%`) : (`${name},\n ${interest1}\n\n${interest2}`);
    // const info = county;
    d3.select(`.${styles.tooltip}`).text(info);
  }

  




  return (
    <> 
      <MainStyle>
      <svg ref={chart} className={styles.chart} viewBox={"0 0 1500 1500"}>
      </svg>
      </MainStyle>
      <div ref={chart4} >
      </div>

      

    </>
  )
}

export default ChoroPleth