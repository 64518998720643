import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import {
  Box, Grid,
} from '@material-ui/core';
import { Colorscale } from 'react-colorscales';
import ColorscalePicker from 'react-colorscales';
import Paper from '@mui/material/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import PCA from 'pca-js'
import About from './components/About'
import Menu from './components/Menu'
import ComponentN from './components/ComponentN'
import DataTable from './components/DataTable'
import Examples from './components/Examples'
import ScatterPlot from './components/ScatterPlot'
import EigenvaluesChart from './components/EigenvaluesChart'
import mathHelper from '../Second_DataCleaning/utils/mathHelper'
import ActiveExample from './components/ActiveExample'
import Plot from 'react-plotly.js';
// import react-mathjax
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import cov from 'compute-covariance';
// import { Fraction, toTex } from 'algebra.js';
// import { Node, Context } from 'react-mathjax';
import { eigs } from 'mathjs';
import * as jz from 'jeezy'
import * as data2grid from 'data2grid'
import { extent } from 'd3';

const drawerWidth = 240;



// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

export default function SideBar4({
  title,
  subtitle,
  data2,
  keys,
  keys2,
  keys3,
  keys4,
  keysNames,
  groupData,
  setKeys4,
  dataset,
  Datasets,
  corr,
  grid2,
  setdataset,

}) {

  const [data, setData] = useState([])
  const [highlightedComponent, setHighlightedComponent] = useState(null)
  const [highlightedIndex, setHighlightedIndex] = useState(null)
  const [principalComponents, setPrincipalComponents] = useState([])
  const [mean, setMean] = useState([0, 0])
  const [activeExample, setActiveExample] = useState(null)
  const [exampleStep, setExampleStep] = useState(0)
  const [showExamplesModal, setShowExamplesModal] = useState(false)
  const [showTooltips, setShowTooltips] = useState(false)

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));



  const theme = useTheme();


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  ////////////////// PCA ////////////////// 
  const PCAsetosa = [{
    'pc1': [-2.6842071251039483, -2.71539061563413, -2.889819539617916, -2.746437197308734, -2.7285929818313144, -2.279897361009598, -2.8208906821806297, -2.6264819933238184, -2.887958565335634, -2.6738446867191206, -2.5065267893389036, -2.613142718271056, -2.787433975997097, -3.22520044627498, -2.643543216941146, -2.3838693237993756, -2.6225262031258083, -2.6483227324791265, -2.1990779614307634, -2.587346188917738, -2.3105317013131335, -2.5432349073036953, -3.215857694900105, -2.3031285376638824, -2.3561710866838976, -2.5079172268378804, -2.4690559975451225, -2.5623909468367505, -2.6398212683765814, -2.632847908030758, -2.588462051303391, -2.4100773371215958, -2.647636673396901, -2.5971594770759183, -2.6738446867191206, -2.866999846932534, -2.625228464680421, -2.6738446867191206, -2.981842664853908, -2.590323025585674, -2.770138910746324, -2.8522110815663906, -2.9982964428323506, -2.4055141012847012, -2.2088329541767058, -2.715665190747454, -2.537573371013507, -2.8403212968270086, -2.5426857570770482, -2.70391231486365],
    'pc2': [0.3266073147643885, -0.16955684755602624, -0.13734560960502795, -0.31112431575199184, 0.33392456356845474, 0.7477827132251336, -0.082104511024681, 0.17040534896028983, -0.5707980263315918, -0.10669170375273856, 0.651935013672572, 0.021520631960257896, -0.22774018887110628, -0.5032799094854259, 1.1861948994134495, 1.3447543445598622, 0.8180896745965952, 0.3191366677508846, 0.8792440880917367, 0.520473638805968, 0.397867821588892, 0.44003175465981526, 0.14161557162558355, 0.10552267842998224, -0.03120958906833842, -0.139056339913174, 0.1378873145904177, 0.3746845627501061, 0.3192900659603219, -0.19007583063362415, -0.19739307943769085, 0.41808000824761615, 0.8199826325595069, 1.1000219280072685, -0.10669170375273856, 0.07719309572358721, 0.6068000084215864, -0.10669170375273856, -0.4802500488560767, 0.23605933728887282, 0.2710594197651671, -0.9328653674695444, -0.3343075745907773, 0.19591725769606078, 0.4426960304210028, -0.24268148289811298, 0.5103675454766059, -0.2205763382764767, 0.5862810253439884, 0.11501085217050566],
    'pc3': [-0.021511837001963102, -0.20352142500549145, 0.024709240998957712, 0.03767197528530123, 0.09622969977460939, 0.17432561901640295, 0.26425108519069607, -0.015801510264315043, 0.02733540611450723, -0.19153329973564465, -0.06927499581393096, 0.10765035324990473, -0.20032778809538926, 0.06841362917233033, -0.14450570400963542, 0.2837306639397771, 0.14531598882161798, 0.033394254070698105, -0.11452146479835279, 0.21957208800082506, -0.23369560709796947, 0.2148363702796755, 0.2996189815108712, 0.04568004127448713, 0.12940757589599344, -0.24711633789085985, 0.1012630794297022, -0.07235915743602817, -0.1392533737785348, 0.04646646364504579, -0.071275073131526, -0.13838824005004097, 0.23058560425417296, 0.16358191262366278, -0.19153329973564465, -0.15684235049802425, -0.2611631564817056, -0.19153329973564465, 0.07972480735962136, -0.07390123824707649, 0.08424157450476331, -0.34096149110715174, 0.19900842494724197, 0.27071707036883347, 0.30348780926827057, -0.09051560595006833, 0.17191840447686085, 0.0900613765304152, -0.011175267831168983, -0.08269572660682165],       
    'pc4': [0.0010061572415399442, 0.0996024240168161, 0.01930454283250917, -0.0759552741085358, -0.0631287327171094, -0.02714680369790093, -0.0500996250628499, -0.046281760966509884, -0.026615414325656162, -0.0558909659960556, -0.01660824782448407, -0.157704569133212, -0.007235086738118975, -0.021946664123407376, 0.15698096151652366, 0.001926181709664808, 0.16474079123407467, 0.07611821327962356, 0.02532693965637576, -0.06908199117876085, -0.0153237395650814, 0.038439500101118, 0.0018570433474501293, 0.14724549964039962, -0.3016202653321938, 0.03538408126768317, 0.05597045237666049, -0.015240286774598438, 0.06514104720019227, -0.1246111533664724, -0.06047626340782174, 0.23084416997707075, -0.2848089542634612, -0.09895807058362008, -0.0558909659960556, 0.16245280571606574, 0.17587987484195133, -0.0558909659960556, -0.01105295083445918, -0.014556306249655954, 0.09236465729576347, 0.3226506066130546, -0.07587182131805259, 0.17378512921102807, -0.1858575300726545, 0.14298902533804533, -0.19216594594983707, -0.06039281061733849, -0.04833370254133826, 0.03409957300828098]
  }]

  const PCAversicolor = [{
   'pc1': [1.284794587845071, 0.9324107529829175, 1.4640613227790753, 0.1809672063476993, 1.0871344872070012, 0.6404367495231457, 1.095223709938478, -0.7514671406482263, 1.0432977807062809, -0.010190070727852822, -0.5110861958950897, 0.5110980606834775, 0.2623357561531805, 0.984044545169431, -0.1748640019656959, 0.9275729420326997, 0.6595927890562004, 0.23454058625983584, 0.9423617073988423, 0.04324640032870497, 1.1162407237538159, 0.3567865678304614, 1.2964688502855342, 0.9205026489090096, 0.7140082136428333, 0.8996408632837759, 1.33104141885472, 1.557396272070702, 0.812455548998002, -0.30733475566340396, -0.07034288894927106, -0.19188449210314515, 0.13499495045137355, 1.378736982775583, 0.5872748535799112, 0.8072054966767166, 1.2204289662446792, 0.81286779036907, 0.2451951616903462, 0.16451342836925617, 0.4630309888714247, 0.8901604456511564, 0.2288790499696479, -0.7070812839208597, 0.35553303918706386, 0.33112694733272313, 0.375238228946766, 0.6416902781665432, -0.9084633331234948, 0.2978079074069342],
    'pc2':  [0.6854391861329202, 0.3191980898336488, 0.5041898329724531, -0.8256039435761133, 0.0753903892887675, -0.41732348297002575, 0.28389121093848035, -1.0011075129743943, 0.22895690877700686, -0.7205748667019015, -1.2624919538621404, -0.1022841050459702, -0.5478932980253367, -0.12436042022227789, -0.25181557108017066, 0.46823620504311714, -0.3519762910603165, -0.33192182936212034, -0.541822258150014, -0.5814894466123587, -0.08421401387837349, -0.06682382794136613, -0.3275615197949442, -0.18239036332792097, 0.15037915314843728, 0.32961097958188407, 0.24466952060228936, 0.26739258481276484, -0.16233157487863326, -0.36508661276616033, -0.7025379317307259, -0.6774905443743572, -0.3117096427033962, -0.4212051382146283, -0.4832842677174825, 0.19505396377111148, 0.4080353370010176, -0.37067899831890966, -0.266728035661848, -0.6796614693108137, -0.6695265465361225, -0.03381244274676266, -0.4022576201789113, -1.0084247617784612, -0.5032184874026623, -0.21118014066262672, -0.2916220248087798, 0.019071176491270835, -0.7515687251694855, -0.34701652159856394],
    'pc3': [-0.40612955307696313, -0.017129909205125235, -0.33826072771795895, -0.17708285639336488, -0.3065444648144318, 0.04118876935364434, 0.1700225337414998, 0.015672194238160093, -0.414814566148704, 0.28343724625412553, -0.26648995382244983, 0.1323278904749737, -0.6919415783189649, -0.06215742761353879, 0.09365863819195037, -0.31322940056857723, 0.3283842970693479, -0.2702806708356937, -0.4973485411777046, -0.2329635564825228, 0.45984422657275353, -0.22747218032088332, -0.34751321258425033, -0.23161141855267006, -0.32037233282926975, -0.3147714813796257, -0.5212449247379847, -0.16463849085222862, 0.03634357634449068, -0.3153371969389201, -0.24175804484226798, -0.3039165434636248, -0.17497330378776524, 0.015480495121708304, 0.44458375303487097, 0.38945871138620264, -0.23656608684982794, -0.6128710500606732, 0.18956248472924195, -0.05779923880574424, -0.024053890889809318, -0.00976802636842478, -0.22736270503287936, -0.10206934253841171, 0.01788946589650754, 0.08380907322251578, 0.07907335550136596, -0.20417287686374627, -0.007736584506980753, 0.012179139158859284],
    'pc4': [0.01929011685534334, -6.757941710509204e-6, -0.0008576440476193869, 0.09578444837157984, 0.1133845390093369, -0.24267131206866582, -0.08497338932826716, -0.016510592154629893, -0.03752355359177022, -0.005945701975093472, 0.048908806063423405, 0.050100535156594767, 0.061484989083400214, -0.1690106698709526, 0.12494088656962879, 0.10043888414555996, -0.18899152519294937, -0.2119849952922857, 0.2606366850425494, -0.039561180738330155, -0.07721355961071598, 0.12409000046372004, 0.00324623910203691, -0.2868253467053201, 0.0429412331735037, 0.10112286467050256, 0.037505049655828675, 0.07035309510363975, -0.02968027108373628, 0.07653037763062077, 0.00909469851960668, -0.018045458785481777, 0.03418291424986639, -0.17758073696002416, -0.2524424346266573, -0.116615391254638, 0.03163524398466042, 0.15770049117443602, -0.14732804233713756, 0.0309655778879862, -0.26844350832386893, -0.1534482063797552, 0.01862045075866921, 0.047624297804021005, -0.09807163534474155, -0.23868654239135992, -0.13116505111148086, -0.020509676260204425, 0.2335586342743453, -0.050783717136689804]

  }]

  const PCAvirginica = [{
    'pc1': [2.5317269804395583, 1.4140722251737576, 2.6164846082840434, 1.970814945906482, 2.349757983950943, 3.3968799206781375, 0.5193832450849368, 2.932005096991301, 2.319672793874053, 2.9181342336442513, 1.6619349470194866, 1.8023404526607334, 2.165378862953397, 1.3445942175098249, 1.585267299308644, 1.9047435782139124, 1.949248781864498, 3.488765379656386, 3.79468686120997, 1.2983298245678165, 2.4281672590208476, 1.1980973722749628, 3.4992654842989572, 1.3876682501815556, 2.2758536493056933, 2.6141938306983334, 1.2576251829251366, 1.2906696477376016, 2.1228539805083133, 2.3875644023690277, 2.840960925285384, 3.232342898295874, 2.158738373133135, 1.4431026043759747, 1.7796401068985703, 3.076521621020662, 2.144986856709304, 1.9048629251371318, 1.1688534694704038, 2.107653731173268, 2.314303394629548, 1.9224508848357522, 1.4140722251737576, 2.563322712340809, 2.41939121980498, 1.9440170488777369, 1.5256636313138727, 1.764045935526983, 1.901629075288233, 1.3896661333194171],
    'pc2': [-0.011842236640300523, -0.5749250559123048, 0.3419352869872948, -0.18112569470491371, -0.04188254965484184, 0.5471680462301528, -1.1913516890506533, 0.3523770061808598, -0.2455481706069101, 0.7803806293720322, 0.2420384010375224, -0.21615460662653627, 0.21528028337300723, -0.7764154251768252, -0.539307053847172, 0.11881899098269857, 0.040730259427767854, 1.1715445442635644, 0.25326557097253466, -0.7610139365215078, 0.3767819712541526, -0.6055789617645945, 0.4567734669640746, -0.20403098657015473, 0.3333865257566422, 0.5583669502788224, -0.17913699742322278, -0.11642525182937251, -0.2108548845494899, 0.46251925072626066, 0.37274259104902147, 1.3705240359763338, -0.21832553156299322, -0.14380128908272616, -0.5014647947060157, 0.6857644422460568, 0.13890660887731443, 0.048047508231834596, -0.16450249981508994, 0.3714822491771058, 0.1826088507936986, 0.4092711761786957, -0.5749250559123048, 0.27597450223983816, 0.30350393770262885, 0.18741522204601396, -0.3750208481918228, 0.07851918642935747, 0.11587674824796555, -0.28288670917226916],
    'pc3': [0.7584586515284955, 0.2963982243052131, -0.11214137055436675, 0.10653914864984594, 0.28411068086616736, -0.3518731576273015, 0.5466855311249489, -0.4236912783368969, -0.3499221833590494, 0.42173893361376735, 0.24281526346759313, -0.03769532849396476, 0.0331481831991332, 0.2828680182243182, 0.6305704884623257, 0.48013807976572853, 0.042729093929436614, 0.1293200833617756, -0.5169707159938949, -0.3448870474686999, 0.21864907004670506, 0.5126407650398147, -0.5769101867216484, -0.06351132177864816, 0.28467815266071794, -0.20842334661879328, 0.046977807449227076, 0.231613560568306, 0.15351588509125513, -0.45202396144912743, -0.5010315391382015, -0.11844877739515763, 0.20842197616391567, -0.1540829711054271, -0.17581118605670318, -0.33642274120822385, 0.7341847390548603, 0.16047063070600845, 0.2824608810023712, 0.027437856461485263, 0.3228604007423825, 0.1154928159607172, 0.2963982243052131, 0.2912536131268597, 0.5043025169513597, 0.17930287068112669, -0.12063644074491217, 0.13078405342866883, 0.7228735608675687, 0.3623178316312214],
    'pc4': [-0.03259956851444925, -0.01569547827948598, 0.06595604952643848, -0.23685862488186119, -0.0013127240036090917, -0.11121996802024871, -0.09879841992577922, -0.2554073688442786, -0.07626286251324571, 0.107729319478853, 0.11944758456759062, 0.07871345255565043, 0.16266728030785257, 0.14048189225832974, 0.32745536666912806, 0.21711450049899506, -0.15784525195695603, -0.3116298379326806, 0.05645164354097455, -0.04267371805612869, 0.18385417915128938, 0.05950003054907901, -0.13775959759087722, 0.16376353673268976, -0.062230277600379726, -0.24044543308754107, 0.14760054550703341, 0.0030843215735913173, 0.052612433191036374, -0.22990687721412378, -0.020216625381557492, -0.2544873443761551, 0.12772448922911858, -0.18992586408934103, -0.5035294527425076, 0.31058909243773947, 0.051766535137647667, -0.22198014191560672, 0.019330765589731586, 0.2099551985159037, 0.27589665973045435, 0.50409500679105, -0.01569547827948598, 0.05618492696844714, 0.2382179465519489, 0.42508163386614445, 0.25572256452137176, 0.1362945563181901, 0.0408728218486993, -0.15631038532610417]
  }]

  const [PCA_X, setPCX] = useState(PCAsetosa[0]['pc1'],PCAversicolor[0]['pc1'],PCAvirginica[0]['pc1']);

  const [PCA_Y, setPCY] = useState(PCAsetosa[0]['pc2'],PCAversicolor[0]['pc2'],PCAvirginica[0]['pc2']);

  const [pcaKeys, setPCAkeys] = useState(['pc1','pc2']);

  const [PCAXmen, setPCXmenu] = useState('pc1');
  const [PCAYmen, setPCYmenu] = useState('pc2');
  
  const pcaPer = [{
   'pc1': "92.5%",
   'pc2': "5.3%",
   'pc3': "1.7%",
   'pc4': "0.5%",

  }]

  
  const handleChangePCX = (event) => {
    setPCXmenu(event.target.value);
    setPCX([PCAsetosa[0][event.target.value],PCAversicolor[0][event.target.value],PCAvirginica[0][event.target.value]]);
  };

  const handleChangePCY = (event) => {
    setPCYmenu(event.target.value);
    setPCY([PCAsetosa[0][event.target.value],PCAversicolor[0][event.target.value],PCAvirginica[0][event.target.value]]);
  };
 

  const [menutitle, setMenuTitle] = React.useState("petalLength");

  const [menutitle2, setMenuTitle2] = React.useState("sepalWidth");

  const [menutitle3, setMenuTitle3] = React.useState("");

  useEffect(() => {
    setMenuTitle(keys[0]);
    setMenuTitle2(keys[1]);
    setMenuTitle3(keys[2]);
    setPCAkeys(Object.keys(PCAsetosa[0]));

    setPCX([PCAsetosa[0][pcaKeys[0]],PCAversicolor[0][pcaKeys[0]],PCAvirginica[0][pcaKeys[0]]])
    setPCY([PCAsetosa[0][pcaKeys[1]],PCAversicolor[0][pcaKeys[1]],PCAvirginica[0][pcaKeys[1]]])

  }, [dataset, setdataset, keys])

  const handleChangeMode = (event) => {

    setMenuTitle(event.target.value);
  };

  const handleChangeMode2 = (event) => {

    setMenuTitle2(event.target.value);
  };

  const handleChangeMode3 = (event) => {
    setMenuTitle3(event.target.value);
  };





  const classes = useStyles();


  // calculate PCA on data change
  useEffect(() => {
    if (data.length === 0) {
      setPrincipalComponents([])
      return
    }
    const vectors = PCA.getEigenVectors(data)
    setPrincipalComponents(vectors)
    setMean(mathHelper.mean(data))
  }, [data])

  // show tooltips if data is empty
  useEffect(() => {
    if (data.length > 0 || showExamplesModal) setShowTooltips(false)

    setTimeout(() => {
      if (data.length === 0 && !showExamplesModal) setShowTooltips(true)
    }, 2500)
  }, [data, showExamplesModal])


  const linspaceFn = (startValue, stopValue, cardinality) => {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
      arr.push(parseFloat((startValue + (step * i)).toFixed(3)));
    }
    return arr;
  }
  const t = linspaceFn(0, 20, 100)
  const x = t.map(i => (Math.cos(i)))
  const y = t.map(i => Math.sin(i))
  const z = t

  const mat = cov(x, y, z);

  const H = [[5, 2.3], [2.3, 1]];
  const answH = eigs(H);
  // var Latex = require('react-latex');




  // var Latex = require('react-latex');
  const matFixed = Array(3, 3);
  for (let i = 0; i < 3; i++) {
    matFixed[i] = mat[i].map(function (each_element) {
      return Number(each_element.toFixed(5));
    });
  }

  const ansFixed = eigs(matFixed);
  const VectorsCV = ansFixed.vectors;

  const ans = eigs(mat); // returns {values: [E1,E2...sorted], vectors: [v1,v2.... corresponding vectors as columns]}
  const E = ans.values;
  const Efix = E.map(function (each_element) {
    return Number(each_element.toFixed(2));
  });
  const U = ans.vectors;

  const a = 10;

  // Covariance Matrix
  const tex = `\\begin{bmatrix} ${matFixed[0][0]} & ${matFixed[0][1]} & ${matFixed[0][2]}   \\\\ 
  ${matFixed[1][0]} & ${matFixed[1][1]} & ${matFixed[1][2]}   \\\\ 
  ${matFixed[2][0]} & ${matFixed[2][1]} & ${matFixed[2][2]}   \\end{bmatrix}`;

  // Eigenvectors

  const EigVec = `\\begin{bmatrix} ${matFixed[0][0]} & ${matFixed[0][1]} & ${matFixed[0][2]}   \\\\ 
${matFixed[1][0]} & ${matFixed[1][1]} & ${matFixed[1][2]}   \\\\ 
${matFixed[2][0]} & ${matFixed[2][1]} & ${matFixed[2][2]}   \\end{bmatrix}`;

  // Eigenvalues

  const EigVal = `\\begin{bmatrix} ${Efix[0]}   \\\\ 
${Efix[1]}   \\\\ 
${Efix[2]}   \\end{bmatrix}`;

  // Drawing the Values and Vectors
  const nBalls = 50;
  const sizeBalls = 3;
  const s = linspaceFn(0, 1, nBalls);
  const eVect = [];
  const sV = Array(3, 3);
  const ex = [];
  const ey = [];
  const ez = [];
  for (let i = 0; i < 3; i++) {
    //sV[i] = VectorsCV[i] * E[i]; // scaled eigenvector

    ex[i] = s.map(function (x) { return x * VectorsCV[i][0] * E[i]; });
    ey[i] = s.map(function (x) { return x * VectorsCV[i][1] * E[i]; });
    ez[i] = s.map(function (x) { return x * VectorsCV[i][2] * E[i]; });


  }


  const inlineFormula = { mat };
  const blockFormula = `\\frac{n!}{k!(n-k)!} = \\binom{n}{k}`;


  // projection of 3D matrix first by multiplying two matrices
  function multiply(a, b) {
    var aNumRows = a.length, aNumCols = a[0].length,
      bNumRows = b.length, bNumCols = b[0].length,
      m = new Array(aNumRows);  // initialize array of rows
    for (var r = 0; r < aNumRows; ++r) {
      m[r] = new Array(bNumCols); // initialize the current row
      for (var c = 0; c < bNumCols; ++c) {
        m[r][c] = 0;             // initialize the current cell
        for (var i = 0; i < aNumCols; ++i) {
          m[r][c] += a[r][i] * b[i][c];
        }
      }
    }
    return m;
  }



  const FullData = [x, y, z];
  const EigenVec = [VectorsCV[1], VectorsCV[2]];
  // projection
  const projected = multiply(EigenVec, FullData);
  const projZero = new Array(100).fill(0);
  const projZero3 = new Array(3).fill(0);


  const Eigen1D = [projZero3, VectorsCV[2]];
  const projected1D = multiply(Eigen1D, FullData);


  const xValue = data2.map(d => d[keys[0]]);
  const yValue = data2.map(d => d[keys[1]]);
  const zValue = data2.map(d => d[keys[2]]);
  const uValue = data2.map(d => d[keys[3]]);

  const xValueFil = (keyNames) => {
    const filtered = data2.filter((word => word === keyNames));
    filtered.map(d => d[keys[0]])
  };
  const yValueFil = (keyNames) => {
    const filtered = data2.filter((word => word === keyNames));
    filtered.map(d => d[keys[1]])
  };
  const zValueFil = (keyNames) => {
    const filtered = data2.filter((word => word === keyNames));
    filtered.map(d => d[keys[2]])
  };

  const colorValue = data2.map(d => d[keys2[0]]);




  const mat2 = cov(xValue, yValue, zValue, uValue);

  const matFixed2 = Array(4, 4);
  for (let i = 0; i < 4; i++) {
    matFixed2[i] = mat2[i].map(function (each_element) {
      return Number(each_element.toFixed(5));
    });
  }

  const ansFixed2 = eigs(matFixed2);
  const VectorsCV2 = ansFixed2.vectors;

  const ans2 = eigs(mat2); // returns {values: [E1,E2...sorted], vectors: [v1,v2.... corresponding vectors as columns]}
  const E2 = ans2.values;
  const Efix2 = E2.map(function (each_element) {
    return Number(each_element.toFixed(2));
  });
  const U2 = ans2.vectors;
  var newArray = Efix2.reduce((sum, item) => sum += item, 0);

  const ex2 = [];
  const ey2 = [];
  const ez2 = [];
  const eu2 = [];

  for (let i = 0; i < 4; i++) {
    //sV[i] = VectorsCV[i] * E[i]; // scaled eigenvector
    eu2[i] = s.map(function (x) { return x * VectorsCV2[i][0] * E2[i]; });
    ex2[i] = s.map(function (x) { return x * VectorsCV2[i][1] * E2[i]; });
    ey2[i] = s.map(function (x) { return x * VectorsCV2[i][2] * E2[i]; });
    ez2[i] = s.map(function (x) { return x * VectorsCV2[i][3] * E2[i]; });


  }


  const FullData2 = [xValue, yValue, zValue, uValue];
  const EigenVec2 = [VectorsCV2[1], VectorsCV2[2], VectorsCV2[3]];

  const projected2 = multiply(EigenVec2, FullData2);
  const projZero2 = new Array(xValue.length).fill(0);





  var data5 = [];
  var cols = "abcdefghijklmnopqrstuvwxyz".split("");
  for (var i = 0; i <= 30; i++) {
    var obj = { index: i };
    cols.forEach(col => {
      obj[col] = jz.num.randBetween(1, 100);
    });
    data5.push(obj);
  }






  var corr = jz.arr.correlationMatrix(data5, cols);
  var extentVar = extent(corr.map(function (d) { return d.correlation; }).filter(function (d) { return d !== 1; }));


  var grid = data2grid.grid(corr);


  // const vectorsAll = PCA.getEigenVectors(data2);

  // const [grid2, setGrid] = useState(grid);

  // useEffect(() => {

  // var data6 = [];
  // var cols2 = keys;

  // for (var i = 0; i < xValue.length; i++){
  //   var obj2 = {index: i};
  //   cols2.forEach(col => {
  //     obj2[col] = data2.map(d=> d[col])[i];
  //   });
  //   data6.push(obj2);
  // }

  // var corr2 = jz.arr.correlationMatrix(data6, cols2);

  // var extentVar2 = extent(corr2.map(function(d){ return d.correlation; }).filter(function(d){ return d !== 1; }));
  // const grid2 = data2grid.grid(corr2);


  // }, [])



  return (

    <Box sx={{ display: 'flex', align: 'center' }}>
{console.log('ev',U2)}
      <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h4" noWrap sx={{ flexGrow: 1 }} component="div">
            {title} {"|"} {subtitle}
          </Typography>


          <IconButton
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              '& svg': {
                color: "inherit",
                transition: '0.2s',
                transform: 'translateX(0) rotate(0)',
              },
              '&:hover, &:focus': {
                bgcolor: 'unset',
                '& svg:first-of-type': {
                  transform: 'translateX(-4px) rotate(-20deg)',
                },
                '& svg:last-of-type': {
                  right: 0,
                  opacity: 1,
                },
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                height: '80%',
                display: 'block',
                left: 0,
                width: '1px',
                bgcolor: 'divider',
              },
              ...(open && { display: 'none' })
            }}
          >
            <Settings />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />




        <Grid container spacing={2}>

          <Grid item xs={0} md={1}> </Grid>
          <Grid item xs={5} md={4}>
            <Plot

              data={[
                {
                  z: grid2.map(d => d["correlation"]),
                  x: grid2.map(d => d["column_x"]),
                  y: grid2.map(d => d["column_y"]),
                  type: 'heatmap',
                  legendrank: 'reversed',
                  hoverongaps: false,
                  colorscale: 'Greys'
                }
              ]}

              layout={{
                width: 400, height: 340, title: "Correlation Matrix",
                annotations: [],
                xaxis: {
                  ticks: '',
                  side: 'bottom'
                },
                yaxis: {
                  ticks: '',
                  ticksuffix: ' ',
                  width: 650,
                  height: 650,
                  autosize: false
                }
              }}



            />


          </Grid>
          <Grid item xs={5} md={5}>
            {/* <p>Covariance Matrix: <InlineMath math={tex} /></p>
        <p>Eigenvalues: <InlineMath math={EigVal} /></p> */}

            <Plot

              data={[



                { type: "bar", color: 3, colorscale: 'Viridis', name: 'eigenvalues', x: [4, 3, 2, 1], y: [Efix2[0] / newArray, Efix2[1] / newArray, Efix2[2] / newArray, Efix2[3] / newArray] },

              ]}

              layout={{ width: 600, height: 340, title: "Comparison of Eigenvalues in %Variance" }}

            />
            {/* <p>Block formula:</p>
        <BlockMath math={'\\begin{pmatrix} ${a} & b \\\\ c & d\\end{pmatrix}'} /> */}
          </Grid>
          <Grid item xs={0} md={1}> </Grid>

        </Grid>

        <hr></hr>

        <Plot

          data={[

            {
              x: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle])))[0][0],
              y: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle2])))[0][0],
              z: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle3])))[0][0],
              type: 'scatter3d',
              mode: 'markers',
              name: keysNames[0],
              marker: { color: 0, colorscale: 'Viridis', opacity: 0.6, size: 8 },
            },
            {
              x: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle])))[0][1],
              y: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle2])))[0][1],
              z: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle3])))[0][1],
              type: 'scatter3d',
              mode: 'markers',
              name: keysNames[1],
              marker: { color: 1, colorscale: 'Viridis', opacity: 0.6, size: 8 },
            },
            {
              x: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle])))[0][2],
              y: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle2])))[0][2],
              z: groupData.map(d => keysNames.map(names => d[names].map(values => values[menutitle3])))[0][2],
              type: 'scatter3d',
              mode: 'markers',
              name: keysNames[2],
              marker: { color: 2, colorscale: 'Viridis', opacity: 0.6, size: 8 },
            },

          ]}
          layout={{
            width: 620, height: 540, title: '3D Data',
            annotations: [],
            scene: {
              aspectmode: "manual",
              aspectratio: {
                x: 1, y: 0.7, z: 1,
               },
              xaxis: {
                title: {
                  text: menutitle,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },
              },
              yaxis: {
                title: {
                  text: menutitle2,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },


              },
              zaxis: {
                title: {
                  text: menutitle3,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },


              }

            }
          }}
        />
<Plot

          data={[

            // {
            //  x: keys2.map(d=> xValueFil(d)),
            //  y: keys2.map(d=> yValueFil(d)),
            //  z: keys2.map(d=> zValueFil(d)),
            //   type: 'scatter3d',
            //   mode: 'markers',
            //   name: keys2.map(d=>d),
            //   marker: {color: keys2.map(d=> d), colorscale: 'Viridis', opacity: 0.6, size: 8},
            // },


            {
              // x: [-2.6842071251039483, -2.71539061563413, -2.889819539617916, -2.746437197308734, -2.7285929818313144, -2.279897361009598, -2.8208906821806297, -2.6264819933238184, -2.887958565335634, -2.6738446867191206, -2.5065267893389036, -2.613142718271056, -2.787433975997097, -3.22520044627498, -2.643543216941146, -2.3838693237993756, -2.6225262031258083, -2.6483227324791265, -2.1990779614307634, -2.587346188917738, -2.3105317013131335, -2.5432349073036953, -3.215857694900105, -2.3031285376638824, -2.3561710866838976, -2.5079172268378804, -2.4690559975451225, -2.5623909468367505, -2.6398212683765814, -2.632847908030758, -2.588462051303391, -2.4100773371215958, -2.647636673396901, -2.5971594770759183, -2.6738446867191206, -2.866999846932534, -2.625228464680421, -2.6738446867191206, -2.981842664853908, -2.590323025585674, -2.770138910746324, -2.8522110815663906, -2.9982964428323506, -2.4055141012847012, -2.2088329541767058, -2.715665190747454, -2.537573371013507, -2.8403212968270086, -2.5426857570770482, -2.70391231486365],
              // y: [0.3266073147643885, -0.16955684755602624, -0.13734560960502795, -0.31112431575199184, 0.33392456356845474, 0.7477827132251336, -0.082104511024681, 0.17040534896028983, -0.5707980263315918, -0.10669170375273856, 0.651935013672572, 0.021520631960257896, -0.22774018887110628, -0.5032799094854259, 1.1861948994134495, 1.3447543445598622, 0.8180896745965952, 0.3191366677508846, 0.8792440880917367, 0.520473638805968, 0.397867821588892, 0.44003175465981526, 0.14161557162558355, 0.10552267842998224, -0.03120958906833842, -0.139056339913174, 0.1378873145904177, 0.3746845627501061, 0.3192900659603219, -0.19007583063362415, -0.19739307943769085, 0.41808000824761615, 0.8199826325595069, 1.1000219280072685, -0.10669170375273856, 0.07719309572358721, 0.6068000084215864, -0.10669170375273856, -0.4802500488560767, 0.23605933728887282, 0.2710594197651671, -0.9328653674695444, -0.3343075745907773, 0.19591725769606078, 0.4426960304210028, -0.24268148289811298, 0.5103675454766059, -0.2205763382764767, 0.5862810253439884, 0.11501085217050566],
              // // z: [-0.021511837001963102, -0.20352142500549145, 0.024709240998957712, 0.03767197528530123, 0.09622969977460939, 0.17432561901640295, 0.26425108519069607, -0.015801510264315043, 0.02733540611450723, -0.19153329973564465, -0.06927499581393096, 0.10765035324990473, -0.20032778809538926, 0.06841362917233033, -0.14450570400963542, 0.2837306639397771, 0.14531598882161798, 0.033394254070698105, -0.11452146479835279, 0.21957208800082506, -0.23369560709796947, 0.2148363702796755, 0.2996189815108712, 0.04568004127448713, 0.12940757589599344, -0.24711633789085985, 0.1012630794297022, -0.07235915743602817, -0.1392533737785348, 0.04646646364504579, -0.071275073131526, -0.13838824005004097, 0.23058560425417296, 0.16358191262366278, -0.19153329973564465, -0.15684235049802425, -0.2611631564817056, -0.19153329973564465, 0.07972480735962136, -0.07390123824707649, 0.08424157450476331, -0.34096149110715174, 0.19900842494724197, 0.27071707036883347, 0.30348780926827057, -0.09051560595006833, 0.17191840447686085, 0.0900613765304152, -0.011175267831168983, -0.08269572660682165],
              x: PCA_X[0],
              y: PCA_Y[0],
              type: 'scatter2d',
              mode: 'markers',
              name: "setosa",
              marker: { color: 0, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            },
            {
              // x: [1.284794587845071, 0.9324107529829175, 1.4640613227790753, 0.1809672063476993, 1.0871344872070012, 0.6404367495231457, 1.095223709938478, -0.7514671406482263, 1.0432977807062809, -0.010190070727852822, -0.5110861958950897, 0.5110980606834775, 0.2623357561531805, 0.984044545169431, -0.1748640019656959, 0.9275729420326997, 0.6595927890562004, 0.23454058625983584, 0.9423617073988423, 0.04324640032870497, 1.1162407237538159, 0.3567865678304614, 1.2964688502855342, 0.9205026489090096, 0.7140082136428333, 0.8996408632837759, 1.33104141885472, 1.557396272070702, 0.812455548998002, -0.30733475566340396, -0.07034288894927106, -0.19188449210314515, 0.13499495045137355, 1.378736982775583, 0.5872748535799112, 0.8072054966767166, 1.2204289662446792, 0.81286779036907, 0.2451951616903462, 0.16451342836925617, 0.4630309888714247, 0.8901604456511564, 0.2288790499696479, -0.7070812839208597, 0.35553303918706386, 0.33112694733272313, 0.375238228946766, 0.6416902781665432, -0.9084633331234948, 0.2978079074069342],
              // y: [0.6854391861329202, 0.3191980898336488, 0.5041898329724531, -0.8256039435761133, 0.0753903892887675, -0.41732348297002575, 0.28389121093848035, -1.0011075129743943, 0.22895690877700686, -0.7205748667019015, -1.2624919538621404, -0.1022841050459702, -0.5478932980253367, -0.12436042022227789, -0.25181557108017066, 0.46823620504311714, -0.3519762910603165, -0.33192182936212034, -0.541822258150014, -0.5814894466123587, -0.08421401387837349, -0.06682382794136613, -0.3275615197949442, -0.18239036332792097, 0.15037915314843728, 0.32961097958188407, 0.24466952060228936, 0.26739258481276484, -0.16233157487863326, -0.36508661276616033, -0.7025379317307259, -0.6774905443743572, -0.3117096427033962, -0.4212051382146283, -0.4832842677174825, 0.19505396377111148, 0.4080353370010176, -0.37067899831890966, -0.266728035661848, -0.6796614693108137, -0.6695265465361225, -0.03381244274676266, -0.4022576201789113, -1.0084247617784612, -0.5032184874026623, -0.21118014066262672, -0.2916220248087798, 0.019071176491270835, -0.7515687251694855, -0.34701652159856394],
              // // z: [-0.40612955307696313, -0.017129909205125235, -0.33826072771795895, -0.17708285639336488, -0.3065444648144318, 0.04118876935364434, 0.1700225337414998, 0.015672194238160093, -0.414814566148704, 0.28343724625412553, -0.26648995382244983, 0.1323278904749737, -0.6919415783189649, -0.06215742761353879, 0.09365863819195037, -0.31322940056857723, 0.3283842970693479, -0.2702806708356937, -0.4973485411777046, -0.2329635564825228, 0.45984422657275353, -0.22747218032088332, -0.34751321258425033, -0.23161141855267006, -0.32037233282926975, -0.3147714813796257, -0.5212449247379847, -0.16463849085222862, 0.03634357634449068, -0.3153371969389201, -0.24175804484226798, -0.3039165434636248, -0.17497330378776524, 0.015480495121708304, 0.44458375303487097, 0.38945871138620264, -0.23656608684982794, -0.6128710500606732, 0.18956248472924195, -0.05779923880574424, -0.024053890889809318, -0.00976802636842478, -0.22736270503287936, -0.10206934253841171, 0.01788946589650754, 0.08380907322251578, 0.07907335550136596, -0.20417287686374627, -0.007736584506980753, 0.012179139158859284],
              x: PCA_X[1],
              y: PCA_Y[1],
              type: 'scatter2d',
              mode: 'markers',
              name: "versicolor",
              marker: { color: 1, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            },
            {
              // x: [2.5317269804395583, 1.4140722251737576, 2.6164846082840434, 1.970814945906482, 2.349757983950943, 3.3968799206781375, 0.5193832450849368, 2.932005096991301, 2.319672793874053, 2.9181342336442513, 1.6619349470194866, 1.8023404526607334, 2.165378862953397, 1.3445942175098249, 1.585267299308644, 1.9047435782139124, 1.949248781864498, 3.488765379656386, 3.79468686120997, 1.2983298245678165, 2.4281672590208476, 1.1980973722749628, 3.4992654842989572, 1.3876682501815556, 2.2758536493056933, 2.6141938306983334, 1.2576251829251366, 1.2906696477376016, 2.1228539805083133, 2.3875644023690277, 2.840960925285384, 3.232342898295874, 2.158738373133135, 1.4431026043759747, 1.7796401068985703, 3.076521621020662, 2.144986856709304, 1.9048629251371318, 1.1688534694704038, 2.107653731173268, 2.314303394629548, 1.9224508848357522, 1.4140722251737576, 2.563322712340809, 2.41939121980498, 1.9440170488777369, 1.5256636313138727, 1.764045935526983, 1.901629075288233, 1.3896661333194171],
              // y: [-0.011842236640300523, -0.5749250559123048, 0.3419352869872948, -0.18112569470491371, -0.04188254965484184, 0.5471680462301528, -1.1913516890506533, 0.3523770061808598, -0.2455481706069101, 0.7803806293720322, 0.2420384010375224, -0.21615460662653627, 0.21528028337300723, -0.7764154251768252, -0.539307053847172, 0.11881899098269857, 0.040730259427767854, 1.1715445442635644, 0.25326557097253466, -0.7610139365215078, 0.3767819712541526, -0.6055789617645945, 0.4567734669640746, -0.20403098657015473, 0.3333865257566422, 0.5583669502788224, -0.17913699742322278, -0.11642525182937251, -0.2108548845494899, 0.46251925072626066, 0.37274259104902147, 1.3705240359763338, -0.21832553156299322, -0.14380128908272616, -0.5014647947060157, 0.6857644422460568, 0.13890660887731443, 0.048047508231834596, -0.16450249981508994, 0.3714822491771058, 0.1826088507936986, 0.4092711761786957, -0.5749250559123048, 0.27597450223983816, 0.30350393770262885, 0.18741522204601396, -0.3750208481918228, 0.07851918642935747, 0.11587674824796555, -0.28288670917226916],
              // // z: [0.7584586515284955, 0.2963982243052131, -0.11214137055436675, 0.10653914864984594, 0.28411068086616736, -0.3518731576273015, 0.5466855311249489, -0.4236912783368969, -0.3499221833590494, 0.42173893361376735, 0.24281526346759313, -0.03769532849396476, 0.0331481831991332, 0.2828680182243182, 0.6305704884623257, 0.48013807976572853, 0.042729093929436614, 0.1293200833617756, -0.5169707159938949, -0.3448870474686999, 0.21864907004670506, 0.5126407650398147, -0.5769101867216484, -0.06351132177864816, 0.28467815266071794, -0.20842334661879328, 0.046977807449227076, 0.231613560568306, 0.15351588509125513, -0.45202396144912743, -0.5010315391382015, -0.11844877739515763, 0.20842197616391567, -0.1540829711054271, -0.17581118605670318, -0.33642274120822385, 0.7341847390548603, 0.16047063070600845, 0.2824608810023712, 0.027437856461485263, 0.3228604007423825, 0.1154928159607172, 0.2963982243052131, 0.2912536131268597, 0.5043025169513597, 0.17930287068112669, -0.12063644074491217, 0.13078405342866883, 0.7228735608675687, 0.3623178316312214],
              x: PCA_X[2],
              y: PCA_Y[2],
              type: 'scatter2d',
              mode: 'markers',
              name: "virginica",
              marker: { color: 2, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            },
            // {type: 'markers', x: eVect[0], y: eVect[1], z: eVect[2], size: sizeBalls},
            // {type: 'markers', x: eVect[0], y: eVect[1], z: eVect[2], size: sizeBalls},
          ]}
          layout={{
            width: 520, height: 540, title: 'PCA in 2D',
              
              xaxis: {
                showgrid: false,
                title: {
                  text: `${PCAXmen} ${pcaPer[0][PCAXmen]}`,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f',

                  }
                },
              },
              yaxis: {
                showgrid: false,
                title: {
                  text: `${PCAYmen} ${pcaPer[0][PCAYmen]}`,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f',

                  }
                },


              },


              

            
          }}
         
        />
{console.log(ex2[1], 'ex',ex2[2], ex2[3])}
<Plot

          data={[

            // {
            //  x: keys2.map(d=> xValueFil(d)),
            //  y: keys2.map(d=> yValueFil(d)),
            //  z: keys2.map(d=> zValueFil(d)),
            //   type: 'scatter3d',
            //   mode: 'markers',
            //   name: keys2.map(d=>d),
            //   marker: {color: keys2.map(d=> d), colorscale: 'Viridis', opacity: 0.6, size: 8},
            // },


            
            {
              x: [-2.6842071251039483, -2.71539061563413, -2.889819539617916, -2.746437197308734, -2.7285929818313144, -2.279897361009598, -2.8208906821806297, -2.6264819933238184, -2.887958565335634, -2.6738446867191206, -2.5065267893389036, -2.613142718271056, -2.787433975997097, -3.22520044627498, -2.643543216941146, -2.3838693237993756, -2.6225262031258083, -2.6483227324791265, -2.1990779614307634, -2.587346188917738, -2.3105317013131335, -2.5432349073036953, -3.215857694900105, -2.3031285376638824, -2.3561710866838976, -2.5079172268378804, -2.4690559975451225, -2.5623909468367505, -2.6398212683765814, -2.632847908030758, -2.588462051303391, -2.4100773371215958, -2.647636673396901, -2.5971594770759183, -2.6738446867191206, -2.866999846932534, -2.625228464680421, -2.6738446867191206, -2.981842664853908, -2.590323025585674, -2.770138910746324, -2.8522110815663906, -2.9982964428323506, -2.4055141012847012, -2.2088329541767058, -2.715665190747454, -2.537573371013507, -2.8403212968270086, -2.5426857570770482, -2.70391231486365],
              y: [0.3266073147643885, -0.16955684755602624, -0.13734560960502795, -0.31112431575199184, 0.33392456356845474, 0.7477827132251336, -0.082104511024681, 0.17040534896028983, -0.5707980263315918, -0.10669170375273856, 0.651935013672572, 0.021520631960257896, -0.22774018887110628, -0.5032799094854259, 1.1861948994134495, 1.3447543445598622, 0.8180896745965952, 0.3191366677508846, 0.8792440880917367, 0.520473638805968, 0.397867821588892, 0.44003175465981526, 0.14161557162558355, 0.10552267842998224, -0.03120958906833842, -0.139056339913174, 0.1378873145904177, 0.3746845627501061, 0.3192900659603219, -0.19007583063362415, -0.19739307943769085, 0.41808000824761615, 0.8199826325595069, 1.1000219280072685, -0.10669170375273856, 0.07719309572358721, 0.6068000084215864, -0.10669170375273856, -0.4802500488560767, 0.23605933728887282, 0.2710594197651671, -0.9328653674695444, -0.3343075745907773, 0.19591725769606078, 0.4426960304210028, -0.24268148289811298, 0.5103675454766059, -0.2205763382764767, 0.5862810253439884, 0.11501085217050566],
              z: [-0.021511837001963102, -0.20352142500549145, 0.024709240998957712, 0.03767197528530123, 0.09622969977460939, 0.17432561901640295, 0.26425108519069607, -0.015801510264315043, 0.02733540611450723, -0.19153329973564465, -0.06927499581393096, 0.10765035324990473, -0.20032778809538926, 0.06841362917233033, -0.14450570400963542, 0.2837306639397771, 0.14531598882161798, 0.033394254070698105, -0.11452146479835279, 0.21957208800082506, -0.23369560709796947, 0.2148363702796755, 0.2996189815108712, 0.04568004127448713, 0.12940757589599344, -0.24711633789085985, 0.1012630794297022, -0.07235915743602817, -0.1392533737785348, 0.04646646364504579, -0.071275073131526, -0.13838824005004097, 0.23058560425417296, 0.16358191262366278, -0.19153329973564465, -0.15684235049802425, -0.2611631564817056, -0.19153329973564465, 0.07972480735962136, -0.07390123824707649, 0.08424157450476331, -0.34096149110715174, 0.19900842494724197, 0.27071707036883347, 0.30348780926827057, -0.09051560595006833, 0.17191840447686085, 0.0900613765304152, -0.011175267831168983, -0.08269572660682165],
              type: 'scatter3d',
              mode: 'markers',
              name: "setosa",
              marker: { color: 0, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            },
            {
              x: [1.284794587845071, 0.9324107529829175, 1.4640613227790753, 0.1809672063476993, 1.0871344872070012, 0.6404367495231457, 1.095223709938478, -0.7514671406482263, 1.0432977807062809, -0.010190070727852822, -0.5110861958950897, 0.5110980606834775, 0.2623357561531805, 0.984044545169431, -0.1748640019656959, 0.9275729420326997, 0.6595927890562004, 0.23454058625983584, 0.9423617073988423, 0.04324640032870497, 1.1162407237538159, 0.3567865678304614, 1.2964688502855342, 0.9205026489090096, 0.7140082136428333, 0.8996408632837759, 1.33104141885472, 1.557396272070702, 0.812455548998002, -0.30733475566340396, -0.07034288894927106, -0.19188449210314515, 0.13499495045137355, 1.378736982775583, 0.5872748535799112, 0.8072054966767166, 1.2204289662446792, 0.81286779036907, 0.2451951616903462, 0.16451342836925617, 0.4630309888714247, 0.8901604456511564, 0.2288790499696479, -0.7070812839208597, 0.35553303918706386, 0.33112694733272313, 0.375238228946766, 0.6416902781665432, -0.9084633331234948, 0.2978079074069342],
              y: [0.6854391861329202, 0.3191980898336488, 0.5041898329724531, -0.8256039435761133, 0.0753903892887675, -0.41732348297002575, 0.28389121093848035, -1.0011075129743943, 0.22895690877700686, -0.7205748667019015, -1.2624919538621404, -0.1022841050459702, -0.5478932980253367, -0.12436042022227789, -0.25181557108017066, 0.46823620504311714, -0.3519762910603165, -0.33192182936212034, -0.541822258150014, -0.5814894466123587, -0.08421401387837349, -0.06682382794136613, -0.3275615197949442, -0.18239036332792097, 0.15037915314843728, 0.32961097958188407, 0.24466952060228936, 0.26739258481276484, -0.16233157487863326, -0.36508661276616033, -0.7025379317307259, -0.6774905443743572, -0.3117096427033962, -0.4212051382146283, -0.4832842677174825, 0.19505396377111148, 0.4080353370010176, -0.37067899831890966, -0.266728035661848, -0.6796614693108137, -0.6695265465361225, -0.03381244274676266, -0.4022576201789113, -1.0084247617784612, -0.5032184874026623, -0.21118014066262672, -0.2916220248087798, 0.019071176491270835, -0.7515687251694855, -0.34701652159856394],
              z: [-0.40612955307696313, -0.017129909205125235, -0.33826072771795895, -0.17708285639336488, -0.3065444648144318, 0.04118876935364434, 0.1700225337414998, 0.015672194238160093, -0.414814566148704, 0.28343724625412553, -0.26648995382244983, 0.1323278904749737, -0.6919415783189649, -0.06215742761353879, 0.09365863819195037, -0.31322940056857723, 0.3283842970693479, -0.2702806708356937, -0.4973485411777046, -0.2329635564825228, 0.45984422657275353, -0.22747218032088332, -0.34751321258425033, -0.23161141855267006, -0.32037233282926975, -0.3147714813796257, -0.5212449247379847, -0.16463849085222862, 0.03634357634449068, -0.3153371969389201, -0.24175804484226798, -0.3039165434636248, -0.17497330378776524, 0.015480495121708304, 0.44458375303487097, 0.38945871138620264, -0.23656608684982794, -0.6128710500606732, 0.18956248472924195, -0.05779923880574424, -0.024053890889809318, -0.00976802636842478, -0.22736270503287936, -0.10206934253841171, 0.01788946589650754, 0.08380907322251578, 0.07907335550136596, -0.20417287686374627, -0.007736584506980753, 0.012179139158859284],
              type: 'scatter3d',
              mode: 'markers',
              name: "versicolor",
              marker: { color: 1, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            },
            {
              x: [2.5317269804395583, 1.4140722251737576, 2.6164846082840434, 1.970814945906482, 2.349757983950943, 3.3968799206781375, 0.5193832450849368, 2.932005096991301, 2.319672793874053, 2.9181342336442513, 1.6619349470194866, 1.8023404526607334, 2.165378862953397, 1.3445942175098249, 1.585267299308644, 1.9047435782139124, 1.949248781864498, 3.488765379656386, 3.79468686120997, 1.2983298245678165, 2.4281672590208476, 1.1980973722749628, 3.4992654842989572, 1.3876682501815556, 2.2758536493056933, 2.6141938306983334, 1.2576251829251366, 1.2906696477376016, 2.1228539805083133, 2.3875644023690277, 2.840960925285384, 3.232342898295874, 2.158738373133135, 1.4431026043759747, 1.7796401068985703, 3.076521621020662, 2.144986856709304, 1.9048629251371318, 1.1688534694704038, 2.107653731173268, 2.314303394629548, 1.9224508848357522, 1.4140722251737576, 2.563322712340809, 2.41939121980498, 1.9440170488777369, 1.5256636313138727, 1.764045935526983, 1.901629075288233, 1.3896661333194171],
              y: [-0.011842236640300523, -0.5749250559123048, 0.3419352869872948, -0.18112569470491371, -0.04188254965484184, 0.5471680462301528, -1.1913516890506533, 0.3523770061808598, -0.2455481706069101, 0.7803806293720322, 0.2420384010375224, -0.21615460662653627, 0.21528028337300723, -0.7764154251768252, -0.539307053847172, 0.11881899098269857, 0.040730259427767854, 1.1715445442635644, 0.25326557097253466, -0.7610139365215078, 0.3767819712541526, -0.6055789617645945, 0.4567734669640746, -0.20403098657015473, 0.3333865257566422, 0.5583669502788224, -0.17913699742322278, -0.11642525182937251, -0.2108548845494899, 0.46251925072626066, 0.37274259104902147, 1.3705240359763338, -0.21832553156299322, -0.14380128908272616, -0.5014647947060157, 0.6857644422460568, 0.13890660887731443, 0.048047508231834596, -0.16450249981508994, 0.3714822491771058, 0.1826088507936986, 0.4092711761786957, -0.5749250559123048, 0.27597450223983816, 0.30350393770262885, 0.18741522204601396, -0.3750208481918228, 0.07851918642935747, 0.11587674824796555, -0.28288670917226916],
              z: [0.7584586515284955, 0.2963982243052131, -0.11214137055436675, 0.10653914864984594, 0.28411068086616736, -0.3518731576273015, 0.5466855311249489, -0.4236912783368969, -0.3499221833590494, 0.42173893361376735, 0.24281526346759313, -0.03769532849396476, 0.0331481831991332, 0.2828680182243182, 0.6305704884623257, 0.48013807976572853, 0.042729093929436614, 0.1293200833617756, -0.5169707159938949, -0.3448870474686999, 0.21864907004670506, 0.5126407650398147, -0.5769101867216484, -0.06351132177864816, 0.28467815266071794, -0.20842334661879328, 0.046977807449227076, 0.231613560568306, 0.15351588509125513, -0.45202396144912743, -0.5010315391382015, -0.11844877739515763, 0.20842197616391567, -0.1540829711054271, -0.17581118605670318, -0.33642274120822385, 0.7341847390548603, 0.16047063070600845, 0.2824608810023712, 0.027437856461485263, 0.3228604007423825, 0.1154928159607172, 0.2963982243052131, 0.2912536131268597, 0.5043025169513597, 0.17930287068112669, -0.12063644074491217, 0.13078405342866883, 0.7228735608675687, 0.3623178316312214],
              type: 'scatter3d',
              mode: 'markers',
              name: "virginica",
              marker: { color: 2, colorscale: 'Viridis', opacity: 0.8, size: 8 },
            }
           ,
        //  {
        //   x: ex2[1], y: ex2[2], z: ex2[3],
        //   type: 'scatter3d',
        //   mode: 'markers', marker: { size: sizeBalls }, name: `Eigenvector1, ${(Efix2[0] / newArray).toFixed(4)*100} % Variance`
        // },
        // {
        //   x: ey2[1], y: ey2[2], z: ey2[3],
        //   type: 'scatter3d',
        //   mode: 'markers', marker: { size: sizeBalls }, name: `Eigenvector2, ${(Efix2[1] / newArray).toFixed(2)*100} % Variance`
        // },
        // {
        //   x: ez2[1], y: ez2[2], z: ez2[3],
        //   type: 'scatter3d',
        //   mode: 'markers', marker: { size: sizeBalls }, name: `Eigenvector3, ${(Efix2[2] / newArray).toFixed(4)*100} % Variance`
        // },
        // {
        //   x: eu2[1], y: eu2[2], z: eu2[3],
        //   type: 'scatter3d',
        //   mode: 'markers', marker: { size: sizeBalls }, name: `Eigenvector4, ${(Efix2[3] / newArray).toFixed(4)*100} % Variance`
        // },


         
         
          ]}
          layout={{
            width: 620, height: 640, title: 'PCA in 3D',
            annotations: [],
            scene: {
              aspectmode: "manual",
              aspectratio: {
                x: 1, y: 0.7, z: 1,
               },
              xaxis: {
                title: {
                  text: `PC1 92.5% Variance`,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },
              },
              yaxis: {
                title: {
                  text: `PC2 5.3% Variance`,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },


              },
              zaxis: {
                title: {
                  text: `PC3 1.7% Variance`,
                  font: {
                    family: 'Courier New, monospace',
                    size: 12,
                    color: '#7f7f7f'
                  }
                },


              }

            }
          }}
        />



       









      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <Divider />


        <div >

          <>
            <Card sx={{ minWidth: drawerWidth}}>
              <CardContent>


                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions4-header"
                  >
                    <Box className={classes.summary}>

                      3D Plot Settings
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div >

                      <>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label">X Parameter</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={menutitle}
                            label="XParameter"
                            onChange={handleChangeMode}
                          >
                            {keys.map((KeyName, index) => (

                              <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                        <br></br>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label2">Y Parameter</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={menutitle2}
                            label="YParameter"
                            onChange={handleChangeMode2}
                          >
                            {keys.map((KeyName, index) => (

                              <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                        <br></br>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label2">Z Parameter</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={menutitle3}
                            label="YParameter"
                            onChange={handleChangeMode3}
                          >
                            {keys.map((KeyName, index) => (

                              <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>

                      </>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions3-content"
                    id="additional-actions4-header"
                  >
                    <Box className={classes.summary}>

                      2D PCA Plot Settings
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div >

                      <>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label">X Parameter</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={PCAXmen}
                            label="XParameter"
                            onChange={handleChangePCX}
                          >
                            {pcaKeys.map((KeyName, index) => (

                              <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                        <br></br>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label2">Y Parameter</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={PCAYmen}
                            label="YParameter"
                            onChange={handleChangePCY}
                          >
                            {pcaKeys.map((KeyName, index) => (

                              <MenuItem key={index} value={KeyName}>{KeyName}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                       

                      </>
                    </div>
                  </AccordionDetails>
                </Accordion>

                


              </CardContent>
            </Card>
          </>
        </div>






        <Divider />


        <Divider />











      </Drawer>
    </Box>







  );
}
