import styled from 'styled-components';
import PropsTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import MuiAvatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
import { pink} from '@material-ui/core/colors';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Logo3 } from "./logos/1-datacleaner.svg";
import { ReactComponent as LogoDim } from "./logos/5-dim-reduction.svg";
import { ReactComponent as LogoFound } from "./logos/1-intro.svg";
import { ReactComponent as LogoFound2 } from "./logos/4-visfoundations-b.svg";
import { ReactComponent as LogoClustering } from "./logos/6-clustering.svg";
import { ReactComponent as LogoMulti } from "./logos/7-multivariate.svg";
import { ReactComponent as LogoGeo } from "./logos/8-geo.svg";


const HeyTitle = styled.text`
  font-size: 1.5rem;
  margin-left: 0rem;
  color: #696969;
`;


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    

    list: {
        width: 300,

    },
    fullList: {
        width: 400,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 20,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(10)+1,
        },
    },
    toolbar: theme.mixins.toolbar,
    divider: {
        // Theme Color, or use css color in quote
        background: 'blue',
    },
    
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: theme.palette.getContrastText(pink[200]),
        backgroundColor: pink[100],
    },
    root: {
        "&$selected": {
          backgroundColor: "pink",
          color: "red",
          "&:hover": {
            backgroundColor: "tomato"
          }
        }
      },
      selected: {},
      imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
      },
      iconRoot: {
        textAlign: 'center'
      }
    
    }
    
    ))
    ;


const Avatar = withStyles({
    root: {
        "&.selected": {
            color: "purple",
        },
        "&,&:focus": {
            backgroundColor: "black",
            color: "white",
            "& .ListItemIcon-root": {
                color: "white"
            }
        },
        "&:hover": {
            backgroundColor: "purple",
            color: "white",
            "& .Avatar-root": {
                color: "white",
                transform: 'translateX(-4px) rotate(-20deg)',
            }
          
        },
    },
        selected: {},
})(MuiAvatar);

const routes = [
    // {
    //     index: 0,
    //     link: '/zero',
    //     icon: <AccountBalanceRoundedIcon  fontSize="large" sx={{ color: 'black'}}/>,
    //     text: 'Home',
    // },

    {
        index: 0,
        link: '/one',
        icon: <LogoFound />
      ,
        text: 'Intro Data Preprocessing',
    },
    {
        index: 1,
        link: '/two',
        icon: <Logo3 />,
        text: 'Data Cleaning',
    },
    {
        index: 2,
        link: '/three',
        icon: <LogoFound2/>,
        text: 'Visualization Fundamentals',
    },
    {
        index: 3,
        link: '/four',
        icon: <LogoDim />,
        text: 'Dimensionality Reduction',
    },
    {
        index: 4,
        link: '/five',
        icon: <LogoClustering />,
        text: 'Clustering',
    },
    {
        index: 5,
        link: '/six',
        icon: <LogoMulti />,
        text: 'Multivariate Data Visualization',
    },
    {
        index: 6,
        link: '/seven',
        icon: <LogoGeo />,
        text: 'Geo Mapping',
    },
];

export default function Header({ title}) {
    {

        const classes = useStyles();

        const [state, setState] = React.useState(false);

        const toggleDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            setState(!state);
        };

        const [selectedIndex, setSelectedIndex] = React.useState(1);

        const handleClick = (event, index) => {
            setSelectedIndex(index);
        };

        const list = (anchor) => (
            <div
                className={clsx(classes.list, {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <div>
                    <Button style={{ position: 'absolute', top: '0.25em', left: '5%', zIndex: 2 }} onClick={toggleDrawer()}>
                        <ArrowForwardIosIcon fontSize="small" color="primary" />
                    </Button>
                </div>
                <>
                    <List>
                        <Divider light={true} />
                        {routes.map((item, index) => (
                            <>
                                <Divider light={true} />
                                <Link to={item.link} >
                                    <ListItem button
                                    classes={{
                                        root: classes.root,
                                        selected: classes.selected
                                      }}
                                    >
                                        {/* <ListItemIcon > */}
                                        <Tooltip title={item.text} arrow placement="right">
                                        <IconButton>
                                            <Avatar
                                                button
                                                key={item}
                                                selected={selectedIndex === index}
                                                onClick={(event) => handleClick(event, index)}
                                                >
                                                <div style={{width: '26px', height: '26px' }}> 
                                                {item.icon}
                                                </div>
                                            </Avatar>
                                            </IconButton>
                                            </Tooltip>
                                 
                                        <ListItemText> <HeyTitle >{item.text}</HeyTitle></ListItemText>
                                    </ListItem>
                                </Link>
                                <Divider light={true} />
                            </>
                        ))}
                    </List>
                </>
              
            </div>
        );
        if (title) {
            return (
                <>
                    <div>

                        <Drawer
                            variant="permanent"
                            open={state} onClose={toggleDrawer()}
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: state,
                                [classes.drawerClose]: !state,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: state,
                                    [classes.drawerClose]: !state,
                                }),
                            }}>
                           
                            <div className={classes.toolbar} />
                            {list()}
                        </Drawer>

                    </div>
                </>
            );
        }



       
    }

    return null;
}



