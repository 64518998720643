import React, { useEffect } from 'react';
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Divider from '@material-ui/core/Divider';
import {
    Box, Grid,
} from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Settings from '@mui/icons-material/Settings';

const drawerWidth = 190;



// new stuff

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));




export default function SideBar(props) {
    const theme = useTheme();


    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // const [state, setState] = React.useState({
    //     sizeSwitch: false,
    //     colorSwitch: true,
    //     brightnessSwitch: false,
    //     orientationSwitch: false,
    //     markSwitch: false,
    //     textureSwitch: false,
    //     motionSwitch: false,
    //     logSwitchX: false,
    //     logSwitchY: false,

    // });

    // const [anchorEl, setAnchorEl] = React.useState(null);

    const [valueSeed, setValueSeed] = React.useState(1);
    const [valueSpread, setValueSpread] = React.useState(0.06);
    const [valueCentroid, setValueCentroid] = React.useState(3);

    const handleChangeSeed = (event, newValue1) => {
        setValueSeed(newValue1);
    };

    const handleChangeSpread = (event, newValue2) => {
        setValueSpread(newValue2);
    };

    const handleChangeCentroid = (event, newValue3) => {
        setValueCentroid(newValue3);
    };

    // const handleChangeStep = (event, newValue4) => {
    //     setValueStep(newValue4);
    // };


    const [menutitle, setMenuTitle] = React.useState("petalLength");

    const [menutitle2, setMenuTitle2] = React.useState("sepalWidth");

    useEffect(() => {

 


    }, [props.keys])




   




    return (




        <Box sx={{ display: 'flex', align: 'center' }}>
            <AppBar position="fixed" open={open} color={'white'} align='center' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h5" noWrap sx={{ flexGrow: 1 }} component="div">
                    {props.title}
                    <br></br>
                    {props.subtitle}
                    </Typography>
                    
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{ '& svg': {
                            color: "inherit",
                            transition: '0.2s',
                            transform: 'translateX(0) rotate(0)',
                          },
                          '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                              transform: 'translateX(-4px) rotate(-20deg)',
                            },
                            '& svg:last-of-type': {
                              right: 0,
                              opacity: 1,
                            },
                          },
                          '&:after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                            bgcolor: 'divider',
                          },
                          ...(open && { display: 'none' }) }}
                    >
                        <Settings />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Main open={open}>
                <DrawerHeader />
            
            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Card sx={{ minWidth: drawerWidth }}>
                    <CardContent>
                        <Box >

                        </Box>


                        <>

                            



                        </>
                    </CardContent>
                </Card>
            </Drawer>
        </Box>






    );
}
